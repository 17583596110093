import React from "react"
import {
  Autocomplete,
  Checkbox,
  ListItemText,
  MenuItem,
  TextField
} from "@mui/material"

interface IMultiSelectAutocomplete {
  multiple: boolean
  data: any[]
  onChange: Function
  extraparams: any
  label: string
  className: string
  disablePortal: boolean
  value: any[]
}

const MultiSelectAutocomplete: React.FC<IMultiSelectAutocomplete> = props => {
  const {
    multiple,
    data,
    onChange,
    extraparams = {},
    label,
    className,
    disablePortal,
    value
  } = props

  return (
    <Autocomplete
      multiple={multiple}
      id="combo-box-demo1"
      className="custom-select"
      value={value}
      disableCloseOnSelect
      disablePortal={disablePortal}
      options={data || []}
      onChange={(event, newValue) => onChange(event, newValue, extraparams)}
      getOptionLabel={(option: any) => option}
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} key={option} value={option}>
          <Checkbox checked={selected} value={option} />
          <ListItemText primary={option} />
        </MenuItem>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          className={className}
          variant="filled"
        />
      )}
      renderTags={list => {
        let displayList = list.filter(f => f !== "All").join(", ")
        return <label className="ellipsis pl-1 pt-2">{displayList}</label>
      }}
    />
  )
}

export default MultiSelectAutocomplete

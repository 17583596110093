import React, { useCallback, useEffect } from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Button
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import Loader from "src/components/Loader"
import { KEY_CODES } from "src/utils/constants"

interface InputConfirm {
  open: boolean
  callBack: any
  callBackKeyUp?: Function
  title: any
  content: any
  custref: any
  isConfirmButtonShow?: boolean
  isConfirmButtonLoader?: boolean
  isCancelDisable?: boolean
  confirmButtonText?: string
  additionalBtnCallBack?: Function
  additionalButtonText?: string
  customContentClass?: string
}

const ConfirmDialog: React.FC<InputConfirm> = ({
  open,
  callBack,
  callBackKeyUp,
  title,
  content,
  custref,
  isCancelDisable,
  additionalBtnCallBack,
  additionalButtonText,
  isConfirmButtonShow = true,
  isConfirmButtonLoader = false,
  confirmButtonText = "CONFIRM",
  customContentClass = ""
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    const handleKeyUp = e => {
      if (e.keyCode === KEY_CODES.ENTER) {
        modalCallbackTrueKeyUp(e)
      }
    }

    // Add the event listener when the component mounts
    document.addEventListener("keyup", handleKeyUp)

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("keyup", handleKeyUp)
    }
  }, [isConfirmButtonShow, isConfirmButtonLoader])

  const modalCallbackFalse = useCallback(() => {
    callBack(false)
  }, [])

  const modalCallbackTrue = useCallback(() => {
    callBack(true)
  }, [])

  const modalCallbackTrueKeyUp = _e => {
    if (isConfirmButtonShow && !isConfirmButtonLoader) {
      if (callBackKeyUp) {
        callBackKeyUp(true)
      } else {
        callBack(true)
      }
    }
  }

  const modalAdditionalCallback = useCallback(() => {
    if (additionalBtnCallBack) {
      additionalBtnCallBack()
    }
  }, [])

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        className="custom-dialog"
      >
        <div>
          <Grid container className="custom-dialog__header">
            <Grid className="custom-dialog__header-close">
              <Button
                data-testid="closeBtn"
                variant="text"
                id="secondary-btn"
                className="secondary-btn"
                disableRipple={true}
                startIcon={<CloseIcon />}
                onClick={modalCallbackFalse}
                disabled={isCancelDisable}
                ref={custref}
              >
                {t(Translates.Close)}
              </Button>
            </Grid>
            <h2> {title}</h2>
          </Grid>
        </div>
        <DialogContent className={`custom-dialog__cont ${customContentClass}`}>
          {typeof content === "function" ? content() : content}
        </DialogContent>
        <DialogActions className="custom-dialog__actions">
          <Button
            data-testid="cancelBtn"
            variant="contained"
            className="secondary-btn mr-2"
            onClick={modalCallbackFalse}
            ref={custref}
            disabled={isCancelDisable}
          >
            {t(Translates.Cancel)}
          </Button>
          {additionalBtnCallBack && additionalButtonText ? (
            <Button
              data-testid="additionalBtn"
              variant="contained"
              className="secondary-btn mr-2"
              onClick={modalAdditionalCallback}
              ref={custref}
            >
              {additionalButtonText}
            </Button>
          ) : null}
          <Button
            data-testid="confirmBtn"
            variant="contained"
            className="primary-btn"
            disabled={!isConfirmButtonShow || isConfirmButtonLoader}
            onClick={modalCallbackTrue}
          >
            {isConfirmButtonLoader ? (
              <Loader isMarginTop={false} />
            ) : (
              confirmButtonText
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmDialog

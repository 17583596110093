import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { CountService } from "../../service/count.service"

export interface InventoryState {
  status: string
  inventoryData: any
  eventDetailStatus: string
}

const initialState: InventoryState = {
  status: "",
  inventoryData: {},
  eventDetailStatus: ""
}

const countService = new CountService()

export const getEventDetail = createAsyncThunk(
  "inventory/eventDetails",
  async () => {
    return countService.getEventDetail()
  }
)
//inventory Slice
export const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    clearInventoryState() {
      return initialState
    }
  },
  extraReducers: builder => {
    // Inventory
    builder.addCase(getEventDetail.pending, state => {
      state.eventDetailStatus = "loading"
    })
    builder.addCase(getEventDetail.fulfilled, (state, { payload }) => {
      state.eventDetailStatus = "success"
      state.inventoryData = payload.data
    })
    builder.addCase(getEventDetail.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.eventDetailStatus = "cancelled"
      } else {
        state.eventDetailStatus = "failed"
      }
    })
  }
})
export const { clearInventoryState } = inventorySlice.actions
export default inventorySlice.reducer

import React from "react"
import { Alert, AlertTitle, Button } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { useHistory } from "react-router-dom"
import { PATH } from "../../constants/paths"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"

const TeamLoggedOffBanner = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const handleViewChecklist = () => {
    history.push(PATH.CLOSE_INVENTORY)
  }
  return (
    <Alert
      severity="info"
      icon={<CheckCircleIcon />}
      className="custom-alert"
      action={
        <Button
        data-testid="TLOBBtn"
          className="primary-btn"
          variant="contained"
          onClick={handleViewChecklist}
        >
          {t(Translates.View_Checklist)}
        </Button>
      }
    >
      <AlertTitle className="custom-alert__title">
        {t(Translates.TEAM_LOGGED_OFF)}
      </AlertTitle>
      <p>
        {t(
          Translates.View_the_Close_Inventory_checklist_to_see_if_there_are_other_requirements_to_close_the_inventory
        )}
      </p>
    </Alert>
  )
}

export default TeamLoggedOffBanner

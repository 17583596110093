import React, { useCallback, useMemo, useState } from "react"
import { Grid } from "@mui/material"
import TagsBulkDataMapping from "./TagsBulkDataMapping"
import TagsBulkValidation from "./TagsBulkValidation"

const TagUploadContainer: React.FC<any> = () => {
  const [currentPage, setCurrentPage] = useState("page1")

  const setPage = useCallback(_page => {
    setCurrentPage(_page)
  }, [])

  const renderPages = useMemo(() => {
    if (currentPage === "page1") {
      return <TagsBulkDataMapping setPage={setPage} />
    } else if (currentPage === "page2") {
      return <TagsBulkValidation setPage={setPage} />
    } else {
      return <></>
    }
  }, [currentPage])

  return <Grid container>{renderPages}</Grid>
}

export default React.memo(TagUploadContainer)

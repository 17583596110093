import React, { useCallback, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, NavLink, useHistory, useLocation } from "react-router-dom"
import { PATH } from "../../constants/paths"
import { FiMoreVertical } from "react-icons/fi"
import { FaRegFileAlt } from "react-icons/fa"
import {
  AlertColor,
  Select,
  Tooltip,
  Grid,
  Button,
  IconButton,
  MenuItem,
  Menu,
  Avatar,
  FormControl,
  InputLabel
} from "@mui/material"
import { RootState, useAppThunkDispatch } from "../../redux/store"
import { clearAllPolls } from "../../service/poll.service"
import useInventoryStatus from "../../hooks/useInventoryStatus"
import AlertTop from "src/components/Alert/AlertTop"
import {
  getEventDetail,
  clearInventoryState
} from "../../redux/slices/inventorySlice"
import {
  clearCountState,
  setViewProcedure,
  updateInventeryStatus
} from "../../redux/slices/countSlice"
import { clearAuditState } from "../../redux/slices/auditSlice"
import { clearTeamState } from "../../redux/slices/teamSlice"
import { useTranslation } from "react-i18next"
import { availableLanguages, Translates } from "../../../src/i18n/i18n"
import { clearVarianceState } from "src/redux/slices/varianceSlice"
import { UserService } from "src/service/user.service"
import { clearAuthState } from "src/redux/slices/authSlice"
import {
  clearConfigurationState,
  getConfig
} from "src/redux/slices/configurationSlice"
import { clearInputFilesState } from "src/redux/slices/inputFilesSlice"
import { InItService } from "src/service/init.service"
import { decrypt, showVarianceTab } from "src/utils/helper"

import { CountService } from "src/service/count.service"
import {
  ALERT_MESSAGE_CLOSETIME,
  COMPLETED,
  INPROGRESS,
  PAGEREFRESHTIME,
  SCREEN_WIDTH_VL,
  STATUS_API_LOADTIME,
  languageCodeMap
} from "src/utils/constants"
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog"
import moment from "moment"

import styles from "src/components/VarianceToolBarHeader/VarianceToolBarHeader.module.scss"
import i18next from "i18next"

const Header = (_props: any) => {
  const userService = new UserService()
  const countService = new CountService()
  const inItService = new InItService()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatchAction = useDispatch()
  const dispatch = useAppThunkDispatch()
  const rangeDetails: any = useSelector(
    (state: RootState) => state.count?.countData
  )

  const isPrecountAvailable: any = useSelector(
    (state: RootState) => state.count?.precountFile?.isPrecountAvailable
  )

  const history = useHistory()
  const { isDisable } = useInventoryStatus()

  const logout = () => {
    userService
      .logout()
      .then(res => {
        if (res.status === 200) {
          dispatchAction(clearCountState())
          dispatchAction(clearAuditState())
          dispatchAction(clearTeamState())
          dispatchAction(clearInventoryState())
          dispatchAction(clearVarianceState())
          dispatchAction(clearAuthState())
          dispatchAction(clearConfigurationState())
          dispatchAction(clearInputFilesState())
          clearAllPolls()
          i18n.changeLanguage("English")
          localStorage.clear()
          history.push(PATH.LOGIN)
        }
      })
      .catch((err: any) => {
        console.log("error ", err)
      })
  }
  const [avthorEl, setAvthorEl] = React.useState<null | HTMLElement>(null)
  const [menuEl, setMenuEl] = React.useState<null | HTMLElement>(null)
  const [multiUserEl, setMultiUserEl] = React.useState<null | HTMLElement>(null)
  const [storeNumber, setStoreNumber] = useState(null as any)
  const [storeLogo, setStoreLogo] = useState(null as any)
  const [fname, setFname] = useState(" " as any)
  const [userDetails, setUserDetails] = useState([] as any)
  const [usersLength, setUsersLength] = useState(0)
  const [recentUserLogedin, setRecentUserLogedin] = useState("")
  const [lname, setLname] = useState(" " as any)
  const [name, setName] = useState([] as any)
  const [address, setAddress] = useState(null as any)
  const [date, setDate] = useState(null as any)
  const [userType, setUserType] = useState(" " as any)
  const open = Boolean(avthorEl)
  const openMenu = Boolean(menuEl)
  const openMultiUser = Boolean(multiUserEl)
  const inventory: any = useSelector((state: RootState) => state.inventory)
  const config: any = useSelector((state: RootState) => state.config)
  const configData: any = useSelector(
    (state: RootState) => state.config?.configData
  )
  const viewProcedure = config?.configData?.isPathExists
  const [previousLength, setPreviousLength] = useState(0)
  const [showAlert, setShowAlert] = useState(false)
  const [isTabLangSwitch, setIsTabLangSwitch] = useState(false)
  const [disableViewOption, setDisableViewOption] = useState(false)
  const [eventId, setEventId] = useState()
  const [isLanguageShortened, setIsLanguageShortened] = useState(true)
  const resInStoreCheck = config?.configData?.InputFiles?.[0]?.some(
    item => item?.InStoreCheck === true
  )
  const stateInventryWipeStatus = useSelector(
    (state: RootState) => state.count?.inventryWipeStatus
  )
  const viewProcedureState: any = useSelector(
    (state: RootState) => state.count.viewProcedure
  )
  useEffect(() => {
    fetchInventoryStatus()
  }, [])
  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      if (stateInventryWipeStatus === INPROGRESS) {
        fetchInventoryStatus()
      } else if (stateInventryWipeStatus === COMPLETED) {
        localStorage.clear()
        history.push(PATH.LOGIN)
      } else {
        clearInterval(fetchDataInterval) // Stop the interval when the status is not 'inprogress'
      }
    }, STATUS_API_LOADTIME)
    return () => clearInterval(fetchDataInterval)
  }, [stateInventryWipeStatus])
  useEffect(() => {
    const handleResize = () => {
      // Check the viewport width and update state accordingly
      setIsLanguageShortened(window.innerWidth > SCREEN_WIDTH_VL)
    }

    // Add event listener for window resize
    window.addEventListener("resize", handleResize)
    handleResize()

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const fetchInventoryStatus = async () => {
    try {
      const response = await countService.GetInventryWipeStatus()
      dispatch(updateInventeryStatus(response.data))
      let path = window.location.pathname
      const PAGE = "/reset-the-inventory"

      if (path !== PAGE && response.data && response.data === INPROGRESS) {
        history.push(PATH.RESET_THE_INVENTORY)
      }
    } catch (error) {
      console.error("Error fetching inventory status:", error)
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAvthorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAvthorEl(null)
  }
  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuEl(event.currentTarget)
  }
  const handleMultiUserClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMultiUserEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setMenuEl(null)
  }
  const handleCloseMultiUser = () => {
    setMultiUserEl(null)
  }
  const languageMap = {
    English: "English (USA)",
    French: "Français (Canadian)",
    Spanish: "Spanish (Mexico)"
  }
  const handleAlertMessage = () => {
    setShowAlert(false)
  }
  const onLoadFileClick = useCallback(_e => {
    if (location.pathname !== PATH.VARIANCE_UPLOAD_DETAILS) {
      history.push(PATH.VARIANCE_UPLOAD_DETAILS)
    }
  }, [])
  const userNameHandle = (item: any) => {
    if (item.firstName && item.lastName) {
      return (
        item.firstName.charAt(0).toUpperCase() +
        "" +
        item.lastName.charAt(0).toUpperCase()
      )
    } else if (item.firstName) {
      return item.firstName.charAt(0).toUpperCase()
    } else if (item.lastName) {
      return item.lastName.charAt(0).toUpperCase()
    }
    return ""
  }

  const eventLoggedUserdetails = async () => {
    try {
      const res = await userService.getLoggedinUsersByEventId()
      const FNAME = localStorage.getItem("firstName")
      const LNAME = localStorage.getItem("lastName")
      // data will be sorted by decending order from API
      const recentuser = res?.data && res?.data[0]
      let resData = res?.data || []
      resData = resData.filter(
        user => user?.firstName !== FNAME || user?.lastName !== LNAME
      )
      setUsersLength(resData.length)
      setUserDetails(resData)
      setRecentUserLogedin(
        recentuser?.firstName?.charAt(0)?.toUpperCase() +
          recentuser?.firstName?.slice(1) +
          " " +
          recentuser?.lastName?.charAt(0)?.toUpperCase() +
          recentuser?.lastName?.slice(1)
      )
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    eventLoggedUserdetails()
    const interval = setInterval(eventLoggedUserdetails, PAGEREFRESHTIME)
    return () => {
      clearInterval(interval)
    }
  }, [])
  useEffect(() => {
    if (usersLength > previousLength && previousLength !== 0) {
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, ALERT_MESSAGE_CLOSETIME)
    }
    setPreviousLength(usersLength)
  }, [usersLength, previousLength])
  useEffect(() => {
    handleAlertMessage()
    document.addEventListener("click", handleAlertMessage)
    return () => {
      document.removeEventListener("click", handleAlertMessage)
    }
  }, [])
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setFname(localStorage.getItem("firstName"))
      setLname(localStorage.getItem("lastName"))
      setUserType(localStorage.getItem("userType"))
      if (
        inventory?.inventoryData &&
        !Object.keys(inventory.inventoryData).length
      ) {
        if (
          !["cancelled", "loading", "failed"].includes(
            inventory.eventDetailStatus
          )
        ) {
          dispatch(getEventDetail())
            .then((res: any) => {
              if (res?.payload) {
                setName(`${res.payload?.data?.name}`)
                setStoreNumber(res.payload?.data?.storeNumber)
                setStoreLogo(res.payload?.data?.logo)
                setAddress(res.payload?.data?.address)
                setDate(res.payload?.data?.inventoryDate)
                setEventId(res.payload?.data?.eventId)
                localStorage.setItem(
                  "isRedirected",
                  res.payload?.data?.isRedirected
                )
              }
            })
            .catch(_error => {
              console.error(_error)
            })
        }
      } else {
        setName(`${inventory?.inventoryData?.name}`)
        setStoreNumber(inventory?.inventoryData?.storeNumber)
        setStoreLogo(inventory?.inventoryData?.logo)
        setAddress(inventory?.inventoryData?.address)
        setDate(inventory?.inventoryData?.inventoryDate)
        setEventId(inventory?.inventoryData?.eventId)
      }
      inItService.init()
      if (
        !Object.keys(configData || {}).length &&
        !["cancelled", "loading"].includes(config?.status)
      ) {
        dispatch(getConfig())
      }
    }
    return () => {
      isMounted = false
    }
  }, [configData, inventory, disableViewOption])

  const formatDate = useCallback(selectedDate => {
    if (!selectedDate) return "-"
    const lang = i18next.language
    moment.locale(languageCodeMap[lang])
    const parsedDate = moment(selectedDate).format("DD-MMM-YYYY").toUpperCase()
    return parsedDate
  }, [])
  const onCloseInventoryClick = () => {
    if (location.pathname !== PATH.CLOSE_INVENTORY) {
      history.push(PATH.CLOSE_INVENTORY)
    }
  }

  const onDeleteAllDataClick = () => {
    if (location.pathname !== PATH.RESET_THE_INVENTORY) {
      history.push(PATH.RESET_THE_INVENTORY)
    }
  }
  const handlePrecountFiles = () => {
    if (location.pathname !== PATH.PRECOUNT_FILES) {
      history.push(PATH.PRECOUNT_FILES)
    }
  }

  const handleViewProcedure = useCallback(async eventId => {
    try {
      // localStorage.setItem("viewProcedures", eventId)
      dispatch(setViewProcedure(eventId))
      const response = await countService.getViewProcedurePdf()
      downloadFile(response?.data)
      // setDisableViewOption(true)
    } catch (error) {
      setDisableViewOption(false)
      console.error(error)
    }
  }, [])
  const downloadFile = url => {
    let actualUrl = decrypt(url)
    window.open(actualUrl, "_blank")
  }

  const getUserType = () => {
    if (!userType) return "-"
    return t(userType)
  }
  let alertProps = {
    title: t(Translates.New_user),
    severity: "info" as AlertColor,
    // content: t(Translates.New_user_logged_in)
    content: recentUserLogedin + " " + t(Translates.New_user_logged_in)
  }

  const handleLangClick = useCallback(() => {
    setIsTabLangSwitch(true)
  }, [])

  const callbackConfirmation = useCallback(() => {
    setIsTabLangSwitch(false)
  }, [])

  const handleLanguageChange = useCallback(e => {
    i18n.changeLanguage(e.target.value)
  }, [])

  const setConfirmContent = useCallback(() => {
    return (
      <Grid container>
        <Grid item xs={8} sm={6} md={5}>
          <FormControl
            variant="filled"
            size="small"
            className="custom-input custom-select"
          >
            <InputLabel>Select Language</InputLabel>
            <Select
              data-testid="LanguageSelect"
              onChange={handleLanguageChange}
              labelId="language-dropdown"
              id="language-dropdown"
              size="small"
              value={i18n.language}
              defaultValue={i18n.language}
            >
              {availableLanguages.map(language => (
                <MenuItem
                  className="dropdown-list lang-dropdown__list"
                  value={language}
                  key={language}
                >
                  <small className={language}>{languageMap[language]}</small>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    )
  }, [])

  const navLinkRedirectConditionCheck = (e, path) => {
    if (location.pathname === path) {
      e.preventDefault()
    }
  }
  const checkLocalViewProcedure = () => {
    return viewProcedureState !== "" &&
      localStorage.getItem("viewProcedures") &&
      localStorage.getItem("viewProcedures") !== "null" && // Check for null
      localStorage.getItem("viewProcedures") !== "" // Check for empty string
      ? true
      : false
  }
  return (
    <header className={`header`} area-label={i18n.language}>
      {showAlert && <AlertTop {...alertProps} />}
      <div className="main-container">
        <div className="d-flex">
          <div className="header__logo">
            <img src={storeLogo} alt={"Logo"} />
            <span>
              <strong className="ellipsis">{name ? `${name}` : "NA"}</strong>
              <small>{storeNumber ? `# ${storeNumber}` : "NA"}</small>
            </span>
          </div>
          <div className={`header__links`}>
            <ul>
              <li>
                <NavLink
                  onClick={e => navLinkRedirectConditionCheck(e, PATH.COUNT)}
                  to={PATH.COUNT}
                  activeClassName="active"
                  data-testid="count"
                >
                  {t(Translates.Count)}
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={e => navLinkRedirectConditionCheck(e, PATH.AUDIT)}
                  to={PATH.AUDIT}
                >
                  {t(Translates.Audit)}
                </NavLink>
              </li>
              {showVarianceTab(configData) ? (
                <li>
                  <NavLink
                    onClick={e =>
                      navLinkRedirectConditionCheck(e, PATH.VARIANCE)
                    }
                    to={PATH.VARIANCE}
                  >
                    {t(Translates.Variance)}
                  </NavLink>
                </li>
              ) : null}
              <li>
                <NavLink
                  onClick={e => navLinkRedirectConditionCheck(e, PATH.TEAM)}
                  to={PATH.TEAM}
                >
                  {t(Translates.Team)}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="header__actions">
          <span className="header__actions--btn">
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "right"
                }
              }}
              onChange={handleLanguageChange}
              labelId="language-dropdown"
              id="language-dropdown"
              className="lang-dropdown"
              value={i18n.language}
              defaultValue={i18n.language}
              data-testid="LanguageSelect"
            >
              {availableLanguages.map(language => (
                <MenuItem
                  className="dropdown-list lang-dropdown__list"
                  value={language}
                  key={language}
                >
                  <small className={language}>
                    {isLanguageShortened ? `${languageMap[language]}` : ""}
                  </small>
                </MenuItem>
              ))}
            </Select>
          </span>
          <span className="header__actions--btn user-icons">
            <Tooltip arrow title={`${t(Translates.Account)}`}>
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                data-testid="logout-menu-btn"
              >
                <Avatar sx={{ width: 48, height: 48 }}>
                  {`${
                    fname &&
                    fname.length &&
                    fname != null &&
                    fname.charAt(0).toUpperCase()
                      ? fname.charAt(0).toUpperCase()
                      : ""
                  }${
                    lname &&
                    lname.length &&
                    lname != null &&
                    lname.charAt(0).toUpperCase()
                      ? lname.charAt(0).toUpperCase()
                      : ""
                  }`}
                </Avatar>
              </IconButton>
            </Tooltip>
            {userDetails && userDetails?.length ? (
              <Tooltip arrow title={t(Translates.Other_users)}>
                <IconButton
                  onClick={handleMultiUserClickMenu}
                  className="multi-user-btn"
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={openMultiUser ? "multi-user-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMultiUser ? "true" : undefined}
                  data-testid="user-menu-btn"
                >
                  <Avatar sx={{ width: 48, height: 48 }}>
                    {" "}
                    <small>+</small>
                    {usersLength}
                  </Avatar>
                </IconButton>
              </Tooltip>
            ) : null}
            <Menu
              data-testid="userMenuContent"
              className="profile-dropdown"
              anchorEl={avthorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  borderRadius: 0,
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0
                  }
                }
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <li className="profile-dropdown__name">
                <span
                  className="d-inline-block ellipsis"
                  title={`${fname ? fname.toUpperCase() : ""} ${
                    lname ? lname.toUpperCase() : ""
                  }`}
                >
                  {`${fname ? fname : ""} ${lname ? lname : ""}`}
                </span>
                <span className="user-role">{getUserType()}</span>
              </li>
              <li className="profile-dropdown__address">
                <strong>{name}</strong>
                <span className="d-block">
                  {storeNumber ? `#${storeNumber}` : "NA"}
                </span>
                <span className="d-block"> {formatDate(date)} </span>
                {address || "NA"}
              </li>
              <li className="profile-dropdown__logout">
                <Button
                  className="primary-btn mt-2"
                  variant="contained"
                  size="small"
                  onClick={logout}
                >
                  {t(Translates.Logout)}
                </Button>
              </li>
            </Menu>
            <Menu
              data-testid="MultiUserMenu"
              className="multi-user"
              anchorEl={multiUserEl}
              id="multi-user-menu"
              open={openMultiUser}
              onClose={handleCloseMultiUser}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <li className="other-users">
                {t(Translates.Other_users_logged_in)}
              </li>
              {userDetails?.map(item => (
                <li className="multi-user__col" key={item.idEventUser}>
                  <>
                    <Avatar sx={{ width: 48, height: 48 }}>
                      {userNameHandle(item)}
                    </Avatar>
                    <label>{`${item.firstName ? item.firstName : ""} ${
                      item.lastName ? item.lastName : ""
                    }`}</label>
                  </>
                </li>
              ))}
            </Menu>
          </span>

          <span className="header__actions--btn file-icon">
            <Tooltip arrow title={`${t(Translates.Reports)}`}>
              <Link
                onClick={e => navLinkRedirectConditionCheck(e, PATH.REPORTS)}
                to={PATH.REPORTS}
              >
                <FaRegFileAlt size={34} />
              </Link>
            </Tooltip>
          </span>

          <span className="header__actions--btn more-icon">
            <Tooltip arrow title={`${t(Translates.More_Options)}`}>
              <IconButton
                onClick={handleClickMenu}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={openMenu ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
                data-testid="handleClickMenu"
              >
                <FiMoreVertical size={40} />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={menuEl}
              id="account-menu"
              open={openMenu}
              className="clsInventory"
              onClose={handleCloseMenu}
              onClick={handleCloseMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
            >
              <MenuItem
                onClick={onLoadFileClick}
                data-testid="Files"
                className={`${styles.mItem}`}
                disabled={resInStoreCheck === false || isDisable}
              >
                {t(Translates.Files)}
              </MenuItem>

              <MenuItem
                disabled={rangeDetails?.countPercent !== 100 || isDisable}
                onClick={onCloseInventoryClick}
                data-testid="handleClickMenuItem1"
              >
                {t(Translates.Close_Inventory)}
              </MenuItem>

              <MenuItem
                disabled={isDisable}
                onClick={onDeleteAllDataClick}
                data-testid="handleClickMenuItem2"
              >
                {t(Translates.Delete_All_Data)}
              </MenuItem>
              <MenuItem
                className="visible-tab"
                data-testid="handleClickMenuItem3"
                onClick={handleLangClick}
              >
                {t(Translates.Language)}
              </MenuItem>
              <MenuItem
                disabled={
                  !viewProcedure
                }
                onClick={() => handleViewProcedure(eventId)}
                data-testid="handleClickMenuItem4"
              >
                {t(Translates.View_Procedures)}
              </MenuItem>
              {isPrecountAvailable ? (
                <MenuItem
                  disabled={isDisable}
                  onClick={handlePrecountFiles}
                  data-testid="handleClickMenuItem5"
                >
                  {t(Translates.Precount_Files)}
                </MenuItem>
              ) : null}
            </Menu>
          </span>
        </div>
        {isTabLangSwitch && (
          <ConfirmDialog
            open={isTabLangSwitch}
            callBack={callbackConfirmation}
            title="Language"
            content={setConfirmContent}
            custref={{}}
            confirmButtonText="Submit"
          ></ConfirmDialog>
        )}
      </div>
    </header>
  )
}

export default Header

import React from "react"
import { Box, CircularProgress } from "@mui/material"

export const LoaderHelper = () => {
  return (
      <Box
        key={"loader"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "32px"
        }}
      >
        <CircularProgress />
      </Box>
  )
}

import ApiService from "../utils/useAxios"

const api = new ApiService()
export class AuditService {
  getAuditSummary() {
    const endPoint = "api/Location/GetTagAndPersonViewData"
    return api.findAll(endPoint)
  }
  getAuditTags() {
    const endPoint = "/api/Location/GetAuditTagViewData"
    return api.findAll(endPoint)
  }
  getAuditPersons() {
    const endPoint = "/api/Location/GetPersonView"
    return api.findAll(endPoint)
  }
  getPersons(eventUserId) {
    const endPoint = `/api/Location/GetAuditPersonTagViewData/${eventUserId}`
    return api.findAll(endPoint)
  }
}

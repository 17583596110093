import React from "react"
import { Box, CircularProgress } from "@mui/material"

const index = ({
  isMarginTop = true,
  isJustifyContent = true,
  marginTopPx = ""
}) => {
  return (
    <Box
      key={"loader"}
      sx={{
        display: "flex",
        alignItems: "center",
        ...(isJustifyContent && { justifyContent: "center" }),
        ...((isMarginTop || marginTopPx) && {
          marginTop: marginTopPx !== "" ? marginTopPx : "300px"
        })
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export default index

import React from "react"
import { useFormContext } from "react-hook-form"
import { Alert, AlertTitle, Grid, Divider } from "@mui/material"
import { useTranslation } from "react-i18next"
import InfoIcon from "@mui/icons-material/Info"
import MUIText from "src/components/MUIFormFields/MUIText"
import MUIRadio from "src/components/MUIFormFields/MUIRadio"
import { fieldNames, getFormControlLabels, reasonOptions } from "../Constant"
import { Translates } from "src/i18n/i18n"
import PasswordFields from "./PasswordFields"
import MUIAutoCompleteText from "src/components/MUIFormFields/MUIAutoCompleteText"
const FormFields = ({
  passwordError,
  onChangePassword
}: {
  passwordError: string
  onChangePassword: Function
}) => {
  //Language translation hook
  const { t } = useTranslation()

  //Yes or No Radio button common props
  const formControlLabels = getFormControlLabels(t)

  const { watch } = useFormContext()
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8}>
        <Grid container>
          <Grid item xs={12} sm={4} data-testid="reset-reason-div">
            <label className="form-control__label mb-2">
              {t(fieldNames.reasonForDelete.label)} *
            </label>
            <MUIAutoCompleteText
              inputAttributes={{
                name: fieldNames.reasonForDelete.name,
                label: `${t(fieldNames.reasonForDelete.label)} *`,
                options: reasonOptions,
                isOptionTranslate: true,
                isNotSort: true
              }}
            />
          </Grid>
          {watch(fieldNames.reasonForDelete.name as any) ===
          reasonOptions[reasonOptions.length - 1].value ? (
            <Grid container className="mt-2">
              <Grid item xs={12} sm={6}>
                <MUIText
                  inputAttributes={{
                    name: fieldNames.reasonOther.name,
                    label: `${t(fieldNames.reasonOther.label)} *`,
                    rows: 3,
                    multiline: true
                  }}
                />
                <span className="note-text">
                  {watch(fieldNames.reasonOther.name as any).length} / 250
                </span>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={12}>
            <Divider className="dashed my-4" />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={12}>
            <label className="form-control__label mb-2">
              {t(fieldNames.keepLocationRange.label)} *
            </label>
            <MUIRadio
              name={fieldNames.keepLocationRange.name}
              formControl={{
                radioGroup: {
                  row: true,
                  formControlLabels: formControlLabels
                }
              }}
            />
            <Divider className="dashed mt-3 mb-4" />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={12}>
            <label className="form-control__label mb-2">
              {t(fieldNames.clearDeviceCountData.label)} *
            </label>
            <MUIRadio
              name={fieldNames.clearDeviceCountData.name}
              formControl={{
                radioGroup: {
                  row: true,
                  formControlLabels: formControlLabels
                }
              }}
            />
            <Divider className="dashed mt-3 mb-4" />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={12}>
            <label className="form-control__label mb-2">
              {t(fieldNames.forceValidationFileReDownload.label)} *
            </label>
            <MUIRadio
              name={fieldNames.forceValidationFileReDownload.name}
              formControl={{
                radioGroup: {
                  row: true,
                  formControlLabels: formControlLabels
                }
              }}
            />
            <Divider className="dashed mt-3 mb-4" />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={4}>
            <label className="form-control__label mb-2">
              {t(fieldNames.eventPassword.label)} *
            </label>
            <PasswordFields
              passwordError={passwordError}
              onChangePassword={onChangePassword}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Alert
          severity="info"
          icon={<InfoIcon />}
          className="custom-alert dark"
        >
          <AlertTitle className="custom-alert__title">Info</AlertTitle>
          <p>{t(Translates.Reset_The_Inventory_Info)}.</p>
        </Alert>
      </Grid>
    </Grid>
  )
}

export default FormFields

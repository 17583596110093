import React, { useEffect, useState } from "react"
import { Grid, Tooltip } from "@mui/material"
import GlobalTable from "src/components/GlobalTable/GlobalTable"
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import { useHistory } from "react-router-dom"
import { PATH } from "src/constants/paths"
import MainLayout from "src/layouts/MainLayout"
import ToolBarHeader from "src/components/ToolBarHeader/ToolBarHeader"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { VarianceService } from "src/service/variance.service"
import { encryptUrlParams, formatTimeOnHover } from "src/utils/helper"
const VarianceUploadDetails = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const varianceService = new VarianceService()
  const [loading, setLoading] = useState(false)
  const [uploadedDetails, setUploadedDetails] = useState([] as any)

  useEffect(() => {
    showUploadFiles()
  }, [])

  const getBreadcrumbConfig = () => {
    let currentPath: string = `files`
    let variables: any = {
      files: t(Translates.Files)
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables
    }
    return bConfig
  }

  const showUploadFiles = async () => {
    try {
      setLoading(true)
      const temp = await varianceService.getUploadFiles()
      setUploadedDetails(temp.data.uploadFileData)
      setLoading(false)
    } catch (_error) {
      console.error(_error)
      setLoading(false)
    }
  }
  const displaySpan = (props: any) => {
    return (
      <span className="d-block text-left">
        {props.value ? props.value : "-"}
      </span>
    )
  }

  const columnsData = React.useMemo(
    () => [
      {
        Header: t(Translates.NAME),
        accessor: "name", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: t(Translates.Description),
        accessor: "description", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: `${t(Translates.Last_uploaded)} / ${t(Translates.Updated)}`,
        accessor: "uploadedDateTime", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150,
        Cell: props => {
          return formatTimeOnHover(props)
        }
      },
      {
        Header: t(Translates.ACTIONS),
        accessor: "action", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 100,
        Cell: props => {
          return (
            <span className="action-btns">
              <Tooltip arrow title={t(Translates.Upload)}>
                <FileUploadOutlinedIcon
                  className="pointer"
                  data-testid="uploadIcon"
                  onClick={() => handleClickButton(props.row.original)}
                />
              </Tooltip>
            </span>
          )
        }
      }
    ],
    [t]
  )
  const handleClickButton = row => {
    history.push(
      PATH.VARIANCE_UPLOAD_FILE +
        `?name=${encryptUrlParams(row.name)}&description=${encryptUrlParams(
          row.description
        )}`
    )
  }
  const displayGrid = () => {
    return (
      <Grid container className="custom-table-responsive">
        <Grid xs={12} item>
          <GlobalTable
            columns={columnsData}
            data={uploadedDetails}
            noDataText={t(Translates.No_Files_Found)}
            initialStateProp={{
              sortBy: [
                {
                  id: columnsData.length ? columnsData[0].accessor : "",
                  desc: false
                }
              ]
            }}
          ></GlobalTable>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <MainLayout>
        <ToolBarHeader
          showToolBar={false}
          customPath={true}
          breadCrumbInfo={getBreadcrumbConfig()}
        />
        <Grid item xs={12} className="mt-3">
          {!loading ? displayGrid() : null}
        </Grid>
      </MainLayout>
    </>
  )
}

export default VarianceUploadDetails

import { formControlLabel } from "src/components/MUIFormFields/MUIRadio"
import { IResetTheInventoryForm } from "./Type"

export const fieldNames = {
  reasonForDelete: { name: "reason", label: "Reason_For_Delete" },
  reasonOther: { name: "reasonText", label: "Enter_your_Reason" },
  keepLocationRange: {
    name: "isKeepLocationRanges",
    label: "Keep_Location_Ranges"
  },
  clearDeviceCountData: {
    name: "isClearDeviceCountData",
    label: "Force_Logout_Clear_Device_Count_Data"
  },
  forceValidationFileReDownload: {
    name: "isForceValidationFileReDownload",
    label: "Force_Validation_File_Re_downloaded_on_Device"
  },
  eventPassword: { name: "eventPassword", label: "Event_Password" }
}

export const reasonOptions = [
  { label: "Training", value: "Training", translateKey: "Training" },
  {
    label: "Wrong Validation File",
    value: "Wrong Validation File",
    translateKey: "Wrong_Validation_File"
  },
  { label: "Count Issue", value: "Count Issue", translateKey: "Count_Issue" },
  { label: "Other", value: "Other", translateKey: "Other" }
]

export const yesorno = {
  yes: { label: "Yes", value: "Yes" },
  no: { label: "No", value: "No" }
}

export const yesornoValues = Object.values(yesorno).map(option => option.value)
export const reasonOptionsValues = Object.values(reasonOptions).map(
  option => option.value
)

export const getBreadcrumbConfig = Reset_The_Inventory_label => {
  let currentPath: string = `Reset the Inventory`
  let variables: any = {}
  variables["Reset the Inventory"] = Reset_The_Inventory_label
  let bConfig: any = {
    currentPath,
    mapObj: variables
  }
  return bConfig
}

export const errCheck = (isValid, passwordError, buttonDisableTest) => {
  if (!isValid || passwordError || buttonDisableTest) {
    return true
  }
  return false
}

export const getFormControlLabels = (t): formControlLabel[] => {
  return [
    {
      value: yesorno.yes.value,
      label: t(yesorno.yes.label),
      radio: { size: "medium" }
    },
    {
      value: yesorno.no.value,
      label: t(yesorno.no.label),
      radio: { size: "medium" }
    }
  ]
}

export const payloadPreparation = (inventory, data) => {
  return {
    eventId: inventory.inventoryData.eventId,
    reason: data.reason,
    reasonText:
      data.reason === reasonOptions[reasonOptions.length - 1].value
        ? data.reasonText
        : "",
    isClearLocationRanges: data.isKeepLocationRanges === yesorno.no.value,
    isClearDeviceCountData: data.isClearDeviceCountData === yesorno.yes.value,
    isForceValidationFileReDownload:
      data.isForceValidationFileReDownload === yesorno.yes.value
  }
}

export const defaultIniValues: IResetTheInventoryForm = {
  reason: "",
  reasonText: "",
  isKeepLocationRanges: yesorno.yes.value,
  isClearDeviceCountData: yesorno.yes.value,
  isForceValidationFileReDownload: yesorno.no.value,
  eventPassword: ""
}

export const validationErrors = {
  required: "This field is required",
  selectOption: "This field value should be one of the options",
  max: "This field length can not be more than {number}"
}

import ApiService from "../utils/useAxios"

const api = new ApiService()

export class ResetInventoryService {
  resetInventoryData(payload) {
    const endPoint = `/api/Event/DeleteAllData`
    return api.delete(endPoint, { data: payload })
  }

  passwordValidate(payload) {
    const endPoint: string = `/api/Event/validate`
    return api.post(endPoint, payload)
  }
}

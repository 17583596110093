import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { FILTERS_LOCAL_STORE, VARIANCE_TYPE } from "src/utils/constants"
import { VarianceService } from "../../service/variance.service"

//clearing to avoid retaining old event cache
if (window.location.pathname === "/login") {
  localStorage.removeItem(FILTERS_LOCAL_STORE.VARIANCE_PAGE_FILTER)
  localStorage.removeItem(FILTERS_LOCAL_STORE.SHOW_LOADER)
  localStorage.removeItem(FILTERS_LOCAL_STORE.BATCH_SORT)
  localStorage.removeItem(FILTERS_LOCAL_STORE.BATCH_FILTER)
  localStorage.removeItem(FILTERS_LOCAL_STORE.SUMMARY_DATA)
}
interface FilterInterface {
  groupVarianceStatus: string
  fullVarianceStatus: string
  view: string
}
export interface VarianceState {
  status: string
  varianceGroups: any
  varianceGroupsStatus: string
  batchDetails: any
  varianceBatchDetailsStatus: string
  fullBatchDetails: any
  fullVarianceBatchDetailsStatus: string
  varianceBatchReport: string
  printVarianceBatchReportStatus: string
  batchSort: string
  batchFilter: string
  expandedAccrodian: any[]
  createRemaining: any[]
  summaryData: any
  varianceSummaryDetailsStatus: string
  fullVariance: any
  fullVarianceAPIStatus: string
  variancePageFilter: FilterInterface
  varianceResearchPercentageStatus: string
  fullexpandedAccrodian: any
  isFullVarianceExpanded: false
  groupVarianceBatchMap: any
  expandedGroupList: number[]
  showloader: boolean
  creationInProgress: number[]
}
const getFilterFromLocalStore = () => {
  let VARIANCE_F: any = localStorage.getItem(
    FILTERS_LOCAL_STORE.VARIANCE_PAGE_FILTER
  )
  VARIANCE_F = VARIANCE_F
    ? JSON.parse(VARIANCE_F)
    : {
        groupVarianceStatus: "",
        fullVarianceStatus: "",
        view: VARIANCE_TYPE?.FULLVARIANCE
      }

  // ShowLoader
  let SHOW_LOADER: any = localStorage.getItem(FILTERS_LOCAL_STORE.SHOW_LOADER)
  SHOW_LOADER = SHOW_LOADER ? SHOW_LOADER.toLowerCase() === "true" : false

  // Batch Sort
  let BATCH_SORT: any = localStorage.getItem(FILTERS_LOCAL_STORE.BATCH_SORT)
  BATCH_SORT = BATCH_SORT ? BATCH_SORT.toLowerCase() : ""

  // Batch filter
  let BATCH_FILTER: any = localStorage.getItem(FILTERS_LOCAL_STORE.BATCH_FILTER)
  BATCH_FILTER = BATCH_FILTER ? BATCH_FILTER.toLowerCase() : ""

  // Summary Data
  let SUMMARY_DATA: any = localStorage.getItem(FILTERS_LOCAL_STORE.SUMMARY_DATA)
  SUMMARY_DATA = SUMMARY_DATA
    ? JSON.parse(SUMMARY_DATA)
    : {
        "0": {
          varianceResearchPercentage: {},
          varianceSummaryDetails: {}
        },
        "1": { varianceResearchPercentage: {}, varianceSummaryDetails: {} }
      }

  return {
    VARIANCE_F,

    SHOW_LOADER,

    BATCH_SORT,
    BATCH_FILTER,
    SUMMARY_DATA
  }
}

const filterFromLocalStore = getFilterFromLocalStore()
const initialState: VarianceState = {
  status: "",
  varianceGroups: {},
  varianceGroupsStatus: "",
  batchDetails: {},
  varianceBatchDetailsStatus: "",
  fullBatchDetails: {},
  fullVarianceBatchDetailsStatus: "",
  varianceBatchReport: "",
  printVarianceBatchReportStatus: "",
  batchSort: filterFromLocalStore.BATCH_SORT,
  batchFilter: filterFromLocalStore.BATCH_FILTER,
  expandedAccrodian: [],
  createRemaining: [],
  summaryData: filterFromLocalStore.SUMMARY_DATA,
  varianceSummaryDetailsStatus: "",
  fullVariance: {},
  fullVarianceAPIStatus: "",
  variancePageFilter: filterFromLocalStore.VARIANCE_F,
  varianceResearchPercentageStatus: "",
  fullexpandedAccrodian: {},
  isFullVarianceExpanded: false,
  groupVarianceBatchMap: {},
  expandedGroupList: [],
  showloader: false,
  creationInProgress: []
}
const initialStateForLogout: VarianceState = {
  status: "",
  varianceGroups: {},
  varianceGroupsStatus: "",
  batchDetails: {},
  varianceBatchDetailsStatus: "",
  fullBatchDetails: {},
  fullVarianceBatchDetailsStatus: "",
  varianceBatchReport: "",
  printVarianceBatchReportStatus: "",
  batchSort: "",
  batchFilter: "",
  expandedAccrodian: [],
  createRemaining: [],
  summaryData: {
    "0": {
      varianceResearchPercentage: {},
      varianceSummaryDetails: {}
    },
    "1": { varianceResearchPercentage: {}, varianceSummaryDetails: {} }
  },
  varianceSummaryDetailsStatus: "",
  fullVariance: {},
  fullVarianceAPIStatus: "",
  variancePageFilter: {
    groupVarianceStatus: "",
    fullVarianceStatus: "",
    view: VARIANCE_TYPE?.FULLVARIANCE
  },
  varianceResearchPercentageStatus: "",
  fullexpandedAccrodian: {},
  isFullVarianceExpanded: false,
  groupVarianceBatchMap: {},
  expandedGroupList: [],
  showloader: false,
  creationInProgress: []
}
const varianceService = new VarianceService()
export const getVarianceGroups = createAsyncThunk(
  "varianceList/varianceService",
  async () => {
    return varianceService.getVarianceGroups()
  }
)
export const getVarianceResearchPercentage = createAsyncThunk(
  "VarianceResearch/varianceService",
  async (params: any) => {
    return varianceService.getVarianceResearchPercentage(params)
  }
)
export const getVarianceSummaryDetails = createAsyncThunk(
  "VarianceSummary/varianceService",
  async (params: any) => {
    return varianceService.getVarianceSummaryDetails(params)
  }
)
export const getFullVariance = createAsyncThunk(
  "fullVariance/varianceService",
  async () => {
    return varianceService.getFullVariance()
  }
)
export const getVarianceBatchDetails = createAsyncThunk(
  "batchDetails/varianceService",
  async (params: any) => {
    let validationControlId = params.idValidationControl
    const { varianceGroupName, varianceGroup } = params
    const resp = await varianceService.getVarianceBatchDetails(
      varianceGroup,
      validationControlId,
      varianceGroupName
    )
    return {
      varianceGroup,
      idValidationControl: validationControlId,
      ...resp
    }
  }
)
export const getFullVarianceBatchDetails = createAsyncThunk(
  "fullbatchDetails/varianceService",
  async (params: any) => {
    let validationControlId = params.validationControlId
    const { varianceGroupName, varianceGroup } = params
    return varianceService.getVarianceBatchDetails(
      varianceGroup,
      validationControlId,
      varianceGroupName
    )
  }
)

export const printVarianceBatchReport = createAsyncThunk(
  "varianceBatchReport/varianceService",
  async (params: any) => {
    let varianceBatchId = params.varianceBatchId

    return varianceService.printVarianceBatchReport(varianceBatchId)
  }
)

const appendGroupBatch = (groupVarianceBatchMap, params) => {
  //multiple groups (create remaining variance case)

  let paramList: any = []
  if (params && Array.isArray(params)) paramList = params
  else paramList = [{ ...params }]
  paramList.forEach(param => {
    if (param && param.varianceGroup && param.idValidationControl) {
      let key = `${param.varianceGroup}-${param.idValidationControl}`
      groupVarianceBatchMap[key] = {
        ...param.data,
        varianceGroup: param.varianceGroup,
        idValidationControl: param.idValidationControl
      }
    }
  })
  return groupVarianceBatchMap
}

//Variance Slice
export const varianceSlice = createSlice({
  name: "varianceList",
  initialState,
  reducers: {
    updateVariancePageFilter(state, action: PayloadAction<any>) {
      state.variancePageFilter = {
        ...state.variancePageFilter,
        ...action.payload
      }
      localStorage.setItem(
        FILTERS_LOCAL_STORE.VARIANCE_PAGE_FILTER,
        JSON.stringify(state.variancePageFilter)
      )
      return state
    },
    updateExpandedAccordian(state, action: PayloadAction<any>) {
      state.expandedAccrodian = [...action.payload]

      return state
    },
    updateFullExpandedAccordian(state, action: PayloadAction<any>) {
      state.fullexpandedAccrodian = { ...action.payload }

      return state
    },
    updateShowLoader(state, action: PayloadAction<any>) {
      state.showloader = action.payload

      return state
    },
    updateIsFullVarianceExpanded(state, action: PayloadAction<any>) {
      state.isFullVarianceExpanded = action.payload

      return state
    },
    updateCreationInProgress(state, action: PayloadAction<any>) {
      state.creationInProgress = action.payload
      return state
    },
    updateExpandedGroupList(state, action: PayloadAction<any>) {
      state.expandedGroupList = action.payload

      return state
    },
    updateFullVariance(state, action: PayloadAction<any>) {
      state.fullVariance = action.payload

      return state
    },
    updateBatchVariance(state, action: PayloadAction<any>) {
      state.groupVarianceBatchMap = appendGroupBatch(
        { ...state.groupVarianceBatchMap },
        action.payload
      )
      return state
    },
    updateSortValue(state, action: PayloadAction<string>) {
      state.batchSort = action.payload
      localStorage.setItem(FILTERS_LOCAL_STORE.BATCH_SORT, state.batchSort)
      return state
    },
    updateFilterValue(state, action: PayloadAction<string>) {
      state.batchFilter = action.payload
      localStorage.setItem(FILTERS_LOCAL_STORE.BATCH_FILTER, state.batchFilter)
      return state
    },
    updateCreateRemainig(state, action: PayloadAction<any>) {
      state.createRemaining = action.payload
      return state
    },
    updateSummaryData(state, action: PayloadAction<any>) {
      state.summaryData = action.payload
      localStorage.setItem(
        FILTERS_LOCAL_STORE.SUMMARY_DATA,
        JSON.stringify(state.summaryData)
      )
      return state
    },

    clearVarianceState() {
      return initialStateForLogout
    }
  },
  extraReducers: builder => {
    builder.addCase(getVarianceGroups.pending, state => {
      state.varianceGroupsStatus = "loading"
    })
    builder.addCase(getVarianceGroups.fulfilled, (state, { payload }) => {
      state.varianceGroupsStatus = "success"
      state.varianceGroups = payload.data
    })
    builder.addCase(getVarianceGroups.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.varianceGroupsStatus = "cancelled"
      } else {
        state.varianceGroupsStatus = "failed"
      }
    })
    builder.addCase(getVarianceBatchDetails.pending, state => {
      state.varianceBatchDetailsStatus = "loading"
    })
    builder.addCase(getVarianceBatchDetails.fulfilled, (state, { payload }) => {
      state.varianceBatchDetailsStatus = "success"
      state.groupVarianceBatchMap = appendGroupBatch(
        { ...state.groupVarianceBatchMap },
        payload
      )
    })
    builder.addCase(getVarianceBatchDetails.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.varianceBatchDetailsStatus = "cancelled"
      } else {
        state.varianceBatchDetailsStatus = "failed"
      }
    })
    builder.addCase(getFullVarianceBatchDetails.pending, state => {
      state.fullVarianceBatchDetailsStatus = "loading"
    })
    builder.addCase(
      getFullVarianceBatchDetails.fulfilled,
      (state, { payload }) => {
        state.fullVarianceBatchDetailsStatus = "success"
        state.fullBatchDetails = payload.data
      }
    )
    builder.addCase(
      getFullVarianceBatchDetails.rejected,
      (state, { error }) => {
        if (error.message?.includes("duplicate request")) {
          state.fullVarianceBatchDetailsStatus = "cancelled"
        } else {
          state.fullVarianceBatchDetailsStatus = "failed"
        }
      }
    )
    builder.addCase(printVarianceBatchReport.pending, state => {
      state.printVarianceBatchReportStatus = "loading"
    })
    builder.addCase(
      printVarianceBatchReport.fulfilled,
      (state, { payload }) => {
        state.printVarianceBatchReportStatus = "success"
        state.varianceBatchReport = payload.data
      }
    )
    builder.addCase(printVarianceBatchReport.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.printVarianceBatchReportStatus = "cancelled"
      } else {
        state.printVarianceBatchReportStatus = "failed"
      }
    })
    builder.addCase(getFullVariance.pending, state => {
      state.fullVarianceAPIStatus = "loading"
    })
    builder.addCase(getFullVariance.fulfilled, (state, { payload }) => {
      state.fullVarianceAPIStatus = "success"
      state.fullVariance = payload?.data
    })
    builder.addCase(getFullVariance.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.fullVarianceAPIStatus = "cancelled"
      } else {
        state.fullVarianceAPIStatus = "failed"
      }
    })
    builder.addCase(getVarianceResearchPercentage.pending, state => {
      state.varianceResearchPercentageStatus = "loading"
    })
    builder.addCase(
      getVarianceResearchPercentage.fulfilled,
      (state, { payload, meta }) => {
        state.varianceResearchPercentageStatus = "success"
        state.summaryData[meta.arg].varianceResearchPercentage = payload.data
      }
    )
    builder.addCase(
      getVarianceResearchPercentage.rejected,
      (state, { error }) => {
        if (error.message?.includes("duplicate request")) {
          state.varianceResearchPercentageStatus = "cancelled"
        } else {
          state.varianceResearchPercentageStatus = "failed"
        }
      }
    )
    builder.addCase(getVarianceSummaryDetails.pending, state => {
      state.varianceSummaryDetailsStatus = "loading"
    })
    builder.addCase(
      getVarianceSummaryDetails.fulfilled,
      (state, { payload, meta }) => {
        state.varianceSummaryDetailsStatus = "success"
        state.summaryData[meta.arg].varianceSummaryDetails = payload.data
      }
    )
    builder.addCase(getVarianceSummaryDetails.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.varianceSummaryDetailsStatus = "cancelled"
      } else {
        state.varianceSummaryDetailsStatus = "failed"
      }
    })
  }
})
export const {
  updateExpandedAccordian,
  updateSortValue,
  updateFilterValue,
  clearVarianceState,
  updateCreateRemainig,
  updateVariancePageFilter,
  updateSummaryData,
  updateFullExpandedAccordian,
  updateIsFullVarianceExpanded,
  updateFullVariance,
  updateBatchVariance,
  updateExpandedGroupList,
  updateCreationInProgress
} = varianceSlice.actions
export default varianceSlice.reducer

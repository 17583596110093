import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useTranslation } from "react-i18next"
import ToolBarHeader from "src/components/ToolBarHeader/ToolBarHeader"
import { Translates } from "src/i18n/i18n"
import MainLayout from "src/layouts/MainLayout"
import { defaultIniValues, getBreadcrumbConfig } from "./Constant"
import { validationSchema } from "./Validation"
import { IResetTheInventoryForm } from "./Type"
import FormSetup from "./Component/FormSetup"

const ResetTheInventoryData = () => {
  //Language translation hook
  const { t } = useTranslation()

  //Call the getBreadcrumbConfig function and store the return value to the const
  const bConfig = getBreadcrumbConfig(t(Translates.Reset_The_Inventory))

  //Form hook declaration
  const formHookMethods = useForm<IResetTheInventoryForm>({
    resolver: yupResolver(validationSchema()),
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: defaultIniValues
  })

  return (
    <MainLayout>
      <ToolBarHeader
        showToolBar={false}
        customPath={true}
        breadCrumbInfo={bConfig}
      />
      <FormProvider {...formHookMethods}>
        <FormSetup />
      </FormProvider>
    </MainLayout>
  )
}

export default ResetTheInventoryData

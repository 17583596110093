import React from "react"

const MiniHeader: React.FC<any> = props => {
  return (
    <header className={`header`}>
      <div className="main-container">
        <h6 className="white-text">{props.headerTitle}</h6>
      </div>
    </header>
  )
}
export default MiniHeader

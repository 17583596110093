import ApiService from "../utils/useAxios"

const api = new ApiService()
export class VarianceService {
  getVarianceGroups() {
    const endPoint = "/api/Variance/GetVarianceGroups"
    return api.findAll(endPoint)
  }
  getFullVariance() {
    const endpoint = "/api/Variance/GetFullVariance"
    return api.findAll(endpoint)
  }
  getVarianceResearchPercentage(IsFullStore) {
    const endpoint = `/api/Variance/VarianceResearchPercentage?IsFullStore=${IsFullStore}`
    return api.findAll(endpoint)
  }
  getVarianceSummaryDetails(IsFullStore) {
    const endpoint = `api/Variance/GetVarianceSummaryDetails?IsFullStore=${IsFullStore}`
    return api.findAll(endpoint)
  }
  onHandFileUpload(payload: any, config: any) {
    const endPoint = `/api/Variance/UploadChunks`
    return api.savefile(endPoint, payload, config)
  }
  finalFileMergePost(payload) {
    const endPoint = `/api/Variance/OnHandFileUpload`
    return api.post(endPoint, payload)
  }
  getResearchVariance(varianceBatchId, eventID) {
    const endpoint = `/api/Variance/ResearchedVariances?varianceBatchId=${varianceBatchId}&eventID=${eventID}`
    return api.findAll(endpoint)
  }
  getVarianceBatchDetails(
    varianceGroup,
    validationControlId,
    varianceGroupName
  ) {
    const endPoint = `api/Variance/GetVarianceResearchDetails?varianceGroup=${varianceGroup}&validationControlId=${validationControlId}&varianceGroupName=${varianceGroupName}`
    return api.findAll(endPoint)
  }

  printVarianceBatchReport(varianceBatchId) {
    const endPoint = `api/Variance/PrintVarianceBatchReport?varianceBatchId=${varianceBatchId}`
    return api.findAll(endPoint)
  }
  createVariance(_varianceGroup, _varianceControlId) {
    const endpoint = `/api/Variance/CreateVariance?varianceGroup=${_varianceGroup}&varianceControlId=${_varianceControlId}`
    return api.post(endpoint, {
      varianceGroup: _varianceGroup
    })
  }
  createFullVariance() {
    const endpoint = `/api/Variance/CreateFullStoreVariance`
    return api.post(endpoint, {})
  }
  recreateVariance(
    _varianceGroup,
    _varianceResearchHeaderId,
    _varianceControlId
  ) {
    const endpoint = `/api/Variance/ReCreateVariance?varianceGroup=${_varianceGroup}&varianceResearchHeaderId=${_varianceResearchHeaderId}&varianceControlId=${_varianceControlId}`
    return api.post(endpoint, {
      varianceGroup: _varianceGroup,
      varianceResearchHeaderId: _varianceResearchHeaderId
    })
  }
  deleteVariance(_varianceResearchHeaderId) {
    const endpoint = `api/Variance/DeleteVariances?varianceResearchHeaderId=${_varianceResearchHeaderId}`
    return api.update(endpoint, {
      varianceResearchHeaderId: _varianceResearchHeaderId
    })
  }
  createRamainingVariance() {
    const endpoint = `api/Variance/CreateRemainingVariance`
    return api.post(endpoint, {})
  }
  getUploadFiles() {
    const endPoint = `api/Variance/GetUploadFiles`
    return api.findAll(endPoint)
  }
  createVarianceGroup(payload) {
    const endpoint = `api/Variance/CreateVarianceGroup`
    return api.post(endpoint, payload)
  }
}

import { AlertColor, IconButton, Tooltip } from "@mui/material"
import moment from "moment"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import CycloneIcon from "@mui/icons-material/Cyclone"
import { formatWithCurrencyLang } from "src/utils/helper"

export const gridStatus = {
  Loaded: "Loaded",
  Not_Loaded: "Not Loaded",
  Deleted: "Deleted"
}

export const alertPropsC = {
  title: "",
  severity: "" as AlertColor,
  content: ""
}

export const preCountGridDateFormat = dateTime => {
  return dateTime
    ? moment
        .utc(dateTime)
        .local()
        .format("DD-MMM-YYYY hh:mm A")
        .toLocaleUpperCase()
    : "-"
}

export const preCountGridAccessor = {
  File_Name: "name",
  File_Received: "createdDate",
  File_Loaded: "lastUpdatedDate",
  Total_Records: "totalRecords",
  Invalid_Records: "invalidRecords",
  Loaded_Qty: "loadedQty",
  status: "status",
  Actions: "actions"
}

const displaySpan = ({
  props,
  t,
  Translates,
  processIconClick,
  deleteIconClick,
  isActionIconClicked
}) => {
  const { value, column, row } = props
  const emptyReplacedValue = value ? value : "-"
  if ([preCountGridAccessor.File_Received].includes(column.id)) {
    return (
      <div> {value ? preCountGridDateFormat(value) : emptyReplacedValue}</div>
    )
  } else if ([preCountGridAccessor.File_Loaded].includes(column.id)) {
    return (
      <div>
        {value && row.original.status !== gridStatus.Not_Loaded
          ? preCountGridDateFormat(value)
          : "-"}
      </div>
    )
  } else if (
    [
      preCountGridAccessor.Total_Records,
      preCountGridAccessor.Invalid_Records,
      preCountGridAccessor.Loaded_Qty
    ].includes(column.id)
  ) {
    return (
      <div className="text-right">
        {value ? formatWithCurrencyLang(value) : emptyReplacedValue}
      </div>
    )
  } else if ([preCountGridAccessor.status].includes(column.id)) {
    if ([gridStatus.Deleted, gridStatus.Loaded].includes(row.original.status)) {
      return (
        <div>
          {value} by {row.original.updatedBy}
        </div>
      )
    }
    return (
      <div> {value ? t(value.replace(" ", "_")) : emptyReplacedValue} </div>
    )
  } else if ([preCountGridAccessor.Actions].includes(column.id)) {
    return (
      <span className="action-btns">
        {row.original.status !== gridStatus.Loaded ? (
          <Tooltip arrow title={t(Translates.Load)}>
            <IconButton
              data-testid="preCountProcessIcon"
              onClick={() => {
                processIconClick(row.original)
              }}
              disabled={isActionIconClicked}
            >
              <CycloneIcon />
            </IconButton>
          </Tooltip>
        ) : null}
        {![gridStatus.Deleted, gridStatus.Not_Loaded].includes(
          row.original.status
        ) ? (
          <Tooltip arrow title={t(Translates.Delete)}>
            <IconButton
              data-testid="preCountDeleteIcon"
              className="delete-icon"
              onClick={() => {
                deleteIconClick(row.original)
              }}
              disabled={isActionIconClicked}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </span>
    )
  }
  return <div> {emptyReplacedValue} </div>
}

export const prepareColumns = ({
  Translates,
  t,
  processIconClick,
  deleteIconClick,
  isActionIconClicked
}) => {
  const commonColumnVariables = {
    headerClassName: "temp_class",
    disableFilters: true,
    disableSortBy: false,
    setWidth: 150,
    Cell: props =>
      displaySpan({
        props,
        t,
        Translates,
        processIconClick,
        deleteIconClick,
        isActionIconClicked
      })
  }
  return [
    {
      ...commonColumnVariables,
      Header: t(Translates.File_Name),
      accessor: preCountGridAccessor.File_Name
    },
    {
      ...commonColumnVariables,
      Header: t(Translates.File_Received),
      accessor: preCountGridAccessor.File_Received
    },
    {
      ...commonColumnVariables,
      Header: t(Translates.File_Loaded),
      accessor: preCountGridAccessor.File_Loaded
    },
    {
      ...commonColumnVariables,
      Header: t(Translates.Total_Records),
      accessor: preCountGridAccessor.Total_Records
    },
    {
      ...commonColumnVariables,
      Header: t(Translates.Invalid_Records),
      accessor: preCountGridAccessor.Invalid_Records
    },
    {
      ...commonColumnVariables,
      Header: t(Translates.Loaded_Qty),
      accessor: preCountGridAccessor.Loaded_Qty
    },
    {
      ...commonColumnVariables,
      Header: t(Translates.Status),
      accessor: preCountGridAccessor.status
    },
    {
      ...commonColumnVariables,
      Header: t(Translates.Actions),
      accessor: preCountGridAccessor.Actions
    }
  ]
}

export const getBreadcrumbConfig = ({ Translates, t }) => {
  let currentPath: string = `Precount_Files`
  let variables: any = {}
  variables["Precount_Files"] = t(Translates.Precount_Files)
  let bConfig: any = {
    currentPath,
    mapObj: variables
  }
  return bConfig
}

import React, { useEffect, useState } from "react"
import { SubmitHandler, useFormContext } from "react-hook-form"
import { useSelector } from "react-redux"
import { AxiosResponse } from "axios"
import moment from "moment"
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Divider,
  AlertColor,
  CircularProgress,
  Backdrop
} from "@mui/material"
import { Warning } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import {
  defaultIniValues,
  errCheck,
  fieldNames,
  payloadPreparation
} from "../Constant"
import { IResetTheInventoryForm } from "../Type"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { ResetInventoryService } from "src/service/resetInventory.service"
import FormFields from "./FormFields"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import AlertTop from "src/components/Alert/AlertTop"
import { Translates } from "src/i18n/i18n"
import { useHistory } from "react-router-dom"
import { PATH } from "src/constants/paths"
import {
  InventoryDataDeletingCheck,
  updateInventeryStatus
} from "src/redux/slices/countSlice"
import { CountService } from "src/service/count.service"
import { COMPLETED, INPROGRESS, STATUS_API_LOADTIME } from "src/utils/constants"
import { encrypt } from "src/utils/helper"

let passwordWrongEnterCount = Number(
  localStorage.getItem("passwordWrongEnterCount") || 0
)
let deletePayload = {}
const wrongPasswordButtonDisableMinutes = 5

let showAlert: boolean = false
const setShowAlert = newVal => (showAlert = newVal)

let alertProps = {
  title: "Reset The Inventory",
  severity: "success" as AlertColor,
  content: "Reset the inventory data successfully"
}
const setAlertProps = newAlertProps =>
  (alertProps = { ...alertProps, ...newAlertProps })

const FormSetup = () => {
  const dispatch = useAppThunkDispatch()
  const stateInventryWipeStatus = useSelector(
    (state: RootState) => state.count?.inventryWipeStatus
  )

  //Language translation hook
  const { t } = useTranslation()
  //Location redirect hook
  const history = useHistory()
  //Get event details for delete payload
  const inventory = useSelector((state: RootState) => state.inventory)
  //use state variable for password api validation
  const [passwordError, setPasswordError] = useState<string>("")
  //use state for the customer type wrong password 3 times
  const [buttonDisableTest, setButtonDisableTest] = useState<string>("")
  //use state for re-render the component
  const [, setReload] = useState<boolean>(false)
  //use state for conformation modal
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<boolean>(false)
  //Api class object declaration
  const resetInventoryService = new ResetInventoryService()
  const countService = new CountService()
  const [isLoading, setIsLoading] = useState(false)
  const [checkStatus, setCheckStatus] = useState(false)

  const toastMsgShow = (alertMsg = alertProps) => {
    setAlertProps({
      ...alertMsg
    })
    setShowAlert(true)
    setReload(oldVal => !oldVal)
    setTimeout(() => {
      setShowAlert(false)
      setReload(oldVal => !oldVal)
    }, 3000)
  }

  useEffect(() => {
    if (stateInventryWipeStatus === INPROGRESS) {
      setIsLoading(true)
    } else if (stateInventryWipeStatus === COMPLETED) {
      localStorage.clear()
      history.push(PATH.LOGIN)
    } else {
      setIsLoading(false)
    }
  }, [stateInventryWipeStatus])

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      //If suppose customer refresh the page get the time when the submit btn disable from local storage
      const disableTime = localStorage.getItem("passwordWrongOccurTime")
      if (disableTime) {
        let time = 0.0005
        const startTime = moment(new Date(disableTime), "DD-MM-YYYY hh:mm:ss")
        const endTime = moment(new Date(), "DD-MM-YYYY hh:mm:ss")
        const minutesDiff = endTime.diff(startTime, "minutes")
        if (minutesDiff < wrongPasswordButtonDisableMinutes) {
          time = wrongPasswordButtonDisableMinutes - minutesDiff
        }
        setButtonDisableTest(
          t(Translates.Button_will_be_enable_after_minutes).replace(
            `${wrongPasswordButtonDisableMinutes}`,
            `${wrongPasswordButtonDisableMinutes - minutesDiff}`
          )
        )
        dynamicChangeErrorMessage(
          wrongPasswordButtonDisableMinutes - minutesDiff,
          time
        )
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [t])

  const {
    handleSubmit,
    watch,
    formState: { errors, isValid },
    reset
  } = useFormContext()

  //Disable button after enter 3 time wrong button show dynamic message
  const dynamicChangeErrorMessage = (maxTime, displayPeriod) => {
    for (let i = maxTime; i > 0; i--) {
      setTimeout(() => {
        setButtonDisableTest(
          t(Translates.Button_will_be_enable_after_minutes).replace(
            `${wrongPasswordButtonDisableMinutes}`,
            `${i}`
          )
        )
      }, (maxTime - i) * 60 * 1000 + 0.01)
    }
    buttonDisableAndEnable(displayPeriod * 60 * 1000)
  }

  //After 5 minutes reset the values
  const buttonDisableAndEnable = time => {
    setTimeout(() => {
      passwordWrongEnterCount = 0
      localStorage.removeItem("passwordWrongOccurTime")
      localStorage.removeItem("passwordWrongEnterCount")
      setButtonDisableTest("")
    }, time)
  }

  //Form submission function
  const formSubmitHandler: SubmitHandler<any> = (
    data: IResetTheInventoryForm
  ) => {
    if (!errors[fieldNames.eventPassword.name]) {
      const payload = {
        firstName: localStorage.getItem("firstName") || null,
        lastName: localStorage.getItem("lastName") || null,
        password: encrypt(watch(fieldNames.eventPassword.name))
      }
      resetInventoryService
        .passwordValidate(payload)
        .then((res: AxiosResponse) => {
          passwordWrongEnterCount = 0
          setPasswordError("")
          localStorage.removeItem("passwordWrongEnterCount")
          localStorage.removeItem("passwordWrongOccurTime")

          //delete api required payload
          deletePayload = payloadPreparation(inventory, data)
          setIsConfirmDeleteOpen(true)
        })
        .catch((err: any) => {
          setPasswordError("Incorrect_password")
          passwordWrongEnterCount = passwordWrongEnterCount + 1
          localStorage.setItem(
            "passwordWrongEnterCount",
            `${passwordWrongEnterCount}`
          )
          if (
            passwordWrongEnterCount >= 3 &&
            !localStorage.getItem("passwordWrongOccurTime")
          ) {
            localStorage.setItem(
              "passwordWrongOccurTime",
              new Date().toString()
            )

            dynamicChangeErrorMessage(
              wrongPasswordButtonDisableMinutes,
              wrongPasswordButtonDisableMinutes
            )
          }
        })
    }
  }
  useEffect(() => {
    if (checkStatus) {
      fetchInventoryStatus()
      const fetchInventoryStatusWrapper = () => {
        fetchInventoryStatus()
      }
      const interval = setInterval(
        fetchInventoryStatusWrapper,
        STATUS_API_LOADTIME
      )
      return () => {
        clearInterval(interval)
      }
    }
  }, [checkStatus])
  const fetchInventoryStatus = async () => {
    try {
      const response = await countService.GetInventryWipeStatus()
      dispatch(updateInventeryStatus(response.data))
      if (response.data === INPROGRESS) {
        setIsLoading(true)
      } else if (response.data === COMPLETED) {
        setCheckStatus(false)
        localStorage.clear()
        history.push(PATH.LOGIN)
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      console.error("Error in fetchInventoryStatusWrapper:", error)
      return false
    }
    return true
  }
  //Conformation function for execute the delete data api
  const callbackDeleteConfirmation = async (res: boolean) => {
    if (res) {
      try {
        setCheckStatus(true)
        dispatch(InventoryDataDeletingCheck(true))
        setButtonDisableTest("Processing...")
        await resetInventoryService.resetInventoryData(deletePayload)
        setButtonDisableTest("")
        toastMsgShow()
        reset(defaultIniValues)
        deletePayload = {}
      } catch (_error) {
        setCheckStatus(false)
        setIsLoading(false)
        setButtonDisableTest("")
      }
    }
    setIsConfirmDeleteOpen(false)
  }

  //Content get function for delete modal
  const setDeleteContent = () => {
    return (
      <Alert severity="error" icon={<Warning />} className="custom-alert">
        <AlertTitle className="alert__title">
          <h2>{t(Translates.Warning)}</h2>
        </AlertTitle>
        <p>
          <strong className="d-block">
            {t(Translates.All_data_deleted_sure)}?
          </strong>
        </p>
      </Alert>
    )
  }

  const buttonDisable = errCheck(isValid, passwordError, buttonDisableTest)

  //OnChange validate the event password before reset inventory data
  const onChangePassword = e => {
    setPasswordError("")
  }
  const redTextStyle = {
    color: "black",
    fontSize: "16px",
    fontWeight: 900
  }

  return (
    <>
      <Backdrop
        open={isLoading}
        sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
      >
        <p style={redTextStyle}>{t(Translates.data_Wiping)}</p>
        <br />
        <CircularProgress />
      </Backdrop>
      :
      {!isLoading && (
        <>
          <FormFields
            passwordError={passwordError}
            onChangePassword={onChangePassword}
          />
          <Grid item xs={12}>
            <Divider className="custom-border my-4 mt-8" />
            <div className="text-right mt-2">
              <Button
                variant="contained"
                className={"primary-btn"}
                onClick={handleSubmit(formSubmitHandler)}
                disabled={buttonDisable}
              >
                {t(Translates.CONFIRM)}
              </Button>
              <div className="mt-2 error-text">{buttonDisableTest}</div>
            </div>
          </Grid>
          <Grid container>
            {isConfirmDeleteOpen && (
              <ConfirmDialog
                open={isConfirmDeleteOpen}
                callBack={callbackDeleteConfirmation}
                title={t(Translates.Reset_The_Inventory)}
                content={setDeleteContent}
                custref={{}}
                confirmButtonText={t(Translates.CONFIRM)}
                isConfirmButtonShow={!buttonDisable}
              />
            )}
          </Grid>
          <Grid container>
            {showAlert ? <AlertTop {...alertProps} /> : null}
          </Grid>
        </>
      )}
    </>
  )
}

export default FormSetup

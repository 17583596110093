import React, { useState } from "react"
import { Alert, AlertColor, AlertTitle, Collapse } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { HighlightOff } from "@mui/icons-material"
import InfoIcon from "@mui/icons-material/Info"

interface AlertProps {
  severity: AlertColor
  title: string
  content: string
}
const AlertTop = (props: AlertProps) => {
  const [open, setOpen] = useState(true)
  let Icon = <CheckCircleIcon />
  if (props.severity === "error") {
    Icon = <HighlightOff />
  } else if (props.severity === "info") {
    Icon = <InfoIcon />
  }
  return (
    <Collapse in={open}>
      <Alert
        severity={props.severity}
        icon={Icon}
        className="custom-alert top"
        data-testid="alert-box"
        onClose={() => {
          setOpen(false)
        }}
      >
        <AlertTitle className="custom-alert__title f-600">
          {`${props.title}`}
        </AlertTitle>
        <p> {`${props.content}`}</p>
      </Alert>
    </Collapse>
  )
}
export default AlertTop

import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import English from "./translations/en.json"
import French from "./translations/de.json"
import Spanish from "./translations/me.json"
import { MultilingualService } from "src/service/multilingual.service"
import { languageCode } from "src/constants/common"
import { store } from "src/redux/store"
import { setKeys } from "src/redux/slices/authSlice"
import { UserService } from "src/service/user.service"
import { decryptUrlParams } from "src/utils/helper"
import { languageCodeMap } from "src/utils/constants"
import moment from "moment"

//clearing to avoid retaining old event cache
if (window.location.pathname === "/login") {
  localStorage.removeItem("i18nextLng")
}

export const resources = {
  English,
  French,
  Spanish
}

const multilingualService = new MultilingualService()
const userService = new UserService()
// const api = new ApiService()
const route = window.location.pathname

export const updateMoment = () => {
  Object.keys(languageCodeMap).forEach(lang => {
    moment.defineLocale(languageCodeMap[lang], {
      monthsShort: resources[lang].monthsShort
    })
  })
}

if (localStorage.getItem("accessToken") && !route.includes("login")) {
  updateMoment()
  userService
    .getKeyVaultSecrets()
    .then((res: any) => {
      if (res?.data && res?.data?.length) {
        const _temp = res?.data?.map(a => ({
          secretKey: a.secretKey,
          secretValue: decryptUrlParams(a.secretValue)
        }))
        store.dispatch(setKeys(_temp))
      }
    })
    .catch(function (err) {
      throw err
    })
  multilingualService.getLanguageText().then((res: any) => {
    const Languages = res?.data || []
    Object.keys(Languages)?.forEach(i => {
      if (i?.toLowerCase() === languageCode.us_en) {
        Languages[i]?.forEach(i1 => {
          English.common = {
            ...English.common,
            ...{ [i1?.field_Code]: i1?.text_Translation }
          }
        })
      } else if (i?.toLowerCase() === languageCode.ca_fr) {
        Languages[i]?.forEach(i1 => {
          French.common = {
            ...French.common,
            ...{ [i1?.field_Code]: i1?.text_Translation }
          }
        })
      } else if (i?.toLowerCase() === languageCode.es_mx) {
        Languages[i]?.forEach(i1 => {
          Spanish.common = {
            ...Spanish.common,
            ...{ [i1?.field_Code]: i1?.text_Translation }
          }
        })
      }
    })
  })
}

export const defaultNS = "common"

export const availableLanguages = Object.keys(resources)

const selectedLanguage = localStorage.getItem("i18nextLng")
const fallbackLanguage = "English" // Fallback language if stored language is not available
const initialLanguage = selectedLanguage ?? fallbackLanguage // Use stored language or fallback language
i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  defaultNS,
  lng: initialLanguage,
  fallbackLng: fallbackLanguage
})

export const Translates = Object.keys(English.common).reduce(
  (current: { [key: string]: string }, key: string) => ({
    ...current,
    [key]: key
  }),
  {}
)

import React from "react"
import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { ReactPlugin } from "@microsoft/applicationinsights-react-js"
import { createBrowserHistory } from "history"
const browserHistory = createBrowserHistory({ basename: "" })
export const reactPlugin = new ReactPlugin()
let appInsights = new ApplicationInsights({
  config: {
    connectionString: `${process.env.REACT_APP_INSIGHTS_CONNECTION_STRING}`,
    isCookieUseDisabled: true,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
})
// istanbul ignore next
appInsights?.loadAppInsights()
// istanbul ignore next
appInsights.addTelemetryInitializer(envelope => {
  if (envelope.tags) {
    envelope.tags["ai.cloud.role"] = "WIS_WebApp"
  }
})

//Track page view
appInsights.trackPageView({
  name: window.location.pathname,
  uri: window.location.href
})
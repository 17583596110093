import {
  Button,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Tooltip
} from "@mui/material"
import BreadCrumb from "../BreadCrumb/BreadCrumb"
import styles from "./TeamToolBarHeader.module.scss"
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined"
import SearchIcon from "@mui/icons-material/Search"
import React, { useEffect, useState } from "react"
import { encryptUrlParams, replaceAll } from "../../../src/utils/helper"
import { Link, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../redux/store"
import { updateTeamPageFilter } from "../../redux/slices/teamSlice"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"

type TeamStatusFilter = {
  handleFilter: any
  showFilter: any
  handleSearchChange: any
  suggestions: any[]
}

const TeamToolBarHeader: React.FC<TeamStatusFilter> = ({
  handleFilter,
  showFilter,
  handleSearchChange,
  suggestions
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const options: any = [
    {
      label: Translates.ALL,
      value: "All"
    },
    {
      label: Translates.ACTIVE,
      value: "Active"
    },
    {
      label: Translates.LOGGED_OFF,
      value: "LoggedOff"
    },
    {
      label: Translates.INACTIVE,
      value: "InActive"
    },
    {
      label: Translates.AUDITORS,
      value: "Auditor"
    },
    {
      label: Translates.COUNTERS,
      value: "Counter"
    }
  ]
  const filterFromStore = useSelector(
    (state: RootState) => state.team.teamPageFilter.status
  )
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [selectedFilter, setSelectedFilter] = useState<null | string>(null)
  const open = Boolean(anchorEl)
  const history = useHistory()
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const [search, setSearch] = useState("")
  const handleClose = () => {
    setAnchorEl(null)
  }
  useEffect(() => {
    setSelectedFilter(filterFromStore)
    let index: number = options.findIndex(
      option => option.value === filterFromStore
    )
    setSelectedIndex(index)
  }, [filterFromStore])
  const handleMenuItemClick = (value, index) => {
    dispatch(updateTeamPageFilter({ status: value }))
    setSelectedIndex(index)
    setAnchorEl(null)
    handleFilter(value)
    setSelectedFilter(value)
  }
  const handleInputChange = e => {
    setSearch(e.target.value)
    handleSearchChange(e.target.value)
  }
  const getBreadcrumbConfig = () => {
    let currentPath: string = `team`
    let variables: any = {
      team: t(Translates.Team)
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables
    }
    return bConfig
  }
  const filterButtonClass = () => {
    let classStr: string = `ml-2 px-2 secondary-btn ${styles.FilterButton}`
    if (selectedFilter && selectedFilter !== "All") {
      return `${classStr} filter-selected`
    } else {
      return classStr
    }
  }
  const renderSingleSuggestion = (suggestion: string, userId: number) => {
    //   match logic
    let searchedText: string = search
    if (searchedText.includes("$")) {
      searchedText = replaceAll(searchedText, "$", "\\$")
    }
    let regexp = new RegExp(searchedText, "gi")
    const matches: any = String(suggestion).match(regexp)
    let parts: any = String(suggestion).split(
      new RegExp(`${searchedText}`, "gi")
    )
    for (let i = 0; i < parts.length; i++) {
      if (i !== parts.length - 1) {
        let match = matches[i]
        parts[i] = (
          <React.Fragment key={i}>
            {parts[i]}
            <span>{match}</span>
          </React.Fragment>
        )
      }
    }
    return (
      <Link
        key={userId}
        className={"team-redirect-link"}
        to={`${window.location.pathname}/${encryptUrlParams(
          suggestion
        )}/${encryptUrlParams(userId)}`}
      >
        <li>{parts}</li>
      </Link>
    )
  }
  const ariaExpanded = () => {
    if (open) {
      return "true"
    } else {
      return undefined
    }
  }
  const ariaControls = () => {
    if (open) {
      return "basic-menu"
    } else {
      return undefined
    }
  }
  const renderSuggestions = () => {
    if (suggestions.length) {
      return (
        <div className="search-dropdown">
          <ul className="search-dropdown-list">
            {suggestions.map((s: any) => {
              return renderSingleSuggestion(s.name, s.userId)
            })}
          </ul>
        </div>
      )
    } else if (!suggestions.length && search.length) {
      return (
        <div className="search-dropdown">
          <ul className="search-dropdown-list">
            <li style={{ cursor: "default" }}>
              {t(Translates.Search_result_not_found)}
            </li>
          </ul>
        </div>
      )
    } else {
      return null
    }
  }
  const handleKeyUp = (e: any) => {
    if (e.keyCode && e.keyCode == 13) {
      history.push(
        `${window.location.pathname}/${encryptUrlParams(
          suggestions[0].name
        )}/${encryptUrlParams(suggestions[0].userId)}`
      )
    }
  }
  return (
    <div id={`toolbar-header-container`} className={`${styles.Container}`}>
      <div className={`main-container ${styles.ContainerInner}`}>
        <div className="custom-breadcrumb">
          <BreadCrumb
            customPath={"Team"}
            breadCrumbInfo={getBreadcrumbConfig()}
            renderedPage={""}
            curTagNumber={""}
            curRangeId={""}
            isEditTagDisabled={false}
          />
        </div>
        {!showFilter ? (
          <div>
            <FormControl variant="filled">
              <FilledInput
                data-testid="search-in"
                id="filled-adornment-password"
                placeholder={t(Translates.Search_Team)}
                inputProps={{ maxLength: 60 }}
                onChange={handleInputChange}
                value={search}
                autoComplete={"off"}
                onKeyUp={handleKeyUp}
                classes={{
                  root: `${styles.SeacrchField}`,
                  input: `${styles.InputBox}`
                }}
                startAdornment={
                  <InputAdornment
                    classes={{
                      root: `${styles.InputIcon}`
                    }}
                    position="start"
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="start"
                    >
                      <SearchIcon></SearchIcon>
                    </IconButton>
                  </InputAdornment>
                }
              />
              {renderSuggestions()}
            </FormControl>
            <Tooltip arrow title={t(Translates.Filter)}>
              <Button
                id="basic-button"
                variant="text"
                className={filterButtonClass()}
                aria-controls={ariaControls()}
                aria-haspopup="true"
                aria-expanded={ariaExpanded()}
                onClick={handleClick}
                // title="Filter"
              >
                <FilterListOutlinedIcon />
                {/* <span>Filter</span> */}
              </Button>
            </Tooltip>
            <Menu
              id="basic-menu"
              className="filter-menu"
              data-testid={"menu-item"}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={option.label}
                  data-testid={`mitem-${index}`}
                  onClick={() => handleMenuItemClick(option.value, index)}
                  selected={index === selectedIndex}
                >
                  {t(option.label)}
                </MenuItem>
              ))}
            </Menu>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default TeamToolBarHeader

export const BREAKPOINT = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400
}

export const COLOR = {
  BLUE: "#0d6efd",
  // White, Black, Grays
  white: 'rgba(255, 255, 255, 1)',
  black: 'rgba(0, 0, 0, 1)',
  bodyText: 'rgb(33, 33, 33)', //#212121

  primary_Color_1: 'rgba(36, 121, 205)', // #2479cd
  primary_Color_2: 'rgb(38, 137, 13)', // #26890d

  secondary_color_1: 'rgb(245 ,245, 245)', //#f5f5f5;
  secondary_color_2: 'rgb(102, 102, 102)', //#666666;
  secondary_color_3: 'rgb(235, 244, 255)', //#EBF4FF;
  secondary_color_4: 'rgb(189, 189, 189)', //#BDBDBD;

  dangerColor_1: 'rgb(255, 0, 0)', //#FF0000;
  dangerColor_2: 'rgba(253, 223, 223, 0.46)',
  inProgressColor: '#FF8400',
  completedColor: '#008800',
  grayBackColor: '#F5F5F5',
  blueTextColor: '#007AFF',
  notStartedProgress:'#CECECE',

}


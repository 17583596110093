import React from "react"
import GlobalTable, {
  SelectCustomColumnFilter
} from "../../../src/components/GlobalTable/GlobalTable"
import { Box, CircularProgress, Grid } from "@mui/material"
import { RootState } from "../../../src/redux/store"
import {
  encryptUrlParams,
  showValuesWithCurrency
} from "../../../src/utils/helper"
import { useHistory } from "react-router-dom"
import useLocationLable from "../../../src/hooks/useLocationLable"
import useDollar from "../../../src/hooks/useDollar"
import { renderAccuracySmiley } from "../Team/teamHelper"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import {
  AUDITOR,
  AUDIT_STATUS,
  AUDIT_STATUS_VERIFIED_CLASS,
  COUNTED_CLASS,
  COUNTER,
  IN_PROGRESS,
  NOT_STARTED,
  NOT_STARTED_CLASS,
  ROWCLICK_COLUMNSTOSUPRESS,
  STATUS_BG_CLASS
} from "src/utils/constants"
import { Translates } from "src/i18n/i18n"
import CounterFlag from "../../../src/assets/images/CounterFlag.png"
import AuditorFlag from "../../../src/assets/images/AuditorFlag.png"

const AuditTagView: React.FC<any> = () => {
  const { t } = useTranslation()

  const auditSummaryFromState = useSelector(
    (state: RootState) => state.audit.auditSummary
  )
  const auditTagsFromStore = useSelector(
    (state: RootState) => state.audit.auditTags
  )
  const inventory: any = useSelector((state: RootState) => state.inventory)
const stdTimeZone: any = inventory?.inventoryData?.stdTimeZone

  const filterFromStore = useSelector(
    (state: RootState) => state.audit.auditPageFilter.tagViewStatus
  )
  const { locationLable } = useLocationLable()
  const {
    dollarLable,
    dataDecimalLable,
    qtyCurrencyLable,
    qtyDecimalLable,
    isExtVarianceAllowed
  } = useDollar()

  const getCellValues = cellValue => {
    if (!cellValue || cellValue == " ") {
      return ""
    } else {
      return cellValue
    }
  }
  const history = useHistory()

  const OnCellClick = (rowObj, cellObj) => {
    const cellValue = parseInt(rowObj?.cells[0].value)
    const pathURL =
      window.location.pathname + "/" + encryptUrlParams(rowObj.original.tag)

    if (
      ROWCLICK_COLUMNSTOSUPRESS.toUpperCase().indexOf(
        cellObj.column.Header.toUpperCase()
      ) <= -1
    ) {
      history.push(pathURL, {
        cellValue,
        stdTimeZone: stdTimeZone
      })
    }
  }

  const renderStatus = row => {
    if (
      row.original?.isActiveUserType?.toLowerCase() === AUDITOR.toLowerCase()
    ) {
      return (
        <img src={AuditorFlag} alt="auditor icon" className="counter-img" />
      )
    }
    if (
      row.original?.isActiveUserType?.toLowerCase() === COUNTER.toLowerCase()
    ) {
      return (
        <img src={CounterFlag} alt="auditor icon" className="counter-img" />
      )
    }
    return ""
  }

  const columnsData = React.useMemo(
    () => [
      {
        Header: t(locationLable),
        accessor: "tag",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 100,
        Cell: props => {
          return (
            <span className="blue-text cursor-pointer"> {props.value} </span>
          )
        }
      },
      {
        Header: `${t(locationLable)}  ${t(Translates.range)}`,
        accessor: "range",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 130,
        Cell: props => {
          return getCellValues(props.value)
        }
      },
      {
        Header: t(Translates.reason),
        accessor: "auditReason",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 130,
        Cell: props => {
          return getCellValues(props.value)
        }
      },
      {
        Header: t(Translates.QTY),
        accessor: "qty",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 90,
        Cell: props => {
          return (
            <span className="d-block text-right">
              {showValuesWithCurrency(
                props.value == null ? 0 : props.value,
                qtyDecimalLable,
                qtyCurrencyLable
              )}
            </span>
          )
        }
      },
      {
        Header: t(Translates.ITEMS),
        accessor: "items",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 100,
        Cell: props => {
          return <span className="d-block text-right">{props.value}</span>
        }
      },
      ...(isExtVarianceAllowed
        ? [
            {
              Header: t(Translates.EXT),
              accessor: "ext",
              headerClassName: "temp_class",
              disableFilters: true,
              disableSortBy: false,
              setWidth: 100,
              Cell: props => {
                return (
                  <span className="d-block text-right">
                    {props.value != null &&
                      showValuesWithCurrency(
                        props.value,
                        dataDecimalLable,
                        dollarLable,
                        true
                      )}
                  </span>
                )
              }
            }
          ]
        : []),
      {
        Header: t(Translates.Counted_By),
        accessor: "countedBy",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 160,
        Cell: props => {
          return getCellValues(props.value)
        }
      },
      {
        Header: t(Translates.Audited_By),
        accessor: "auditedBy",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150,
        Cell: ({ row, value }) => {
          if (!value || value == " " || row.original.status == NOT_STARTED) {
            return ""
          } else {
            return value
          }
        }
      },
      {
        Header: t(Translates.accuracy),
        accessor: "accuracy",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 110,
        sortType: (a, b, columnId, desc) => {
          const aValue = a.original[columnId]
          const bValue = b.original[columnId]

          if (
            (aValue === 0 && bValue !== 0) ||
            [NOT_STARTED, IN_PROGRESS].includes(a.original.status)
          ) {
            return -1
          }
          if (aValue !== 0 && bValue === 0) {
            return 1
          }

          return aValue - bValue // Sort by numeric value otherwise
        },
        Cell: ({ row, value }) => {
          return !(
            row.original.status === NOT_STARTED ||
            row.original.status === IN_PROGRESS
          )
            ? renderAccuracySmiley(
                value,
                auditSummaryFromState.goal,
                auditSummaryFromState.secondaryGoal
              )
            : ""
        }
      },
      {
        Header: t(Translates.STATUS),
        accessor: "status",
        headerClassName: "temp_class",
        Filter: SelectCustomColumnFilter, //SelectColumnFilter,
        filter: "equals",
        id: "auditTagStatus",
        sourcePage: "auditTagView",
        disableFilters: false,
        disableSortBy: true,
        setWidth: 150,
        Cell: ({ row, value }) => {
          let statusText
          let statusClassName = STATUS_BG_CLASS
          switch (value?.toLowerCase()) {
            case AUDIT_STATUS.VERIFIED.toLowerCase():
              statusText = t(Translates.VERIFIED)
              statusClassName += ` ${AUDIT_STATUS_VERIFIED_CLASS}`
              break
            case AUDIT_STATUS.NOTSTARTED.toLowerCase():
              statusText = t(Translates.Not_Started)
              statusClassName += ` ${NOT_STARTED_CLASS}`
              break
            case AUDIT_STATUS.COUNTED.toLowerCase():
              statusText = t(Translates.Counted)
              statusClassName += ` ${COUNTED_CLASS}`
              break
            case AUDIT_STATUS.VOIDED.toLowerCase():
              statusText = t(Translates.Voided)
              statusClassName += ` ${COUNTED_CLASS}`
              break
            default:
              statusText = "-"
              break
          }
          return (
            <span className="counter-flag">
              <span className={statusClassName}>{statusText}</span>
              {renderStatus(row)}
            </span>
          )
        }
      }
    ],
    [
      locationLable,
      filterFromStore,
      t,
      auditTagsFromStore,
      auditSummaryFromState
    ]
  )
  const Loader = () => {
    return (
      <>
        <Box
          key={"loader"}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "32px"
          }}
        >
          <CircularProgress />
        </Box>
      </>
    )
  }

  const displayGrid = () => {
    return (
      <Grid container>
        <Grid
          xs={12}
          item
          className="custom-table-responsive"
          data-testid="AuditTagViewGlobT"
        >
          <GlobalTable
            columns={columnsData}
            data={auditTagsFromStore}
            noDataText={`${t(Translates.No)} ${t(locationLable)}s ${t(
              Translates.Found
            )}`}
            OnCellClick={OnCellClick}
            initialStateProp={{
              sortBy: [
                {
                  id: columnsData.length ? columnsData[0].accessor : "",
                  desc: false
                }
              ],
              sourcePage: "auditTagView",
              filters:
                filterFromStore && filterFromStore.length
                  ? [
                      {
                        id: "auditTagStatus",
                        value: filterFromStore === "ALL" ? "" : filterFromStore
                      }
                    ]
                  : []
            }}
          ></GlobalTable>
        </Grid>
      </Grid>
    )
  }
  return <>{displayGrid()}</>
}
export default AuditTagView

export const CONCISE_FIELDS: string[] = [
  "barcode",
  "color",
  "department",
  "sku",
  "uom",
  "size",
  "productcaptured",
  "description",
  "price",
  "cost",
  "style",
  "producttext1",
  "producttext5",
  "productdecimal1",
  "productdecimal5",
  "capturedProduct"
]

export const CUSTOM_REQUEST_HEADERS = {
  "Strict-Transport-Security": "Max-age=31536000; includeSubDomains",
  "Content-Security-Policy": "frame-ancestors 'none'",
  "X-Frame-Options": "deny",
  "X-Content-Type-Options": "No sniff"
}
export const COUNT_STATUS = {
  NOTSTARTED: "count_status_not_started"
  // NOTSTARTED: "Not Started"
}
export const VARIANCES_STATUS = {
  NOTRESEARCHED: "variance_status_not_researched"
}
export const VARIANCE_STATUS = {
  INPROGRESS: "in progress",
  RESEARCHED: "researched",
  NOTRESEARCHED: "not researched",
  NEEDSRESEARCH: "needs researched",
  LIGHT: "researched light",
  SECONDARYCOLOR: "secondary-color-4"
}
export const VARIANCE_CREATION_STATUS = {
  NOT_STARTED: "not started",
  IN_PROGRESS: "in progress",
  COMPLETED: "completed"
}
export const VARIANCE_ACTIONS = {
  RECREATE: "RECREATE",
  RECREATE_VARIANCE: "Recreate Variance",
  DELETE: "DELETE",
  DELETE_VARIANCE: "Delete Variance"
}
export const VARIANCE_TYPE = {
  FULLVARIANCE: "fullVariance",
  GROUPVARIANCE: "groupVariance",
  FULLSTORE: "fullstore",
  fullVariance: "full variance",
  FULL: "full",
  VARIANCEGROUP: "varianceGroup"
}
export const AUDIT_VIEW = {
  AUDIT_TYPE: "audit",
  TAG_VIEW: "tagView",
  PERSON_VIEW: "personView"
}
export const TAG_STATUS = {
  COUNTED: "COUNTED",
  VOIDED: "VOIDED",
  Ori_VOIDED: "Voided",
  NOTSTARTED: "NOTSTARTED",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARNING: "WARNING",
  VERIFIED: "VERIFIED"
}
export const AUDIT_STATUS = {
  COUNTED: "COUNTED",
  NOTSTARTED: "NOTSTARTED",
  VERIFIED: "VERIFIED",
  VOIDED: "VOIDED",
  COMPLETED: "completed",
  INPROGRESS: "INPROGRESS"
}
export const REPORT_FIELDS = {
  EXT: "ext",
  QTY: "qty",
  PRICE: "price"
}
export const ROWCLICK_COLUMNSTOSUPRESS = "ACTIONS"
export const ROWCLICK_CHECKMARK_IGNORE = "CheckMarks"
export const SUBTOTAL = "SUB TOTAL"
export const CUSTOMLIST = "CUSTOM_LIST"
export const ITEMS = "ITEMS"

export const TAG_DETAILS_HEADERS = {
  ACTIONS: "Actions",
  VERIFIED: "Verified",
  EXT: "Ext",
  DEPARTMENT: "department",
  PRICE: "price",
  QTY: "qty",
  STATUS: "STATUS",
  NOT_VERIFIED: "Not Verified",
  DEPT: "dept",
  PRODUCT_CAPTURED: "ProductCaptured",
  LOCATION: "Location"
}

export const onFailType = { error: "ERROR", warning: "WARN" }
export const conditionOperatorConstant = { OR: "OR", AND: "AND" }

export const functionNames = {
  CheckBarcodeType: "CheckBarcodeType",
  CheckDigit: "CheckCheckDigit",
  Braces: "Braces",
  If: "If",
  CheckDataType: "CheckDataType",
  CheckEntryMethod: "CheckEntryMethod",
  CheckLengths: "CheckLength",
  CheckValueRange: "CheckValueRange",
  CheckDecimalRange: "CheckDecimalRange",
  CheckLengthRange: "CheckLengthRange",
  RegEx: "RegEx",
  IsEqual: "IsEqual",
  IsGreaterThan: "IsGreaterThan",
  IsGreaterThanOrEqual: "IsGreaterThanOrEqual",
  IsLessThan: "IsLessThan",
  IsLessThanOrEqual: "IsLessThanOrEqual",
  IsIn: "IsIn",
  StartsWith: "StartsWith",
  EndsWith: "EndsWith",
  IsNull: "IsNull",
  IsNullOrEmpty: "IsNullOrEmpty",
  IsNullOrWhiteSpace: "IsNullOrWhiteSpace",
  IsNotNull: "IsNotNull",
  IsNotNullOrEmpty: "IsNotNullOrEmpty",
  IsNotNullOrWhiteSpace: "IsNotNullOrWhiteSpace",
  HasConsecutiveSameDigits: "HasConsecutiveSameDigits",
  AllowScan: "AllowScan",
  AllowKey: "AllowKey",
  AutoStore: "AutoStore",
  Lookup: "Lookup",
  LookupRoutine: "LookupRoutine",
  Error: "ERROR",
  Trim: "Trim",
  LTrim: "LTrim",
  RTrim: "RTrim",
  LPad: "LPad",
  RPad: "RPad",
  SubString: "SubString",
  SubstringFromEnd: "SubstringFromEnd",
  ToUpper: "ToUpper",
  ToLower: "ToLower",
  Remove: "Remove",
  Replace: "Replace",
  RemoveFromEnd: "RemoveFromEnd",
  Strip: "Strip",
  StripControlChars: "StripControlChars",
  Split: "Split",
  Insert: "Insert",
  ExpandUpceToUpca: "ExpandUpceToUpca",
  AddCheckDigit: "AddCheckDigit",
  RestoreOriginal: "RestoreOriginal",
  Else: "Else",
  Endif: "Endif",
  HasConsecutiveSameCapture: "HasConsecutiveSameCapture",
  RecordsInLoop: "RecordsInLoop"
}
export const SORT_OPTIONS = {
  Sku: "sku",
  Tag: "tag",
  QTYVariance: "qtyVariance",
  EXTVariance: "extVariance"
}
export const RANGE_HEADER_SPACE = 140
export const QTY_HEADER_SPACE = 120
export const MAX_LIMIT = 1000
export const MIN_RANGE_LIMIT = "1"
export const MAX_RANGE_LIMIT = "999999999999999999999999"
export const PAGEREFRESHTIME = 15000
export const STATUS_API_LOADTIME = 3000
export const ALERT_MESSAGE_CLOSETIME = 3000
export const DECIMAL_REGEX = /^(?!.*\..*\.)\d+(\.\d*)?$/
export const INPROGRESS = "In Progress"
export const COMPLETED = "Completed"
export const LOADING_TIME = 1000
export const SUCCESS_CODE = 200
export const BASE_NO = 10

export const PARAMS = {
  IdInventoryData: "idInventoryData",
  RecordNumber: "recordNumber",
  ConciseRecordId: "conciseRecordId",
  RangeId: "rangeId",
  RangeName: "RangeName",
  Type: "type",
  LocationValue: "locationValue",
  RecordHistory: "recordHistory",
  RecIdList: "recIdList"
}
export const KEY_CODES = {
  ENTER: 13
}
export const AREA_RANGE_FILE = "AreaRangeFile"
export const AUDITOR = "auditor"
export const COUNTER = "counter"
export const STATUS_BG_CLASS = "status-bg"
export const AUDIT_STATUS_VERIFIED_CLASS = "audit-status success"
export const NOT_STARTED_CLASS = "not-started"
export const COUNTED_CLASS = "success count-status"
export const NOT_STARTED = "NOT STARTED"
export const IN_PROGRESS = "INPROGRESS"
export const RadixParameter = 10
export const TagConstants = {
  TAGRANGE: "tagRange",
  MULTILIST: "multi-list",
  UPLOAD: "upload"
}
export const ConfirmActions = {
  UPDATE: "Update",
  IGNORE: "Ignore"
}
export const validationConstants = {
  CATEGORY: "Category",
  BUT: "but",
  LOCATION_END: "Location End",
  LOCATION_START: "Location Start"
}

export const SURVEY_STARBASED_LABELS = {
  1: "Poor",
  2: "Ok",
  3: "Needs Improvement",
  4: "Good",
  5: "Excellent"
}
const v2 = 2
const v3 = 3
const v4 = 4
export const getColour = value => {
  if (value === 1) {
    return "#FF0000"
  } else if (value === v2) {
    return "#FF8400"
  } else if (value === v3) {
    return "#DF9800"
  } else if (value === v4) {
    return "#48AE64"
  } else {
    return "#006341"
  }
}
export const SURVEY_PARAMS = {
  IS_MANAGER_FILLING: "isManagerFilling",
  REASON: "surveyReason",
  PRIVACY: "privacy"
}
export const PATH_MAP_FOR_BC = {
  AUDIT: "audit",
  COUNT: "count",
  DETAILS: "details",
  REPORTS: "reports",
  OUT_OF_RANGE: "out of range",
  VOIDED_AND_COUNTED: "voidedandcounted",
  TEAM: "team",
  VARIANCE: "variance",
  DRILLDOWN: "drill-down"
}

export const FILTERS_LOCAL_STORE = {
  AUDIT_FILTER: "auditPageFilter",
  COUNT_FILTER: "countPageFilter",
  PERSONS_PAGE_FILTER: "personsPageFilter",
  TAG_RANGE_FILTER: "tagRangePageFilter",
  CONSICE_VIEW: "consiceView",
  CATEGORIES_ACCORDIAN_LIST: "categoriesAccordionList",
  CONCISE_MAP_DATA: "conciseMapData",
  SHOW_UPDATED_GRID: "showUpdatedGrid",
  SHOW_DELETED: "showDeleted",
  TEAM_PAGE_FILTER: "teamPageFilter",
  DUPLICATE_TAG_CONSICE_VIEW: "duplicateTagReviewconsiceView",
  VARIANCE_PAGE_FILTER: "variancePageFilter",
  EXPANDED_ACCORDIAN: "expandedAccrodian",
  FULL_EXPANDED_ACCORDIAN: "fullexpandedAccrodian",
  SHOW_LOADER: "showloader",
  IS_FULL_EXPANDED: "isFullVarianceExpanded",
  EXPANDED_GRP_LIST: "expandedGroupList",
  FULL_VARIANCE: "fullVariance",
  GRP_BATCH_MAP: "groupVarianceBatchMap",
  BATCH_SORT: "batchSort",
  BATCH_FILTER: "batchFilter",
  SUMMARY_DATA: "summaryData",
  VARIANCE_GROUPS: "varianceGroups",
  FULL_BATCH_DETAILS: "fullBatchDetails",
  IS_GROUP_EXPANDED: "isGroupVarianceExpanded"
}

export const requiredErr = "This field is required."
export const oneOfErr = "This field value should be one of the options."
export const disallowedExtensions = [".php", ".exe", ".java", ".ps1", ".cs"]
export const PAGE_REFRESH_TIME_1MINS = 60000

export const languageCodeMap = {
  English: "us_en",
  French: "ca_fr",
  Spanish: "es_mx"
}

export const languageLocaleMap = {
  English: "en-US",
  French: "fr-CA",
  Spanish: "es-MX"
}

export const SCREEN_WIDTH_VL = 1440
export const ENTER_KEY = "Enter"
export const GENERIC_KEYS = [
  "Backspace",
  "Delete",
  "ArrowLeft",
  "ArrowRight",
  "ArrowUp",
  "ArrowDown",
  "Tab",
  ENTER_KEY
]
export const CTRL_KEYBOARD_SHORTCUTS = ["a", "c", "x"]
export const sourcePages = { TagRangePage: "TagRangePage" }

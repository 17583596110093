export const INPUT_FILE_STATUS = {
  SUCCESS: "success"
}
export const INVENTORY_STATUS = {
  COMPLETED: "closed",
  LOCKED: "locked"
}

export const languageCode = {
  US_EN: "US_EN",
  CA_FR: "CA_FR",
  us_en: "us_en",
  ca_fr: "ca_fr",
  es_mx:"es_mx",
  ES_MX:"ES_MX"
}

export const languages = {
  ENFLISH: "ENGLISH",
  FRENCH: "FRENCH",
  SPANISH:"SPANISH"
}

import {
  Grid,
  Divider,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material"

import React, { useCallback, useEffect, useState } from "react"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { useHistory } from "react-router-dom"
import { PATH } from "src/constants/paths"
import { setTagReset } from "src/redux/slices/bulkSlice"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import shortid from "shortid"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { encrypt } from "src/utils/helper"
import { CountService } from "src/service/count.service"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import ForwardIcon from "@mui/icons-material/Forward"
import { getTagCategory } from "src/redux/slices/countSlice"
import useLocationLable from "src/hooks/useLocationLable"
import { getEventDetail } from "src/redux/slices/inventorySlice"
import { ConfirmActions, validationConstants } from "src/utils/constants"
import { LoaderHelper } from "src/utils/LoaderHelper"

const TagsBulkValidation: React.FC<any> = ({ setPage }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { locationLable } = useLocationLable()
  const [mapping, setMapping] = useState([] as any)
  const tagData = useSelector((state: RootState) => state.bulk)
  const [localtagData, setLocaltagData] = useState(tagData)
  const [eventId, setEventId] = useState()
  const [apiProgress, setApiProgress] = useState(0)
  const [tagValRes, setTagValRes] = useState({} as any)
  const [radioStateLocation, setRadioStateLocation] = useState("")
  const [radioStateRange, setRadioStateRange] = useState("")
  const countData = useSelector((state: RootState) => state.count)
  const tagCategory = countData?.tagCategories
  const inventory: any = useSelector((state: RootState) => state.inventory)
  const dispatch = useAppThunkDispatch()
  const [loading, setLoading] = useState(false)

  const countService = new CountService()

  useEffect(() => {
    if (
      inventory?.inventoryData &&
      !Object.keys(inventory.inventoryData).length
    ) {
      if (
        !["cancelled", "loading", "failed"].includes(
          inventory.eventDetailStatus
        )
      ) {
        dispatch(getEventDetail())
          ?.then((res: any) => {
            setEventId(res?.payload?.data?.eventId)
          })
          .catch(_error => {
            console.error(_error)
          })
      }
    } else {
      setEventId(inventory?.inventoryData?.eventId)
    }
    tagValidationPost(
      false,
      null,
      null,
      inventory?.inventoryData?.eventId || eventId
    )
    if (
      tagCategory?.length === 0 &&
      countData?.tagCategoriesStatus !== "failed"
    ) {
      dispatch(getTagCategory())
    }
  }, [])

  const config = {
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      setApiProgress(percentCompleted)
    }
  }

  const tagValidationPost = async (
    isConfirmed: boolean,
    locationConfirmationAction: any,
    rangeConfirmationAction: any,
    idEvent: any
  ) => {
    const LOC_S_KEY =
      localtagData?.TagMappingData[validationConstants.LOCATION_START]
    let TRIMMED_DATA = JSON.parse(localtagData.TagFileData).map(r => {
      return { ...r, [LOC_S_KEY]: r[LOC_S_KEY].trim() }
    })
    TRIMMED_DATA = JSON.stringify(TRIMMED_DATA)
    const _payload = {
      idEvent,
      isConfirmed,
      eventUserId: 0,
      idCustomer: 0,
      LocationConfirmationAction: locationConfirmationAction,
      RangeConfirmationAction: rangeConfirmationAction,
      columns: Object.entries(localtagData.TagMappingData).map(m => {
        return {
          dataAttribute: encrypt(m[0]),
          csvAttribute: encrypt(m[1])
        }
      }),
      fileData: encrypt(TRIMMED_DATA)
    }

    try {
      setLoading(true)
      const dt = await countService.postTagValidationData(
        _payload,
        isConfirmed ? {} : config
      )
      setLoading(false)
      // Adding isSolvable flag as true for Category error (once we map it we can continue) and false for other errors(for other errors need to change in csv)
      const _tempInValidRowColumns = dt?.data?.inValidRowColumns?.map(i =>
        i.includes("Category")
          ? { text: i, isSolvable: true }
          : { text: i, isSolvable: false }
      )
      const _tagVal = {
        ...dt.data,
        tempInValidRowColumns: _tempInValidRowColumns
      }
      setTagValRes(_tagVal)

      checkErrorData(dt.data)
      if (isConfirmed) {
        history.push(PATH.COUNT)
      }
    } catch (_error) {
      console.error(_error)
    }
  }
  const checkErrorData = useCallback((data: any) => {
    const _dt = data.inValidRowColumns
      .filter(f => f.includes(validationConstants.CATEGORY))
      .map((m, i) => ({
        id: i + 1,
        row: m.split(":")[0],
        name: m.substring(
          m.indexOf("=") + 2,
          m.indexOf(validationConstants.BUT)
        ),
        value: ""
      }))

    setMapping(_dt)
  }, [])
  const handleRadioChangeLocation = useCallback(
    (e: any) => {
      e.preventDefault()
      e.stopPropagation()
      setRadioStateLocation(e.target.value)
    },
    [radioStateLocation]
  )
  const handleRadioChangeRange = useCallback(
    (e: any) => {
      e.preventDefault()
      e.stopPropagation()
      setRadioStateRange(e.target.value)
    },
    [radioStateRange]
  )
  // Checking to enable/disable continue btn depending on duplicate errors
  const checkForDuplicateError = useCallback(() => {
    const locationError =
      tagValRes?.duplicateLocations?.length > 0 &&
      radioStateLocation === "" &&
      tagValRes?.totalErrorRecordsCount > 0
    const rangeError =
      tagValRes?.duplicateRangeNames?.length > 0 &&
      radioStateRange === "" &&
      tagValRes?.totalErrorRecordsCount > 0
    if (
      tagValRes?.duplicateLocations?.length > 0 &&
      tagValRes?.duplicateRangeNames?.length > 0 &&
      radioStateLocation === "" &&
      radioStateRange === ""
    ) {
      return true
    } else if (locationError || rangeError) {
      return true
    } else if (
      (tagValRes?.duplicateLocations?.length > 0 &&
        radioStateLocation === "") ||
      (tagValRes?.duplicateRangeNames?.length > 0 && radioStateRange === "") ||
      apiProgress < 100
    ) {
      return true
    } else {
      return false
    }
  }, [
    tagValRes?.duplicateLocations,
    radioStateLocation,
    radioStateRange,
    tagValRes?.totalErrorRecordsCount,
    apiProgress
  ])

  const nextDisabled = useCallback(() => {
    if (tagValRes?.tempInValidRowColumns?.some(s => s.isSolvable === false)) {
      return true
    } else if (
      tagValRes?.totalErrorRecordsCount > 0 &&
      mapping?.some(m => !m.value)
    ) {
      return true
    } else if (loading === true) {
      return true
    } else {
      return checkForDuplicateError()
    }
  }, [
    tagValRes?.duplicateLocations,
    radioStateLocation,
    radioStateRange,
    tagValRes?.tempInValidRowColumns,
    mapping,
    loading
  ])
  const onCancelClick = useCallback(() => {
    dispatch(setTagReset())
    history.push(PATH.COUNT)
  }, [])

  const handleInputChange = useCallback(
    (e: any) => {
      const _temp = mapping.map(m =>
        m.id === e.target.name ? { ...m, value: e.target.value } : { ...m }
      )
      setMapping(_temp)

      const dt1 = JSON.parse(localtagData.TagFileData).map((m, i) => ({
        ...m,
        row: `Row${i + 1}`
      }))
      const finalDt = dt1.map(m => {
        const _tm = _temp.find(f => f.row === m.row)
        return _tm ? { ...m, Category: _tm.value } : { ...m }
      })
      const finalDtWithoutRow = finalDt.map(({ row, ...rest }) => rest)

      const modifiedTagFileDataString = JSON.stringify(finalDtWithoutRow)

      // Assign the modified string back to tagData.TagFileData

      setLocaltagData({ ...tagData, TagFileData: modifiedTagFileDataString })
    },
    [mapping]
  )
  return (
    <Grid container>
      <MiniHeader headerTitle={t(Translates.UPLOAD_FILE)} />
      <Grid container className="inner-view">
        <Grid item xs={12} className="main-container">
          <Grid container>
            <Grid item xs={12}>
              <h4 className="inner-view__title">{t(Translates.Validation)}</h4>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <p className="font-16 f-700 mb-0">
              {t(Translates.Validation_Completed)}
            </p>
            <Grid container spacing={2} className="justify-content-end mb-5">
              <span className="justify-content-end font-16">
                {apiProgress}% {t(Translates.Completed)}
              </span>
              <Grid item sm={12}>
                <div className={`custom-progressbar`}>
                  <LinearProgress
                    color="success"
                    variant="determinate"
                    value={apiProgress}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={11}>
                <p className="font-18 f-700">
                  {t(Translates.Validation_Outcome)}
                </p>
              </Grid>
              <Grid item xs={11} className="mb-3">
                <Divider />
              </Grid>
            </Grid>
            {!loading ? (
              <Grid container>
                {tagValRes?.totalRecordsCount > 0 ? (
                  <Grid item xs={12} sm={11}>
                    <Accordion className="custom-accordion dark validates success">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h5>
                          {tagValRes?.totalRecordsCount}
                          {tagValRes?.totalRecordsCount > 1
                            ? ` ${t(Translates.Records)}`
                            : ` ${t(Translates.Record)}`}
                          {`  ${t(Translates.will_be_inserted)}`}
                        </h5>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ul>
                          {tagValRes?.tagCountByCategory?.map((m: any) => (
                            <li key={shortid.generate()}>{m}</li>
                          ))}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={11}>
                    <Accordion className="custom-accordion dark validates success pointer-events-none">
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h5>{t(Translates.No_Records_will_be_inserted)} </h5>
                      </AccordionSummary>
                    </Accordion>
                  </Grid>
                )}
                {tagValRes?.duplicateLocations?.length > 0 && (
                  <Grid item xs={12} sm={11}>
                    <Accordion className="custom-accordion dark validates info">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h5>
                          {tagValRes?.duplicateLocations?.length}
                          {tagValRes?.duplicateLocations?.length > 1
                            ? ` ${t(Translates.Locations)}`
                            : ` ${t(Translates.Location)}`}
                          {` ${t(Translates.already_exist)}`}
                        </h5>
                        <Grid item xs={12} className="mt-1">
                          <FormControl>
                            <RadioGroup
                              row
                              data-testid="radio-group"
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={radioStateLocation}
                              onChange={handleRadioChangeLocation}
                            >
                              <FormControlLabel
                                value={ConfirmActions.UPDATE}
                                control={<Radio data-testid="radio-update" />}
                                label={t(Translates.Update)}
                              />
                              <FormControlLabel
                                value={ConfirmActions.IGNORE}
                                control={<Radio data-testid="radio-ignore" />}
                                label={t(Translates.Ignore)}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container xs={12}>
                          <Grid item xs={12} sm={12}>
                            <ul>
                              {tagValRes?.duplicateLocations?.map((m: any) => (
                                <li key={shortid.generate()}>{m}</li>
                              ))}
                            </ul>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
                {tagValRes?.duplicateRangeNames?.length > 0 && (
                  <Grid item xs={12} sm={11}>
                    <Accordion className="custom-accordion dark validates info">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h5>
                          {tagValRes?.duplicateRangeNames?.length}
                          {tagValRes?.duplicateRangeNames?.length > 1
                            ? ` ${t(Translates.Ranges)}`
                            : ` ${t(Translates.Range)}`}
                          {` ${t(Translates.already_exist)}`}
                        </h5>
                        <Grid item xs={12} className="mt-1">
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={radioStateRange}
                              onChange={handleRadioChangeRange}
                            >
                              <FormControlLabel
                                value={ConfirmActions.UPDATE}
                                control={<Radio data-testid="radio-update" />}
                                label={t(Translates.Update)}
                              />
                              <FormControlLabel
                                value={ConfirmActions.IGNORE}
                                control={<Radio data-testid="radio-ignore" />}
                                label={t(Translates.Ignore)}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container xs={12}>
                          <Grid item xs={12} sm={12}>
                            <ul>
                              {tagValRes?.duplicateRangeNames?.map((m: any) => (
                                <li key={shortid.generate()}>{m}</li>
                              ))}
                            </ul>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
                {tagValRes?.totalWarningRecordsCount > 0 && (
                  <Grid item xs={12} sm={11}>
                    <Accordion className="custom-accordion dark validates warning">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h5>{`${tagValRes?.totalWarningRecordsCount} ${
                          tagValRes?.totalWarningRecordsCount > 1
                            ? t(Translates.Records_have_warnings)
                            : t(Translates.Record_has_warning)
                        }`}</h5>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ul>
                          {tagValRes?.warningRowColumns?.map((m: any) => (
                            <li key={shortid.generate()}>{m}</li>
                          ))}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}

                {tagValRes?.totalErrorRecordsCount > 0 && (
                  <Grid item xs={12} sm={11}>
                    <Accordion className="custom-accordion dark validates error">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h5>
                          {tagValRes?.totalErrorRecordsCount}{" "}
                          {tagValRes?.totalErrorRecordsCount > 1
                            ? t(Translates.Records)
                            : t(Translates.Record)}{" "}
                          {t(Translates.have_Errors)}
                        </h5>
                      </AccordionSummary>

                      <AccordionDetails>
                        <ul>
                          {tagValRes?.inValidRowColumns.map((m: any) => (
                            <li key={shortid.generate()}>{m}</li>
                          ))}
                        </ul>
                        <div className="data-mapping-block">
                          <h4> {t(Translates.Data_Mapping)}</h4>

                          {mapping?.map((m: any) => (
                            <div
                              className="data-mapping__row"
                              key={shortid.generate()}
                            >
                              <label>{m?.name}</label>
                              <ForwardIcon className="mr-2 blue-text" />

                              <FormControl
                                key={shortid.generate()}
                                variant="filled"
                                size="small"
                                className="custom-input custom-select mb-0"
                              >
                                <InputLabel>
                                  {`${t(Translates.Select)} ${t(
                                    locationLable
                                  )} ${t(Translates.Category)}`}
                                </InputLabel>
                                <Select
                                  fullWidth
                                  id="demo-multiple-checkbox"
                                  data-testid={"category-select"}
                                  key={m}
                                  name={m.id}
                                  value={m.value || ""}
                                  onChange={handleInputChange}
                                >
                                  {tagCategory?.map((c: any, i) => (
                                    <MenuItem
                                      key={c.categoryLabel}
                                      value={c.categoryLabel || ""}
                                      className="dropdown-list"
                                    >
                                      <small data-testid={`coption-${i}`}>
                                        {c.categoryLabel}
                                      </small>
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
              </Grid>
            ) : (
              <LoaderHelper />
            )}
          </Grid>
          <Grid item xs={12}>
            <div className="inner-view__footer">
              <Grid container>
                <Grid item xs={12} sm={4} className="text-left">
                  <Button
                    variant="text"
                    className="primary-btn mr-5 cancel-btn"
                    data-testid="previous-button"
                    onClick={() => {
                      setPage("page1")
                    }}
                  >
                    <ArrowBackIcon className="mr-1" />
                    {t(Translates.Previous)}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={8} className="text-right">
                  <Button
                    variant="text"
                    className="primary-btn mr-5 cancel-btn"
                    data-testid="cancel-button"
                    onClick={onCancelClick}
                  >
                    {t(Translates.Cancel)}
                  </Button>
                  <Button
                    variant="contained"
                    className="primary-btn"
                    disabled={nextDisabled() === true}
                    data-testid="next-button"
                    onClick={() =>
                      tagValidationPost(
                        true,
                        radioStateLocation ? radioStateLocation : null,
                        radioStateRange ? radioStateRange : null,
                        eventId
                      )
                    }
                  >
                    {t(Translates.CONTINUE)}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default React.memo(TagsBulkValidation)

import React from "react"
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt"
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral"
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied"

export const renderDownTimeSmiley = (cellValue, primaryGoal, secondaryGoal) => {
  primaryGoal = primaryGoal * 100
  secondaryGoal = secondaryGoal * 100
  if (cellValue || cellValue === 0 || cellValue === 0.0) {
    if (cellValue <= primaryGoal) {
      return (
        <span className="accuracy-status success">
          <SentimentSatisfiedAltIcon data-testid="greenSmiley" />
          {`${cellValue}%`}
        </span>
      )
    } else if (cellValue > primaryGoal && cellValue <= secondaryGoal) {
      return (
        <span className="accuracy-status warning" data-testid="orangeSmiley">
          <SentimentNeutralIcon />
          {`${cellValue}%`}
        </span>
      )
    } else if (cellValue > secondaryGoal) {
      return (
        <span className="accuracy-status error" data-testid="redSmiley">
          <SentimentDissatisfiedIcon />
          {`${cellValue}%`}
        </span>
      )
    }
  }
  return "-"
}

export const renderAccuracySmiley = (cellValue, pGoal, sGoal) => {
  pGoal = pGoal * 100
  sGoal = sGoal * 100
  if (cellValue || cellValue === 0 || cellValue === 0.0) {
    if (!isNaN(cellValue)) {
      const formattedValue = Number.isInteger(cellValue) ? cellValue.toFixed(0) : cellValue.toFixed(1);      
      if (cellValue <= 0) {
        return (
          <span className="accuracy-status error">
            <SentimentDissatisfiedIcon />
            {`${0}%`}
          </span>
        );
      } else if (cellValue >= pGoal) {
        return (
          <span className="accuracy-status success">
            <SentimentSatisfiedAltIcon />
            {`${formattedValue}%`}
          </span>
        );
      } else if (cellValue >= sGoal && cellValue < pGoal) {
        return (
          <span className="accuracy-status warning">
            <SentimentNeutralIcon />
            {`${formattedValue}%`}
          </span>
        );
      } else if (cellValue < sGoal) {
        return (
          <span className="accuracy-status error">
            <SentimentDissatisfiedIcon />
            {`${formattedValue}%`}
          </span>
        );
      }
    } else {
      return "-";
    }
  }
  return "-";
};


import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface AuthState {
  allAPICalls: { [key: string]: any }
}

const initialState: AuthState = {
  allAPICalls: {}
}

export const apiDuplicateCancel = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAllAPICalls(state, action: PayloadAction<any>) {
      state.allAPICalls = action.payload
      return state
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAllAPICalls } = apiDuplicateCancel.actions

export default apiDuplicateCancel.reducer

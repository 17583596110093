import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { TeamService } from "../../service/team.service"
import { FILTERS_LOCAL_STORE } from "src/utils/constants"
//clearing to avoid retaining old event cache
if (window.location.pathname === "/login") {
  localStorage.removeItem(FILTERS_LOCAL_STORE.TEAM_PAGE_FILTER)
}
interface FilterInterface {
  status: string
}

export interface TeamState {
  status: string
  teamSummary: any
  teamSummaryStatus: string
  teamDetails: any
  teamDetailsStatus: string
  teamPersonsSummary: any
  teamPersonsSummaryStatus: string
  teamPageFilter: FilterInterface
}
const getFilterFromLocalStore = () => {
  let TEAM_F: any = localStorage.getItem(FILTERS_LOCAL_STORE.TEAM_PAGE_FILTER)
  TEAM_F = TEAM_F ? JSON.parse(TEAM_F) : { status: "" }
  return TEAM_F
}
const initialState: TeamState = {
  status: "",
  teamSummary: {},
  teamSummaryStatus: "",
  teamDetails: [],
  teamDetailsStatus: "",
  teamPersonsSummary: {},
  teamPersonsSummaryStatus: "",
  teamPageFilter: getFilterFromLocalStore()
}
const initialStateForLogout: TeamState = {
  status: "",
  teamSummary: {},
  teamSummaryStatus: "",
  teamDetails: [],
  teamDetailsStatus: "",
  teamPersonsSummary: {},
  teamPersonsSummaryStatus: "",
  teamPageFilter: { status: "" }
}

const teamService = new TeamService()
export const getTeamSummary = createAsyncThunk(
  "teamList/getTeamSummary",
  async () => {
    return teamService.getTeamSummary()
  }
)
export const getTeamDetails = createAsyncThunk(
  "teamList/getTeamDetails",
  async () => {
    return teamService.getTeamDetails()
  }
)
export const getTeamPersonsSummary = createAsyncThunk(
  "teamList/getTeamPersons",
  async (params: any) => {
    let eventUserId = params.userId
    return teamService.getTeamPersonsSummary(eventUserId)
  }
)

//Team Slice
export const teamSlice = createSlice({
  name: "teamList",
  initialState,
  reducers: {
    updateTeamPageFilter(state, action: PayloadAction<any>) {
      state.teamPageFilter = action.payload
      localStorage.setItem(
        FILTERS_LOCAL_STORE.TEAM_PAGE_FILTER,
        JSON.stringify(state.teamPageFilter)
      )
      return state
    },
    clearTeamState() {
      return initialStateForLogout
    }
  },
  extraReducers: builder => {
    builder.addCase(getTeamSummary.pending, state => {
      state.teamSummaryStatus = "loading"
    })
    builder.addCase(getTeamSummary.fulfilled, (state, { payload }) => {
      state.teamSummaryStatus = "success"
      state.teamSummary = payload.data
    })
    builder.addCase(getTeamSummary.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.teamSummaryStatus = "cancelled"
      } else {
        state.teamSummaryStatus = "failed"
      }
    })
    builder.addCase(getTeamDetails.pending, state => {
      state.teamDetailsStatus = "loading"
    })
    builder.addCase(getTeamDetails.fulfilled, (state, { payload }) => {
      state.teamDetailsStatus = "success"
      state.teamDetails = payload.data
    })
    builder.addCase(getTeamDetails.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.teamDetailsStatus = "cancelled"
      } else {
        state.teamDetailsStatus = "failed"
      }
    })
    builder.addCase(getTeamPersonsSummary.pending, state => {
      state.teamPersonsSummaryStatus = "loading"
    })
    builder.addCase(getTeamPersonsSummary.fulfilled, (state, { payload }) => {
      state.teamPersonsSummaryStatus = "success"
      state.teamPersonsSummary = payload.data
    })
    builder.addCase(getTeamPersonsSummary.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.teamPersonsSummaryStatus = "cancelled"
      } else {
        state.teamPersonsSummaryStatus = "failed"
      }
    })
  }
})
export const { updateTeamPageFilter, clearTeamState } = teamSlice.actions
export default teamSlice.reducer

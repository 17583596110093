import IconButton from "@mui/material/IconButton"
import PdfIcon from "../../assets/images/FilePdfIcon.svg"
import { decrypt } from "../../utils/helper"
import { Tooltip } from "@mui/material"
import { KEY_CODES } from "src/utils/constants"
import { useCallback } from "react"

const ReportToolbar: React.FC<any> = (props: any) => {
  const downloadFile = async (url: any) => {
    const actualUrl = await decrypt(url)
    window.open(actualUrl)
  }

  const handlePdfOpenClick = useCallback(url => {
    downloadFile(url)
  }, [])

  const handlePdfOpenClickKeyUp = useCallback((e, url) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      downloadFile(url)
    }
  }, [])

  return (
    <div>
      <Tooltip arrow title="Download PDF">
        <IconButton
          className="icon-btn mx-2"
          data-testid="RDRPDFViewBtn"
          onClick={() => handlePdfOpenClick(props.pdfUrl)}
          onKeyUp={e => handlePdfOpenClickKeyUp(e, props.pdfUrl)}
        >
          <img src={PdfIcon} alt="pdf-icon" />
        </IconButton>
      </Tooltip>
    </div>
  )
}
export default ReportToolbar

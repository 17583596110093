import React, { useEffect, useState } from "react"
import GlobalTable, {
  SelectCustomColumnFilter
} from "../../../src/components/GlobalTable/GlobalTable"
import { Grid } from "@mui/material"
import { RootState } from "../../../src/redux/store"
import { AuditService } from "../../../src/service/audit.service"
import { useHistory, useRouteMatch } from "react-router-dom"
import { PATH } from "../../../src/constants/paths"
import useLocationLable from "../../../src/hooks/useLocationLable"
import { renderAccuracySmiley } from "../Team/teamHelper"
import { useSelector } from "react-redux"
import { encryptUrlParams } from "../../../src/utils/helper"
import { useTranslation } from "react-i18next"
import { ROWCLICK_COLUMNSTOSUPRESS } from "src/utils/constants"
import { Translates } from "src/i18n/i18n"
import Loader from "src/components/Loader"


const AuditPersonView = () => {
  const auditService = new AuditService()
  const { t } = useTranslation()
  const history = useHistory()
  let { url } = useRouteMatch()
  const [tagPersonList, setTagPersonList] = useState([] as any)
  const [requiredAuditsPerCounterIsNull, setRequiredAuditsPerCounterIsNull] =
    useState(false)
  const [loading, setLoading] = useState(true)
  const { locationLable } = useLocationLable()
  const [stdTimeZone,setTimeZoneLocation] = useState("")

  const auditPersonsFromStore = useSelector(
    (state: RootState) => state.audit.auditPersons
  )
  const auditSummaryFromState = useSelector(
    (state: RootState) => state.audit.auditSummary
  )
  const filterFromStore = useSelector(
    (state: RootState) => state.audit.auditPageFilter.personViewStatus
  )
  useEffect(() => {
    formatData(auditPersonsFromStore)
  }, [auditPersonsFromStore])
  useEffect(() => {
    showAuditPersons()
  }, [])
  const formatData = data => {
    let personViewData = data?.personViewData || []
    personViewData = personViewData.map(record => {
      return {
        ...record,
        statusStr: record.auditsRemaining == 0 ? t(Translates.COMPLETED) : ""
      }
    })
    setTagPersonList(personViewData)
    setRequiredAuditsPerCounterIsNull(data?.requiredAuditsPerCounterIsNull)
  }

  const showAuditPersons = () => {
    auditService
      .getAuditPersons()
      .then((temp: any) => {
        if (!temp || (Array.isArray(temp) && !temp.length)) {
          history.push(PATH.PAGE_NOT_FOUND)
        } else {
          formatData(temp.data)
        }
        setLoading(false)
      })
      .catch(_error => {
        if (
          typeof _error === "string" &&
          !_error.includes("duplicate request")
        ) {
          setLoading(false)
        }
      })
  }

  const OnCellClick = (rowObj, cellObj) => {
    const rowValues = rowObj.values
    const pathURL = `${url}/${encryptUrlParams(
      rowObj.original.name
    )}/${encryptUrlParams(parseInt(rowObj.original.userId))}`

    if (
      ROWCLICK_COLUMNSTOSUPRESS.toUpperCase().indexOf(
        cellObj.column.Header.toUpperCase()
      ) <= -1
    ) {
      history.push(pathURL, {
        rowValues,
        stdTimeZone:stdTimeZone
      })
    }
  }
  const displayDateWithTimeZone = (time, timezoneValue) => {
    const displayTimeZoneLabel = (
      <div style={{ textAlign: "center" }}>
        {time}
        {timezoneValue && (<><br />{timezoneValue}</>)}
      </div>
    )
    return displayTimeZoneLabel
  }
  
  const columnsData = React.useMemo(
    () => [
      {
        Header: t(Translates.name),
        accessor: "name",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150,
        Cell: ({ row, value }) => {
          return <span className="blue-text cursor-pointer"> {value} </span>
        }
      },
      {
        Header: `${t(locationLable)}s  ${t(Translates.Counted)}`,
        accessor: "locationsCounts",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150
      },
      {
        Header: `${t(locationLable)}s ${t(Translates.Audited)}`,
        accessor: "locationAudits",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150
      },
      ...(auditSummaryFromState.requiredAuditsPerCounter
        ? [
            {
              Header: t(Translates.Audits_Remaining),
              accessor: "auditsRemaining",
              headerClassName: "temp_class",
              disableFilters: true,
              disableSortBy: false,
              setWidth: 150,
              Cell: ({ value }) => {
                return value < 0 ? 0 : value
              }
            }
          ]
        : []),
      {
        Header: t(Translates.accuracy),
        accessor: "accuracy",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 110,
        Cell: ({ row, value }) => {
          return renderAccuracySmiley(
            value,
            auditSummaryFromState.goal,
            auditSummaryFromState.secondaryGoal
          )
        }
      },
      {
        Header: t(Translates.STATUS),
        accessor: "statusStr",
        headerClassName: "temp_class",
        Filter: SelectCustomColumnFilter, //SelectColumnFilter,
        filter: "equals",
        sourcePage: "auditPersonView",
        id: "auditPersonStatus",
        disableFilters: false,
        disableSortBy: false,
        setWidth: 150,
        Cell: ({ row }) => {
          return row.original.auditsRemaining == 0 ? (
            <span className="status-bg audit-status success">
              {t(Translates.COMPLETED)}
            </span>
          ) : (
            " "
          )
        }
      }
    ],
    [locationLable, filterFromStore, t, auditSummaryFromState]
  )

  const displayGrid = () => {
    return (
      <Grid container className="table-responsive">
        <Grid xs={12} data-testid="APVGlobTable">
          <GlobalTable
            columns={columnsData}
            data={tagPersonList}
            noDataText={`${t(Translates.No)} Persons ${t(Translates.Found)}`}
            OnCellClick={OnCellClick}
            initialStateProp={{
              hiddenColumns: requiredAuditsPerCounterIsNull
                ? ["auditsRemaining", "status"]
                : [],
              sortBy: [
                {
                  id: columnsData.length ? columnsData[0].accessor : "",
                  desc: false
                }
              ],
              sourcePage: "auditPersonView",
              filters: filterFromStore?.length
                ? [
                    {
                      id: "auditPersonStatus",
                      value: filterFromStore === "ALL" ? "" : filterFromStore
                    }
                  ]
                : []
            }}
          ></GlobalTable>
        </Grid>
      </Grid>
    )
  }

  return <>{loading ? <Loader /> : displayGrid()}</>
}
export default AuditPersonView

import {
  getTeamDetails,
  getTeamPersonsSummary
} from "./../redux/slices/teamSlice"
import {
  getAuditTags,
  getAuditPersons,
  getAuditSummary,
  getPersons
} from "./../redux/slices/auditSlice"
import {
  getCountList,
  getCountSuccess,
  getOORTagDetails,
  getTagRanges,
  getTagDetails,
  getTeamEfficiency,
  getAuditProgress,
  getDuplicateTags,
  getDuplicateTagsReview,
  getVoidBannerDetails,
  getTagDetailsRecordHistory,
  getTagDetailsHistory,
  getTagEditDetails,
  CountPageBannersData,
  IsFileAvailableToProcess
} from "../redux/slices/countSlice"
import { getTeamSummary } from "../redux/slices/teamSlice"
import {
  getFullVarianceBatchDetails,
  getVarianceBatchDetails,
  getVarianceResearchPercentage
} from "src/redux/slices/varianceSlice"
import { getEventDetail } from "src/redux/slices/inventorySlice"

const interval: number = 60000 // 1minute
const recordHistory = "recordHistory"

export const intervalVarNames = {
  FOOTER: "footer",
  OORTAGS: "OORTags",
  COUNTLIST: "countList",
  COUNTSUCCESS: "countSuccess",
  RANGEPAGE: "rangePage",
  TAGDETAILPAGE: "tagdetails",
  AUDITPERSONPAGE: "auditPersonPage",
  AUDITTAGSPAGE: "auditTagsPage",
  AUDITPAGE: "auditPage",
  PERSONSVIEWPAGE: "personsViewPage",
  TEAMPAGE: "teamPage",
  TEAMPERSONSVIEW: "teamPersonsView",
  VOIDEDTAGS: "voidedtags",
  DUPLICATETAGSSUMMARY: "duplicateTagsSummaryPage",
  DUPLICATETAGSREVIEWPAGE: "duplicateTagsReviewPage",
  TAG_DETAILS_HISTORY_PAGE: "tagDetailsHistory",
  FULL_VARIANCE_PAGE: "fullVariancePage",
  GROUP_VARIANCE_PAGE: "groupVariancePage",
  CLOSEINVENTORY: "closeInventory"
}

const pageWiseRunStatus = {
  countpage: false,
  OORTpage: false,
  footer: false,
  tagdetails: false,
  rangepage: false,
  auditPersonPage: false,
  auditTagsPage: false,
  auditPage: false,
  personsViewPage: false,
  teamPage: false,
  teamPersonsView: false,
  voidedPage: false,
  duplicateTagsSummaryPage: false,
  duplicateTagsReviewPage: false,
  tagDetailsHistory: false,
  fullVariancePage: false,
  groupVariancePage: false,
  closeInventory: false
}
export class IntervalsList {
  footer: any = null
  OORTags: any = null
  countList: any = null
  rangePage: any = null
  tagdetails: any = null
  auditPersonPage: any = null
  auditTagsPage: any = null
  auditPage: any = null
  personsViewPage: any = null
  teamPage: any = null
  teamPersonsView: any = null
  voidedTags: any = null
  duplicateTagsSummaryPage: any = null
  duplicateTagsReviewPage: any = null
  tagDetailsHistory: any = null
  variance: any = null
  fullVariance: any = null
  groupVariance: any = null
  closeInventory: any = null
}

let intervalIdVars = new IntervalsList()

const isUserLoggedIn = () => {
  return localStorage.getItem("accessToken")
}

export const setRunPollStatus = (status: boolean, page: string) => {
  pageWiseRunStatus[page] = status
}

let dispatchFunc: any
let rangeIdVar: number
// Tag number of tag details page if loaded
let currentTagNum: number | string
let personEventUserId: number | string
let teamPersonEventUserId: number | string
let duplicateInstanceIdValue: number | string
let originalInstanceIdsValues: number | Array<any>
let _varianceGroup: string
let _validationControlId: number | string
let _varianceGroupList: Array<any>

export const countPagePoll = dispatch => {
  try {
    dispatchFunc = dispatch || dispatchFunc
    if (dispatch) setRunPollStatus(true, "countpage")
    if (!dispatch) {
      dispatchFunc(getEventDetail())
      dispatchFunc(getOORTagDetails())
      dispatchFunc(getDuplicateTags())
      dispatchFunc(getVoidBannerDetails())
      dispatchFunc(CountPageBannersData())
      footerPoll(dispatchFunc)
    }
    if (pageWiseRunStatus.countpage && isUserLoggedIn()) {
      console.log("---------------countPagePoll-------------")
      intervalIdVars.countList = setTimeout(() => {
        countPagePoll(null)
      }, interval)
    }
  } catch (error) {
    console.error("error in polling api")
  }
  // }
}
export const rangePagePoll = (dispatch, rangeId) => {
  try {
    dispatchFunc = dispatch || dispatchFunc
    rangeIdVar = rangeId || rangeIdVar
    if (dispatch && rangeIdVar) setRunPollStatus(true, "rangepage")
    if (!dispatch) {
      dispatchFunc(getEventDetail())
      dispatchFunc(getTagRanges(rangeIdVar))
      footerPoll(dispatchFunc)
    }
    if (pageWiseRunStatus.rangepage && isUserLoggedIn()) {
      console.log("---------------rangePagePoll-------------")
      intervalIdVars.rangePage = setTimeout(() => {
        rangePagePoll(null, rangeIdVar)
      }, interval)
    }
  } catch (error) {
    console.error("error in polling api")
  }
}
export const footerPoll = dispatchFunc => {
  try {
    dispatchFunc(getEventDetail())
    dispatchFunc(getCountList())
    dispatchFunc(getTeamEfficiency())
    dispatchFunc(getAuditProgress())
    dispatchFunc(getTeamSummary())
    dispatchFunc(getVarianceResearchPercentage(0))
    dispatchFunc(IsFileAvailableToProcess())
    console.log("---------------footerPoll-------------")
  } catch (error) {
    console.error("error in polling api")
  }
}

export const outOfRanngeTagsPagePoll = dispatch => {
  try {
    dispatchFunc = dispatch || dispatchFunc
    if (dispatch) setRunPollStatus(true, "OORTpage")
    if (!dispatch) {
      dispatchFunc(getEventDetail())
      dispatchFunc(getOORTagDetails())
      footerPoll(dispatchFunc)
    }
    if (pageWiseRunStatus.OORTpage && isUserLoggedIn()) {
      console.log("---------------outOfRanngeTagsPagePoll-------------")
      intervalIdVars.OORTags = setTimeout(() => {
        outOfRanngeTagsPagePoll(null)
      }, interval)
    }
  } catch (error) {
    console.error("error in polling api")
  }
}
export const voidedAndCountedTagsPagePoll = dispatch => {
  try {
    dispatchFunc = dispatch || dispatchFunc
    if (dispatch) setRunPollStatus(true, "voidedPage")
    if (!dispatch) {
      dispatchFunc(getEventDetail())
      dispatchFunc(getVoidBannerDetails())
      footerPoll(dispatchFunc)
    }
    if (pageWiseRunStatus.voidedPage && isUserLoggedIn()) {
      console.log("---------------voidedAndCountedTagsPagePoll-------------")
      intervalIdVars.voidedTags = setTimeout(() => {
        voidedAndCountedTagsPagePoll(null)
      }, interval)
    }
  } catch (error) {
    console.error("error in polling api")
  }
}

export const TagDetailPagePoll = (dispatch, locationValue) => {
  try {
    dispatchFunc = dispatch || dispatchFunc
    currentTagNum = locationValue || currentTagNum
    if (dispatch) setRunPollStatus(true, "tagdetails")
    if (!dispatch) {
      dispatchFunc(getEventDetail())
      dispatchFunc(getTagDetails({ locationValue: currentTagNum }))
      dispatchFunc(getTagEditDetails({ locationValue: currentTagNum }))
      footerPoll(dispatchFunc)
    }
    if (pageWiseRunStatus.tagdetails && isUserLoggedIn()) {
      console.log("---------------TagDetailPagePoll-------------")
      intervalIdVars.tagdetails = setTimeout(() => {
        TagDetailPagePoll(null, locationValue)
      }, interval)
    }
  } catch (error) {
    console.error("error in polling api")
  }
}
export const AuditPagePoll = dispatch => {
  try {
    dispatchFunc = dispatch || dispatchFunc
    if (dispatch) setRunPollStatus(true, "auditPage")
    if (!dispatch) {
      dispatchFunc(getEventDetail())
      dispatchFunc(getAuditTags())
      dispatchFunc(getAuditPersons())
      dispatchFunc(getAuditSummary())
      footerPoll(dispatchFunc)
    }
    if (pageWiseRunStatus.auditPage && isUserLoggedIn()) {
      console.log("---------------AuditPagePoll-------------")
      intervalIdVars.auditPage = setTimeout(() => {
        AuditPagePoll(null)
      }, interval)
    }
  } catch (error) {
    console.error("error in polling api")
  }
}
export const PersonsViewPagePoll = (dispatch, eventUserId) => {
  try {
    dispatchFunc = dispatch || dispatchFunc
    personEventUserId = eventUserId || personEventUserId
    if (dispatch) setRunPollStatus(true, "personsViewPage")
    if (!dispatch) {
      dispatchFunc(getEventDetail())
      dispatchFunc(getPersons({ eventUserId: personEventUserId }))
      footerPoll(dispatchFunc)
    }
    if (pageWiseRunStatus.personsViewPage && isUserLoggedIn()) {
      console.log("---------------PersonsViewPagePoll-------------")
      intervalIdVars.personsViewPage = setTimeout(() => {
        PersonsViewPagePoll(null, eventUserId)
      }, interval)
    }
  } catch (error) {
    console.error("error in polling api")
  }
}
export const DuplicateTagsSummaryPoll = dispatch => {
  try {
    dispatchFunc = dispatch || dispatchFunc
    if (dispatch) setRunPollStatus(true, "duplicateTagsSummaryPage")
    if (!dispatch) {
      dispatchFunc(getEventDetail())
      dispatchFunc(getDuplicateTags())
      footerPoll(dispatchFunc)
    }
    if (pageWiseRunStatus.duplicateTagsSummaryPage && isUserLoggedIn()) {
      console.log("---------------DuplicateTagsSummaryPoll-------------")
      intervalIdVars.duplicateTagsSummaryPage = setTimeout(() => {
        DuplicateTagsSummaryPoll(null)
      }, interval)
    }
  } catch (error) {
    console.error("error in polling api")
  }
}
export const DuplicateTagsReviewPagePoll = (
  dispatch,
  duplicateInstanceId,
  originalInstanceIds
) => {
  try {
    dispatchFunc = dispatch || dispatchFunc
    duplicateInstanceIdValue = duplicateInstanceId || duplicateInstanceIdValue
    originalInstanceIdsValues = originalInstanceIds || originalInstanceIdsValues
    if (dispatch) setRunPollStatus(true, "duplicateTagsReviewPage")
    if (!dispatch) {
      dispatchFunc(getEventDetail())
      dispatchFunc(
        getDuplicateTagsReview({
           idLocation: duplicateInstanceIdValue,
          locationValue: originalInstanceIdsValues
        })
      )
      footerPoll(dispatchFunc)
    }
    if (pageWiseRunStatus.duplicateTagsReviewPage && isUserLoggedIn()) {
      console.log("---------------DuplicateTagsReviewPagePoll-------------")
      intervalIdVars.duplicateTagsReviewPage = setTimeout(() => {
        DuplicateTagsReviewPagePoll(
          null,
          duplicateInstanceId,
          originalInstanceIds
        )
      }, interval)
    }
  } catch (error) {
    console.error("error in polling api")
  }
}

export const TeamPagePoll = dispatch => {
  try {
    dispatchFunc = dispatch || dispatchFunc
    if (dispatch) setRunPollStatus(true, "teamPage")
    if (!dispatch) {
      dispatchFunc(getEventDetail())
      dispatchFunc(getTeamDetails())
      dispatchFunc(getCountSuccess())
      footerPoll(dispatchFunc)
    }
    if (pageWiseRunStatus.teamPage && isUserLoggedIn()) {
      console.log("---------------TeamPagePoll-------------")
      intervalIdVars.teamPage = setTimeout(() => {
        TeamPagePoll(null)
      }, interval)
    }
  } catch (error) {
    console.error("error in polling api")
  }
}

export const TeamPersonsViewPagePoll = (dispatch, userId) => {
  try {
    dispatchFunc = dispatch || dispatchFunc
    teamPersonEventUserId = userId || teamPersonEventUserId
    if (dispatch) setRunPollStatus(true, "teamPersonsView")
    if (!dispatch) {
      dispatchFunc(getEventDetail())
      dispatchFunc(getTeamPersonsSummary({ userId: teamPersonEventUserId }))
      dispatchFunc(getPersons({ eventUserId: teamPersonEventUserId }))
      footerPoll(dispatchFunc)
    }
    if (pageWiseRunStatus.teamPersonsView && isUserLoggedIn()) {
      console.log("---------------TeamPersonsViewPagePoll-------------")
      intervalIdVars.teamPersonsView = setTimeout(() => {
        TeamPersonsViewPagePoll(null, userId)
      }, interval)
    }
  } catch (error) {
    console.error("error in polling api")
  }
}
export const TagDetailsHistoryPagePoll = (dispatch, val, type) => {
  try {
    dispatchFunc = dispatch || dispatchFunc
    currentTagNum = val || currentTagNum
    if (dispatch) setRunPollStatus(true, "tagDetailsHistory")
    if (!dispatch) {
      if (type === recordHistory) {
        dispatchFunc(
          getTagDetailsRecordHistory({ locationValue: currentTagNum })
        )
      } else {
        dispatchFunc(getTagDetailsHistory({ locationValue: currentTagNum }))
      }
      dispatchFunc(getEventDetail())
      footerPoll(dispatchFunc)
    }
    if (pageWiseRunStatus.tagDetailsHistory && isUserLoggedIn()) {
      intervalIdVars.tagDetailsHistory = setTimeout(() => {
        TagDetailsHistoryPagePoll(null, val, type)
      }, interval)
    }
  } catch (error) {
    console.error("error in polling api")
  }
}

export const fullVariancePagePoll = (
  dispatch,
  varianceGroup,
  validationControlId
) => {
  try {
    dispatchFunc = dispatch || dispatchFunc
    _varianceGroup = varianceGroup || _varianceGroup
    _validationControlId = validationControlId || _validationControlId
    if (dispatch && _varianceGroup && _validationControlId)
      setRunPollStatus(true, "fullVariancePage")
    if (!dispatch) {
      if (_varianceGroup && _validationControlId) {
        dispatchFunc(
          getFullVarianceBatchDetails({
            varianceGroup: _varianceGroup,
            validationControlId: _validationControlId
          })
        )
      }
      dispatchFunc(getEventDetail())
      footerPoll(dispatchFunc)
    }
    if (pageWiseRunStatus.fullVariancePage && isUserLoggedIn()) {
      console.log(
        "---------------setting timeout fullVariancePagePoll page-------------"
      )
      intervalIdVars.fullVariance = setTimeout(() => {
        fullVariancePagePoll(null, varianceGroup, validationControlId)
      }, interval)
    }
  } catch (error) {
    console.error("error in polling api")
  }
}

export const groupVariancePagePoll = async (
  dispatch,
  groupList: any[] = []
) => {
  try {
    dispatchFunc = dispatch || dispatchFunc
    _varianceGroupList = groupList
    if (dispatch && _varianceGroupList.length)
      setRunPollStatus(true, "groupVariancePage")
    if (!dispatch) {
      _varianceGroupList.map(VG => {
        if (
          VG.varianceGroup &&
          VG.idValidationControl &&
          VG.varianceGroupName
        ) {
          dispatchFunc(
            getVarianceBatchDetails({
              varianceGroup: VG.varianceGroup,
              idValidationControl: VG.idValidationControl,
              varianceGroupName: VG.varianceGroupName
            })
          )
        }
      })
      dispatchFunc(getEventDetail())
      footerPoll(dispatchFunc)
    }
    if (pageWiseRunStatus.groupVariancePage && isUserLoggedIn()) {
      console.log(
        "---------------setting timeout group variance page-------------"
      )
      intervalIdVars.groupVariance = setTimeout(() => {
        return groupVariancePagePoll(null, groupList)
      }, interval)
    }
  } catch (error) {
    console.error("error in polling api", error)
  }
}
export const closeInventoryPagePoll = dispatch => {
  try {
    dispatchFunc = dispatch || dispatchFunc
    if (dispatch) setRunPollStatus(true, "closeInventory")
    if (!dispatch) {
      dispatchFunc(getEventDetail())
      dispatchFunc(getCountSuccess())
      footerPoll(dispatchFunc)
    }
    if (pageWiseRunStatus.closeInventory && isUserLoggedIn()) {
      console.log("---------------setting timeout variance page-------------")
      intervalIdVars.closeInventory = setTimeout(() => {
        closeInventoryPagePoll(null)
      }, interval)
    }
  } catch (error) {
    console.error("error in polling api")
  }
}
export const clearPoll = async (pollList: string[], page: string) => {
  setRunPollStatus(false, page)

  pollList.forEach((intervalIdVar: string) => {
    if (
      intervalIdVars &&
      intervalIdVars.hasOwnProperty(intervalIdVar) &&
      intervalIdVars[intervalIdVar]
    ) {
      clearTimeout(intervalIdVars[intervalIdVar])
    }
  })
}

export const clearAllPolls = async () => {
  Object.keys(intervalIdVars).forEach((intervalKey: string) => {
    if (intervalIdVars[intervalKey]) clearTimeout(intervalIdVars[intervalKey])
  })
}

export const gridColumns = {
  LOCATION: "Location",
  PRODUCT_BARCODE: "ProductBarcode",
  PRODUCT_SKU: "ProductSku",
  PRODUCT_DESCRIPTION: "ProductDescription",
  DEPARTMENT: "Department",
  QTY: "Qty",
  PRICE: "Price",
  SUB_TOTAL: "SubTotal"
}
export const AdjfilterNames = {
  TAG: "tag",
  BARCODE: "barcode",
  SKU: "sku",
  DESCRIPTION: "description",
  ADJ_SOURCE_NAME: "adjustmentSourceName",
  ADJ_SOURCE: "adjustmentSource"
}

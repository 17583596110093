import React, { ReactNode, useState, useEffect, useRef } from "react"
import Header from "../components/Header/Header"
import styles from "./MainLayout.module.scss"
import useInventoryStatus from "../hooks/useInventoryStatus"
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined"
import { Avatar } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import {
  setTagRangePageScrollY,
  updateFooterToggleView
} from "../redux/slices/countSlice"
import { RootState } from "../redux/store"
import { getInputFiles } from "../redux/slices/configurationSlice"
import { INPUT_FILE_STATUS } from "../constants/common"
import { KEY_CODES, sourcePages } from "src/utils/constants"
import chatIconAvatar from "src/assets/images/ChatIconAvatar.png"
import chatIcon from "src/assets/images/ChatIcon.png"
import { useHistory } from "react-router-dom"

declare global {
  interface Window {
    WebChat: any
  }
}

interface Props {
  children: ReactNode
  sourcePage?: string
  scrollCheckData?: any
}

export default function MainLayout(props: Props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const layoutContainerRef = useRef<any>(null)
  const { isDisable } = useInventoryStatus()
  const footerViewFromStore = useSelector(
    (state: RootState) => state.count?.footerToggleView
  )

  const eventId: any = useSelector(
    (state: RootState) => state.inventory?.inventoryData?.eventId
  )
  const isInputFilesLoaded = useSelector(
    (state: RootState) =>
      state.config?.inputFileData?.status === INPUT_FILE_STATUS.SUCCESS
  )
  const config: any = useSelector((state: RootState) => state.config)
  const webchatRef: any = useRef(null)
  const [isWebChatVisible, setIsWebChatVisible] = useState(false)
  const { children, sourcePage, scrollCheckData } = props
  const [toggleFooter, setToggleFooter] = useState(true)

  const tagRangePageScrollY = useSelector(
    (state: RootState) => state.count.tagRangePageScrollY
  )

  const footerToggleClick = () => {
    let toggledOption = !toggleFooter
    setToggleFooter(toggledOption)
    dispatch(updateFooterToggleView(toggledOption))
  }
  useEffect(() => {
    setToggleFooter(footerViewFromStore)
  }, [footerViewFromStore])

  useEffect(() => {
    if (
      !isInputFilesLoaded &&
      !["cancelled", "loading"].includes(
        config?.inputFileData?.inputFilesStatus
      )
    ) {
      dispatch(getInputFiles())
    }
  }, [])
  const handleKeyUp = (e: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      footerToggleClick()
    }
  }

  // useEffect(() => {
  //   const handleClickOutside = event => {
  //     if (webchatRef.current && !webchatRef.current.contains(event.target)) {
  //       setIsWebChatVisible(false)
  //     }
  //   }

  //   // Add the event listener when the component mounts
  //   if (isWebChatVisible) {
  //     document.addEventListener("mousedown", handleClickOutside)
  //   }

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside)
  //   }
  // }, [isWebChatVisible])

  // const triggerChatBot = async () => {
  //   setIsWebChatVisible(prevState => !prevState)

  //   if (!isWebChatVisible) {
  //     const styleOptions = {
  //       hideUploadButton: true
  //     }

  //     const tokenEndpointURL = new URL(
  //       process.env.REACT_APP_COPILOT_TOKEN_URL as string
  //     )
  //     const locale = document.documentElement.lang || "en-US"
  //     const apiVersion = tokenEndpointURL.searchParams.get("api-version")

  //     const [directLineURL, token] = await Promise.all([
  //       fetch(
  //         new URL(
  //           `/powervirtualagents/regionalchannelsettings?api-version=${apiVersion}`,
  //           tokenEndpointURL
  //         )
  //       )
  //         .then(response => {
  //           if (!response.ok) {
  //             throw new Error("Failed to retrieve regional channel settings.")
  //           }
  //           return response.json()
  //         })
  //         .then(({ channelUrlsById: { directline } }) => directline),
  //       fetch(tokenEndpointURL)
  //         .then(response => {
  //           if (!response.ok) {
  //             throw new Error("Failed to retrieve Direct Line token.")
  //           }
  //           return response.json()
  //         })
  //         .then(({ token }) => token)
  //     ])

  //     const directLine = window.WebChat.createDirectLine({
  //       domain: new URL("v3/directline", directLineURL),
  //       token
  //     })

  //     const first_name = localStorage.getItem("firstName") || ""
  //     const last_name = localStorage.getItem("lastName") || ""
  //     const custId = localStorage.getItem("custId") || ""

  //     const subscription = directLine.connectionStatus$.subscribe({
  //       next(value) {
  //         if (value === 2) {
  //           directLine
  //             .postActivity({
  //               localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  //               locale,
  //               name: "startConversation",
  //               type: "event",
  //               channelData: {
  //                 firstlast: `${first_name} ${last_name}`,
  //                 id_customer: custId || null,
  //                 id_event: eventId
  //               }
  //             })
  //             .subscribe()
  //           subscription.unsubscribe()
  //         }
  //       }
  //     })

  //     window.WebChat.renderWebChat(
  //       { directLine, locale, styleOptions },
  //       webchatRef.current
  //     )

  //     const targetElement = document.getElementById("webchatbanner")
  //     if (targetElement) {
  //       targetElement.style.display = "flex"
  //     }
  //   }
  // }

  useEffect(() => {
    const handleNavigation = (_location, action) => {
      if (action === "PUSH") {
        if (layoutContainerRef.current) {
          if (sourcePage === sourcePages.TagRangePage) {
            dispatch(
              setTagRangePageScrollY(layoutContainerRef.current.scrollTop)
            )
          }
        }
      }
    }
    // Listen for changes to the current location.
    const unlisten = history.listen(handleNavigation)
    // Cleanup the listener on component unmount.
    return () => {
      unlisten()
    }
  }, [history, dispatch])

  useEffect(() => {
    if (layoutContainerRef.current) {
      if (sourcePage === sourcePages.TagRangePage && tagRangePageScrollY > 0) {
        layoutContainerRef.current.scrollTo({
          top: tagRangePageScrollY,
          behavior: "smooth"
        })
        if (layoutContainerRef.current.scrollTop === tagRangePageScrollY) {
          dispatch(setTagRangePageScrollY(0))
        }
      }
    }
  }, [layoutContainerRef.current, tagRangePageScrollY, scrollCheckData])

  return (
    <div
      className={`wrapper d-flex flex-column align-items-stretch ${
        !toggleFooter ? "footer-collapse" : ""
      }`}
    >
      {/* <div  className={pathname === "*"? 'wrapper d-flex flex-column align-items-stretch no-footer': ' wrapper d-flex flex-column align-items-stretch'}> */}
      {/* <SideNav /> */}
      <main className={`flex-grow-1 mw-100 ${styles.MainContentContainer}`}>
        <Header />
        <div
          className={`main-wrapper main-container ${
            isDisable ? "is-inventory" : ""
          }`}
          ref={layoutContainerRef}
        >
          {children}
        </div>
        {/* <Button
          variant="contained"
          className="chatbot-btn"
          onClick={triggerChatBot}
        > */}
        {/* {!isWebChatVisible ? (
          <Avatar
            className="chatbot-btn"
            onClick={triggerChatBot}
            alt="User Profile"
            src={chatIcon}
          />
        ) : (
          `X Close`
        )}
        {isWebChatVisible ? (
          <div className="web-chat-box">
            <span className="webchatbannerr" id="webchatbanner">
              <Avatar alt="User Profile" src={chatIconAvatar} />
            </span>
            <div id="webchat" ref={webchatRef} />
          </div>
        ) : null} */}
        <span
          className="footer-btn"
          onClick={footerToggleClick}
          onKeyUp={e => handleKeyUp(e)}
          data-testid="MainKeyBADOIcon"
        >
          <KeyboardArrowDownOutlinedIcon />
        </span>
      </main>

      {/* <Footer /> */}

      {/* </div> */}
    </div>
  )
}

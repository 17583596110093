import React from "react"
import { useSelector } from "react-redux"
import {
  Route,
  RouteProps,
  Redirect,
  RouteComponentProps
} from "react-router-dom"
import { PATH } from "src/constants/paths"
import { RootState } from "src/redux/store"

interface Props extends RouteProps {
  component: React.ComponentType<RouteComponentProps>
  isRedirect?: boolean
  redirectTo?: string
}

function AuthenticatedGuard(props: Props) {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAutheticated
  )
  const { component: Component, ...rest } = props
  let isRedirect = props.isRedirect || false
  let redirectTo = props.redirectTo || "/"
  let PATH_NAME = window.location.pathname
  PATH_NAME = PATH_NAME === PATH.COUNT ? "" : PATH_NAME
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated && !localStorage.getItem("accessToken")) {
          return <Redirect data-testid="redirect-component" to={`/login?redirectURL=${PATH_NAME}`} />
        }
        if (isRedirect) {
          return <Redirect to={`${redirectTo}`} />
        }
        return <Component {...props} />
      }}
    />
  )
}
export default AuthenticatedGuard

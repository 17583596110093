import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Alert, AlertColor, AlertTitle, Box, Grid } from "@mui/material"
import { AiFillWarning } from "react-icons/ai"
import { useTranslation } from "react-i18next"
import ToolBarHeader from "src/components/ToolBarHeader/ToolBarHeader"
import { Translates } from "src/i18n/i18n"
import MainLayout from "src/layouts/MainLayout"
import PreCountDataBanner from "./PreCountDataBanner"
import GlobalTable from "src/components/GlobalTable/GlobalTable"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import { alertPropsC, getBreadcrumbConfig, prepareColumns } from "./Helper"
import { PreCountFilesService } from "src/service/preCountFiles.service"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { IsFileAvailableToProcess } from "src/redux/slices/countSlice"
import { PATH } from "src/constants/paths"
import Loader from "src/components/Loader"
import AlertTop from "src/components/Alert/AlertTop"
import { PAGE_REFRESH_TIME_1MINS } from "src/utils/constants"
import { footerPoll } from "src/service/poll.service"
let tempActionRow: any = {}
let pollId
const PreCountFiles = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useAppThunkDispatch()
  const precountFileStatus: any = useSelector(
    (state: RootState) => state.count?.precountFileStatus
  )

  const isPrecountAvailable: any = useSelector(
    (state: RootState) => state.count?.precountFile?.isPrecountAvailable
  )
  const isPrecountBanner: any = useSelector(
    (state: RootState) => state.count?.precountFile?.isPrecountBanner
  )

  const preCountFilesService = new PreCountFilesService()

  const [pFiles, setPFiles] = useState<any[]>([])

  const [isProcessModalOpen, setIsProcessModalOpen] = useState(false)
  const [isActionIconClicked, setIsActionIconClicked] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [alertProps, setAlertProps] = useState(alertPropsC)
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [loadings, setLoadings] = useState({
    isGridAPIDone: false,
    precountFileStatusSuccess: false
  })

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (["failed"].includes(precountFileStatus)) {
        history.push(PATH.COUNT)
      } else if (["success"].includes(precountFileStatus)) {
        if (!isPrecountAvailable) {
          history.push(PATH.COUNT)
        } else {
          setLoadings(oldV => ({
            ...oldV,
            precountFileStatusSuccess: true
          }))
          pollApi()
        }
      } else if (!["success"].includes(precountFileStatus)) {
        dispatch(IsFileAvailableToProcess())
      }
    }
    return () => {
      isMounted = false
      if (pollId) {
        clearInterval(pollId)
      }
    }
  }, [isPrecountAvailable])

  const pollApi = async () => {
    fetchGridData()
    footerPoll(dispatch)
    pollId = setTimeout(async () => {
      pollApi()
    }, PAGE_REFRESH_TIME_1MINS)
  }

  const fetchGridData = async () => {
    let isAPICancelError = false
    try {
      const temp = await preCountFilesService.GetPrecountFileData()
      setPFiles(temp.data)
      isAPICancelError = false
    } catch (_error: any) {
      isAPICancelError = false
      if (_error?.includes("duplicate request")) {
        isAPICancelError = true
      }
    } finally {
      if (!isAPICancelError) {
        setLoadings(oldV => ({ ...oldV, isGridAPIDone: true }))
      }
    }
  }

  const loadSuccessAction = () => {
    fetchGridData()
    toastMsgShow({
      ...alertPropsC,
      ...{
        title: t(Translates.Success),
        severity: "success" as AlertColor,
        content: t(Translates.PrecountActionLoadSuccessText)
      }
    })
  }

  const deleteSuccessAction = () => {
    fetchGridData()
    dispatch(IsFileAvailableToProcess())
    toastMsgShow({
      ...alertPropsC,
      ...{
        title: t(Translates.Success),
        severity: "success" as AlertColor,
        content: t(Translates.PrecountActionDeleteSuccessText)
      }
    })
  }

  const processIconClick = async row => {
    tempActionRow = row
    let trySucceeded = false
    let isActionCompleted = true
    setIsActionIconClicked(true)
    try {
      const payload = {
        precountHeaderId: tempActionRow.precountHeaderId,
        fileName: tempActionRow.name,
        isConfirmed: false
      }
      const res: any = await preCountFilesService.LoadPrecountFileData(payload)
      if (res.showPrompt) {
        setIsProcessModalOpen(true)
        isActionCompleted = false
      } else {
        trySucceeded = true
      }
    } catch (_error) {
      console.error(_error)
    } finally {
      if (trySucceeded) {
        loadSuccessAction()
      }
      setIsActionIconClicked(!isActionCompleted)
    }
  }

  const deleteIconClick = row => {
    setIsActionIconClicked(true)
    tempActionRow = row
    setIsDeleteModalOpen(true)
  }

  const columnsData = React.useMemo(
    () =>
      prepareColumns({
        Translates,
        t,
        processIconClick,
        deleteIconClick,
        isActionIconClicked
      }),
    [t, pFiles, isActionIconClicked]
  )

  const toastMsgShow = (alertMsg = alertPropsC) => {
    setAlertProps({
      ...alertMsg
    })
    setIsShowAlert(true)
    setTimeout(() => {
      setIsShowAlert(false)
      setAlertProps({
        ...alertPropsC
      })
    }, 3000)
  }

  const setContentForProcessConfirmation = () => {
    return (
      <Alert severity="error" icon={<AiFillWarning />} className="custom-alert">
        <AlertTitle className="custom-alert__title">
          {t(Translates.Warning)}
        </AlertTitle>
        <p>{t(Translates.Precount_load_confirmation_text)}?</p>
      </Alert>
    )
  }
  const callbackProcessConfirmation = async (confirm: boolean) => {
    let trySucceeded = false
    try {
      if (confirm) {
        const payload = {
          precountHeaderId: tempActionRow.precountHeaderId,
          fileName: tempActionRow.name,
          isConfirmed: true
        }
        await preCountFilesService.LoadPrecountFileData(payload)
        trySucceeded = true
      }
    } catch (_error) {
      console.error(_error)
    } finally {
      if (trySucceeded) {
        loadSuccessAction()
      }
      setIsProcessModalOpen(false)
      setIsActionIconClicked(false)
    }
  }

  const setContentForDeleteConfirmation = () => {
    return (
      <Alert severity="error" icon={<AiFillWarning />} className="custom-alert">
        <AlertTitle className="custom-alert__title">
          {t(Translates.Warning)}
        </AlertTitle>
        <p>{t(Translates.Are_you_sure_you_want_to_delete_this_process)}?</p>
      </Alert>
    )
  }
  const callbackDeleteConfirmation = async (confirm: boolean) => {
    let trySucceeded = false
    try {
      if (confirm) {
        await preCountFilesService.DeletePrecountFileData({
          precountHeaderId: tempActionRow.precountHeaderId,
          fileName: tempActionRow.name,
          isConfirmed: true
        })
        trySucceeded = true
      }
    } catch (_error) {
      console.error(_error)
    } finally {
      if (trySucceeded) {
        deleteSuccessAction()
      }
      setIsDeleteModalOpen(false)
      setIsActionIconClicked(false)
    }
  }

  const loading = Object.values(loadings).some(i => !i)
  return (
    <MainLayout>
      <ToolBarHeader
        data-testid="ToolBarHeader"
        breadCrumbInfo={getBreadcrumbConfig({ Translates, t })}
        customPath={true}
        renderedPage={"PRECOUNT"}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <Box mb={2}>
            {isPrecountBanner ? (
              <PreCountDataBanner isButtonShow={false} />
            ) : null}
          </Box>
          <Grid container className="table-responsive">
            <Grid xs={12} data-testid="APVGlobTable">
              <GlobalTable
                columns={columnsData}
                data={pFiles}
                noDataText={`${t(Translates.No_Data_Found)}`}
              />
            </Grid>
          </Grid>
          {isProcessModalOpen && (
            <ConfirmDialog
              open={isProcessModalOpen}
              callBack={callbackProcessConfirmation}
              title={`${t(Translates.Process)}`}
              content={setContentForProcessConfirmation}
              custref={{}}
              confirmButtonText={t(Translates.Load)}
            />
          )}
          {isDeleteModalOpen && (
            <ConfirmDialog
              open={isDeleteModalOpen}
              callBack={callbackDeleteConfirmation}
              title={`${t(Translates.Delete)}`}
              content={setContentForDeleteConfirmation}
              custref={{}}
              confirmButtonText={t(Translates.CONFIRM)}
            />
          )}
          <Grid container>
            {isShowAlert ? <AlertTop {...alertProps} /> : null}
          </Grid>
        </>
      )}
    </MainLayout>
  )
}

export default PreCountFiles

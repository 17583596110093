import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Grid from "@mui/material/Grid"
import Progressbar from "../Progressbar/Progressbar"
import { RootState, useAppThunkDispatch } from "../../redux/store"
import {
  getAuditProgress,
  getCountList,
  getTeamEfficiency
} from "../../redux/slices/countSlice"
import moment from "moment"
import useLocationLable from "../../hooks/useLocationLable"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import {
  getFullVariance,
  getVarianceResearchPercentage
} from "src/redux/slices/varianceSlice"
import {
  calculateProgressValue,
  formatNumbers,
  formatWithCurrencyLang,
  showValuesWithCurrency,
  showVarianceTab
} from "src/utils/helper"
import {
  clearPoll,
  // footerPoll,
  intervalVarNames
} from "src/service/poll.service"
import useDollar from "src/hooks/useDollar"
import i18next from "i18next"
import { languageCodeMap, languageLocaleMap } from "src/utils/constants"

const Footer = (_props: any) => {
  const { t } = useTranslation()
  const { qtyCurrencyLable } = useDollar()
  const [countPercentage, setCountPercentage] = useState(0)
  const [remainingTags, setRemainingTags] = useState(0)
  const [goal, setGoal] = useState(null as any)
  const [secondaryGoal, setSecondaryGoal] = useState(null as any)
  const [aph, setAph] = useState(null as any)
  const [loggedInUsers, setLoggedInUsers] = useState(null as any)
  const [variancePercentage, setVariancePercentage] = useState(0)
  const [auditProgressPercentage, setAuditProgressPercentage] = useState(0)
  const [auditsRemaining, setAuditsRemaining] = useState(null as any)
  const { locationLable } = useLocationLable()
  const rangeDetails: any = useSelector(
    (state: RootState) => state.count?.countData
  )
  const countListStatus: any = useSelector(
    (state: RootState) => state.count?.countListStatus
  )
  const efficiencyTeam: any = useSelector(
    (state: RootState) => state.count?.teamEfficiency
  )
  const teamEfficiencyStatus: any = useSelector(
    (state: RootState) => state.count?.teamEfficiencyStatus
  )
  const progressAuditPercent: any = useSelector(
    (state: RootState) => state.count?.auditProgress
  )
  const auditProgressStatus: any = useSelector(
    (state: RootState) => state.count?.auditProgressStatus
  )

  const configData: any = useSelector(
    (state: RootState) => state.config?.configData
  )
  const varianceData: any = useSelector(
    (state: RootState) => state.variance?.summaryData
  )
  const varianceResearchPercentageStatus: any = useSelector(
    (state: RootState) => state.variance?.varianceResearchPercentageStatus
  )
  const fullVarianceAPIStatus: any = useSelector(
    (state: RootState) => state.variance?.fullVarianceAPIStatus
  )
  let _fullVariance = useSelector(
    (state: RootState) => state.variance?.fullVariance
  )
  const { isBothVarianceEnabled, isFullVariance, isGroupVariance } = useDollar()

  let TagsCounted = rangeDetails?.totalTagsCounted
  let TagRangeLength = rangeDetails?.rangeDetails || []
  let roundedPercentage =
    countPercentage < 99
      ? Math.round(countPercentage)
      : Math.floor(countPercentage)
  let roundedAuditPercentage =
    auditProgressPercentage < 99
      ? Math.round(auditProgressPercentage)
      : Math.floor(auditProgressPercentage)
  const roundedAph = Math.round(aph)

  const progressData = calculateProgressValue(
    aph <= 0 ? 0 : aph,
    goal,
    secondaryGoal
  )
  let roundedVariancePercentage =
    variancePercentage < 99
      ? Math.round(variancePercentage)
      : Math.floor(variancePercentage)
  const dispatch = useAppThunkDispatch()

  const fetchTeamEfficiency = async () => {
    try {
      let efficiency = efficiencyTeam
      if (!["success", "cancelled", "loading"].includes(teamEfficiencyStatus)) {
        const res = await dispatch(getTeamEfficiency())?.unwrap()
        efficiency = res?.data
      }

      const goalValue = efficiency?.goal || 0
      const aphValue = efficiency?.aph || 0
      const loggedInUsersValue = efficiency?.loggedInUsers || 0
      setGoal(goalValue)
      setAph(aphValue)
      setSecondaryGoal(efficiency?.secondaryGoal || 0)
      setLoggedInUsers(loggedInUsersValue)
      // footerPoll(dispatch)
    } catch (_error) {
      console.error(_error)
    }
  }

  const fetchAuditProgress = async () => {
    try {
      let progressAudit = progressAuditPercent
      if (!["success", "cancelled", "loading"].includes(auditProgressStatus)) {
        const res = await dispatch(getAuditProgress())?.unwrap()
        progressAudit = res?.data
      }
      const auditPercentageValue = progressAudit?.auditProgressPercentage || 0
      const auditRemainingValue = progressAudit?.auditsRemaining || 0
      setAuditProgressPercentage(auditPercentageValue)
      setAuditsRemaining(auditRemainingValue)
    } catch (_error) {
      console.error(_error)
    }
  }

  useEffect(() => {
    fetchCountData()
    fetchTeamEfficiency()
    fetchAuditProgress()
    fetchVarianceData()
    return () => {
      clearPoll([intervalVarNames.FOOTER], "footer")
    }
  }, [
    rangeDetails?.countPercent,
    efficiencyTeam?.efficiencyPercent,
    progressAuditPercent?.auditProgressPercentage,
    varianceData &&
      Object.keys(varianceData)?.length &&
      varianceData[0]?.varianceResearchPercentage?.percentageResearched,
    configData,
    isFullVariance,
    isGroupVariance,
    isBothVarianceEnabled,
    varianceResearchPercentageStatus,
    _fullVariance
  ])
  const fetchCountData = async () => {
    try {
      let response = rangeDetails
      if (!["success", "cancelled", "loading"].includes(countListStatus)) {
        const res = await dispatch(getCountList())?.unwrap()
        response = res?.data
      }
      const percentage = response?.countPercent
      setCountPercentage(!isNaN(percentage) ? percentage : 0)
      setRemainingTags(
        response && response?.totalTags - response?.totalTagsCounted
      )
    } catch (error) {
      console.error(error)
    }
  }
  const getProgressFromGroup = async () => {
    let vRes
    let vPercentage
    let vBatchPercentage
    let remDepts

    if (
      !["success", "cancelled", "loading", "failed"].includes(
        varianceResearchPercentageStatus
      )
    ) {
      const res = await dispatch(getVarianceResearchPercentage(0))?.unwrap()
      vRes = res && res?.data
      vPercentage = (vRes && vRes?.percentageResearched) || 0
      remDepts = vRes && vRes?.remainingDepartmentsResearch
      vBatchPercentage = (vRes && vRes?.batchPercentageResearched) || 0
    } else {
      vPercentage =
        (varianceData &&
          Object.keys(varianceData)?.length &&
          varianceData[0] &&
          varianceData[0].varianceResearchPercentage &&
          varianceData[0].varianceResearchPercentage?.percentageResearched) ||
        0

      vBatchPercentage =
        (varianceData &&
          Object.keys(varianceData)?.length &&
          varianceData[0] &&
          varianceData[0].varianceResearchPercentage &&
          varianceData[0].varianceResearchPercentage
            ?.batchPercentageResearched) ||
        0
      remDepts =
        varianceData &&
        Object.keys(varianceData)?.length &&
        varianceData[0] &&
        varianceData[0].varianceResearchPercentage &&
        varianceData[0].varianceResearchPercentage?.remainingDepartmentsResearch
    }
    return { vPercentage, remDepts, vBatchPercentage }
  }
  const getProgressFromFull = async () => {
    let vRes
    let vPercentage
    let remDepts
    if (
      _fullVariance &&
      !Object.keys(_fullVariance)?.length &&
      !["success", "cancelled", "loading"]?.includes(fullVarianceAPIStatus)
    ) {
      const res = await dispatch(getFullVariance())?.unwrap()
      vRes = res && res?.data
      vPercentage = (vRes && vRes?.varianceGroupProgress) || 0
      remDepts = vRes && vRes?.items - vRes?.itemsResearched
    } else {
      vPercentage = (_fullVariance && _fullVariance?.varianceGroupProgress) || 0
      remDepts =
        _fullVariance && _fullVariance?.items - _fullVariance?.itemsResearched
    }
    return { vPercentage, remDepts }
  }

  const getVarianceConfiguredAndEnabledDetails = () => {
    const _groupVariance = varianceData?.[0].varianceResearchPercentage
    return {
      isBothVarianceEnabled: isFullVariance && isGroupVariance,
      isBatchVarianceCreated:
        isFullVariance &&
        isGroupVariance &&
        (_fullVariance?.isVarianceCreated || _groupVariance?.isVarianceCreated),
      isFullVarianceAvailable:
        isFullVariance && _fullVariance?.isVarianceCreated,
      isGroupVarianceAvailable:
        isGroupVariance && _groupVariance?.isVarianceCreated
    }
  }

  const fetchVarianceData = async () => {
    try {
      let vPercentage
      const _progressFull = await getProgressFromFull()
      const _progressGroup = await getProgressFromGroup()

      const {
        isBothVarianceEnabled,
        isBatchVarianceCreated,
        isFullVarianceAvailable,
        isGroupVarianceAvailable
      } = getVarianceConfiguredAndEnabledDetails()
      // If Both variances are enabled and variance is created for full variance then variance progress is full variance progress
      // otherwise variance progress is group variance progress
      if (isBothVarianceEnabled) {
        if (isBatchVarianceCreated) {
          vPercentage = _progressGroup.vBatchPercentage
        }
      }
      // If only full variance is enabled and variance is not created that time variance progress will be 0% Not started
      //if variance is created then variance progress will be full variance progress
      else if (isFullVarianceAvailable) {
        vPercentage = _progressFull?.vPercentage
      } // If only group variance is enabled then variance progress will be group variance progress
      else if (isGroupVarianceAvailable) {
        vPercentage = _progressGroup?.vPercentage
      }

      setVariancePercentage(vPercentage || 0)
    } catch (error) {
      console.error(error)
    }
  }
  const percentageCount = () => {
    const lang = i18next.language
    moment.locale(languageCodeMap[lang])
    if (TagRangeLength?.length < 1) {
      return `${t(Translates.No)} ${t(locationLable)} ${t(Translates.Ranges)}`
    } else if (TagRangeLength?.length >= 1 && TagsCounted === 0) {
      return t(Translates.Count_Not_Started)
    } else if (
      countPercentage &&
      countPercentage > 0 &&
      countPercentage < 100 &&
      remainingTags === 1
    ) {
      return `1 ${t(locationLable)} ${t(Translates.remaining)}`
    } else if (
      countPercentage &&
      countPercentage > 0 &&
      countPercentage < 100 &&
      remainingTags > 1
    ) {
      return (
        formatNumbers(remainingTags, languageLocaleMap[lang]) +
        ` ${t(locationLable)}s ${t(Translates.remaining)}`
      )
    } else if (countPercentage === 100) {
      return (
        t(Translates.Completed_at) +
        ": " +
        moment
          .utc(rangeDetails?.lastUpdatedDate)
          .local()
          .format("DD-MMM-YYYY  hh:mm:ss A")
          .replace(/([a-z]{3})/gi, match => match.toUpperCase())
        // .replace(
        //   /([a-z]+)/gi,
        //   match => match.charAt(0).toUpperCase() + match.slice(1)
        // )
      )
    } else {
      return " "
    }
  }
  const usersLoggedIn = () => {
    if (loggedInUsers && loggedInUsers > 1) {
      return t(Translates.People)
    } else {
      return t(Translates.Person)
    }
  }
  const remainingAudits = () => {
    if (auditsRemaining === 1) {
      return t(Translates.Audit)
    } else {
      return t(Translates.Audits)
    }
  }
  const condition = showVarianceTab(configData)

  const displayVarianceFooterText = () => {
    const _groupVariance = varianceData?.[0].varianceResearchPercentage

    const {
      isBothVarianceEnabled,
      isBatchVarianceCreated,
      isFullVarianceAvailable,
      isGroupVarianceAvailable
    } = getVarianceConfiguredAndEnabledDetails()
    const getBatchVarianceLabel = () => {
      return isBatchVarianceCreated
        ? `${formatWithCurrencyLang(
            _groupVariance?.remainingBatchesResearch
          )} ${t(Translates.variance_batches_remaining)}`
        : `${t(Translates.No_Variances)}`
    }

    const getFullVarianceLabel = () => {
      return `${formatWithCurrencyLang(_fullVariance?.itemsResearched)} ${t(
        Translates.of
      )} ${formatWithCurrencyLang(_fullVariance?.items)} ${t(
        Translates.Items_Researched
      )}`
    }

    const getGroupVarianceLabel = () => {
      return `${formatWithCurrencyLang(
        _groupVariance?.researchedDepartments
      )} ${t(Translates.of)} ${formatWithCurrencyLang(
        _groupVariance?.departmentCount
      )} ${t(Translates.Departments_Researched)}`
    }

    if (isBothVarianceEnabled) {
      return getBatchVarianceLabel()
    } else if (isFullVarianceAvailable) {
      return getFullVarianceLabel()
    } else if (isGroupVarianceAvailable) {
      return getGroupVarianceLabel()
    } else {
      return `${t(Translates.No_Variances)}`
    }
  }

  return (
    <footer
      className={`d-flex align-items-center FooterContainer`}
      id={`flex-count-footer`}
    >
      <Grid container spacing={2}>
        <Grid item xs={condition ? 3 : 4} className="GridItem">
          <div className={`ProgressSection`}>
            <strong className="footer-label">
              {t(Translates.Count_Progress)}{" "}
            </strong>
            <div className={`mt-1`}>
              <Progressbar value={roundedPercentage} type={"countProgress"} />
            </div>
            <small className={`mt-1 footer-subtext`}>{percentageCount()}</small>
          </div>
        </Grid>
        <Grid item xs={condition ? 3 : 4} className="GridItem">
          <div className={`ProgressSection`}>
            <strong>{t(Translates.Audit_Progress)} </strong>
            <div className={`mt-1`}>
              <Progressbar
                value={roundedAuditPercentage}
                type={"auditProgress"}
              />
            </div>
            <small className={`mt-1`}>
              {auditsRemaining}{" "}
              {`${remainingAudits()} ${t(Translates.remaining)}`}
            </small>
          </div>
        </Grid>
        {condition ? (
          <Grid item xs={3} className="GridItem">
            <div className={`ProgressSection`}>
              <strong>{t(Translates.Variance_Progress)}</strong>
              <div className={`mt-1`}>
                <Progressbar
                  value={roundedVariancePercentage}
                  type={"varianceProgress"}
                />
              </div>
              <small className={`mt-1`}>{displayVarianceFooterText()}</small>
            </div>
          </Grid>
        ) : null}
        <Grid item xs={condition ? 3 : 4} className="GridItem">
          <div className={`ProgressSection`}>
            <strong>{t(Translates.Team_efficiency)}</strong>
            <div className="team-efficiency-bar mt-1">
              <span className="team-efficiency-bar__red"></span>
              <span className="team-efficiency-bar__orange"></span>
              <span className="team-efficiency-bar__green"></span>
              <span
                className="team-efficiency-bar__line"
                data-testid="team-efficiency-bar-line"
                style={{
                  left: progressData?.positionPercent
                }}
              >
                <label
                  className={
                    progressData?.progressValue <= 95
                      ? "text-right"
                      : "text-left"
                  }
                >
                  {showValuesWithCurrency(roundedAph, 0, qtyCurrencyLable)}
                </label>
              </span>
            </div>
            <div className="team-efficiency-count mt-1">
              <span>
                {loggedInUsers} {`${usersLoggedIn()} ${t(Translates.counting)}`}
              </span>
              <span>
                {t(Translates.APH_Goal)}{" "}
                {showValuesWithCurrency(
                  Math.round(Number(goal)),
                  0,
                  qtyCurrencyLable
                )}
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
    </footer>
  )
}

export default Footer

import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { getConfig } from "../redux/slices/configurationSlice"
import { RootState, useAppThunkDispatch } from "../redux/store"

function useLocationLable() {
  const config: any = useSelector((state: RootState) => state.config)
  const [locationLable, setLocationLable] = useState("Tag")
  const dispatch = useAppThunkDispatch()

  useEffect(() => {
    checkLocationLable()
  }, [config])

  const checkLocationLable = () => {
    if (!config?.configData) {
      return
    }

    if (
      !Object.keys(config.configData).length &&
      !["cancelled", "loading"].includes(config.status)
    ) {
      dispatch(getConfig())
    }

    const fetchedLabel = getState()
    if (fetchedLabel) {
      setLocationLable(fetchedLabel)
    }
  }

  const getState = () => {
    return config.configData.Fields?.length
      ? config.configData.Fields[0]?.Location?.Labels[0]["en-us"]
      : ""
  }

  const checkPlural = (input: number) => {
    if (input === 1) {
      return locationLable
    } else {
      return `${locationLable}s`
    }
  }

  return { locationLable, checkPlural }
}

export default useLocationLable

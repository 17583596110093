import React, { useState } from "react"
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs"
import { IconButton, InputAdornment } from "@mui/material"
import { useTranslation } from "react-i18next"
import MUIText from "src/components/MUIFormFields/MUIText"
import styles from "./../../Login/Login.module.scss"
import { fieldNames } from "../Constant"
const PasswordFields = ({
  passwordError,
  onChangePassword
}: {
  passwordError: string
  onChangePassword: Function
}) => {
  //Language translation hook
  const { t } = useTranslation()

  //Use state for show and hide password
  const [showPassword, setShowPassword] = useState<boolean>(false)

  //Toggle password text field type
  const updateShowPassword = () => {
    setShowPassword(oldVal => !oldVal)
  }

  return (
    <MUIText
      inputAttributes={{
        name: fieldNames.eventPassword.name,
        label: `${t(fieldNames.eventPassword.label)} *`,
        onChange: onChangePassword,
        secondaryValidationError: `${t(passwordError)}`,
        disabled:
          Number(localStorage.getItem("passwordWrongEnterCount") || 0) >= 3,
        className: showPassword ? "" : "password-input",
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                className={`${styles.PassIcon}`}
                onClick={updateShowPassword}
              >
                {showPassword ? (
                  <BsEyeFill size={22} className={`${styles.PassIcon}`} />
                ) : (
                  <BsEyeSlashFill
                    data-testid="eyeIconSlash"
                    className={`${styles.PassIcon}`}
                    size={22}
                  />
                )}
              </IconButton>
            </InputAdornment>
          )
        }
      }}
    />
  )
}

export default PasswordFields

import React from "react"
import { Grid } from "@mui/material"
import teamNoDataBg from "../../../src/assets/images/TeamNoData.svg"
import styles from "./TeamNoDataBanner.module.scss"
import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"

const TeamNoDataBanner = () => {
  const {t} = useTranslation()
  const inventoryData = useSelector(
    (state: RootState) => state.inventory.inventoryData
  )
  return (
    <>
      <Grid container className={`blue-bg ${styles.teamModeBanner}`}>
        <Grid item md={7} lg={7}>
          <h1 className="font-24 text-uppercase f-700 mb-0">{t(Translates.LOG_IN_TO_COUNT)}</h1>
          <p>
            {t(Translates.Turn_on_the_handheld_devices_and_have_each_person_log_in_to_begin_counting)}
            <span className="d-block">
              {t(Translates.Provide_each_counter_with_this_Event_PIN_to_login)}:{" "}
              {`${inventoryData.counterPassword || "-"}`}
            </span>
          </p>
        </Grid>
        <Grid item md={5} lg={4} className="text-right">
          <img width={"312px"} height={"232px"} src={teamNoDataBg} alt="team No Data Bg" />
        </Grid>
      </Grid>
    </>
  )
}
export default TeamNoDataBanner

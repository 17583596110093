import React, { useEffect, useState } from "react"
import AuditToolBarHeader from "../../../src/components/AuditToolbar/AuditToolBarHeader"
import MainLayout from "../../../src/layouts/MainLayout"
import AuditNoDataBanner from "./AuditNoDataBanner"
import AuditPersonView from "./AuditPersonView"
import AuditSummaryBanner from "./AuditSummaryBanner"
import AuditTagView from "./AuditTagView"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "../../redux/store"
import {
  AuditPagePoll,
  clearPoll,
  intervalVarNames
} from "../../../src/service/poll.service"
import {
  getAuditSummary,
  getAuditTags,
  updateAuditPageFilter
} from "../../redux/slices/auditSlice"
import InventoryStatusBanner from "../../../src/pages/CloseInventory/InventoryStatusBanner"
import useInventoryStatus from "../../../src/hooks/useInventoryStatus"
import styles from "../../../src/components/AuditToolbar/AuditToolBarHeader.module.scss"
import Loader from "src/components/Loader"
import { setLoadingValue } from "src/utils/helper"

const Audit: React.FC<any> = () => {
  const { isDisable } = useInventoryStatus()
  const [isPersonView, setIsPersonView] = useState(false)
  const dispatch = useAppThunkDispatch()

  const inventory: any = useSelector((state: RootState) => state.inventory)
  const stdTimeZone: any = inventory?.inventoryData?.stdTimeZone
  const auditSummaryFromState = useSelector(
    (state: RootState) => state.audit.auditSummary
  )
  const auditTagsFromStore = useSelector(
    (state: RootState) => state.audit.auditTags
  )
  const auditSummaryStatus = useSelector(
    (state: RootState) => state.audit.auditSummaryStatus
  )
  const auditTagsStatus = useSelector(
    (state: RootState) => state.audit.auditTagsStatus
  )
  const pageViewFromStore = useSelector(
    (state: RootState) => state.audit.auditPageFilter.view
  )
  useEffect(() => {
    setIsPersonView(pageViewFromStore === "personView")
  }, [pageViewFromStore])
  const handleToggle = val => {
    dispatch(updateAuditPageFilter({ view: val ? "personView" : "tagView" }))
    setIsPersonView(val)
  }
  const isNoData = () => {
    return !auditTagsFromStore.length ? true : false
  }
  const [loadings, setLoadings] = useState({
    isAuditSummaryAPIDone: false,
    isAuditTagsAPIDone: false
  })

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (!["cancelled", "loading"].includes(auditTagsStatus)) {
        dispatch(getAuditTags())
      }
      if (!["cancelled", "loading"].includes(auditSummaryStatus)) {
        dispatch(getAuditSummary())
      }

      AuditPagePoll(dispatch)
    }
    return () => {
      isMounted = false
      clearPoll([intervalVarNames.AUDITPAGE], "auditPage")
    }
  }, [])

  useEffect(() => {
    setLoadingValue({
      setLoadings,
      status: auditSummaryStatus,
      variableName: "isAuditSummaryAPIDone"
    })
  }, [auditSummaryStatus])

  useEffect(() => {
    setLoadingValue({
      setLoadings,
      status: auditTagsStatus,
      variableName: "isAuditTagsAPIDone"
    })
  }, [auditTagsStatus])

  const loading = Object.values(loadings).some(i => !i)

  return (
    <MainLayout>
      {!loading && <AuditToolBarHeader
        handleToggle={val => handleToggle(val)}
        showToggle={!isNoData()}
      />}
      {isDisable ? (
        <div className={`${styles.inventoryBanner}`}>
          <div className={`main-container inventory-banner`}>
            <InventoryStatusBanner />
          </div>
        </div>
      ) : null}
      {loading ? (
        <Loader marginTopPx="32px" />
      ) : (
        <>
          {isNoData() ? (
            <AuditNoDataBanner />
          ) : (
            <div className="fit-content">
              {Object.keys(auditSummaryFromState).length > 0 && (
                <AuditSummaryBanner />
              )}
              {!isPersonView ? <AuditTagView /> : <AuditPersonView />}
            </div>
          )}
        </>
      )}
    </MainLayout>
  )
}

export default Audit

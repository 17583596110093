import React, { useEffect, useState } from "react"
import { Box, Grid, Tooltip } from "@mui/material"
import { useHistory } from "react-router-dom"
import GlobalTable from "../../../src/components/GlobalTable/GlobalTable"
import MainLayout from "../../../src/layouts/MainLayout"
import TeamNoDataBanner from "./TeamNoDataBanner"
import TeamLoggedOffBanner from "./TeamLoggedOffBanner"
import TeamSummaryBanner from "./TeamSummaryBanner"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import TeamToolBarHeader from "../../../src/components/TeamToolBarHeader/TeamToolBarHeader"
import {
  getTeamDetails,
  getTeamSummary
} from "../../../src/redux/slices/teamSlice"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "../../../src/redux/store"
import {
  clearPoll,
  intervalVarNames,
  TeamPagePoll
} from "../../../src/service/poll.service"
import { renderDownTimeSmiley, renderAccuracySmiley } from "./teamHelper"
import InventoryStatusBanner from "../CloseInventory/InventoryStatusBanner"
import useInventoryStatus from "../../../src/hooks/useInventoryStatus"
import styles from "../../../src/components/TeamToolBarHeader/TeamToolBarHeader.module.scss"
import useLocationLable from "../../../src/hooks/useLocationLable"
import { getCountSuccess } from "../../../src/redux/slices/countSlice"
import {
  encryptUrlParams,
  setLoadingValue,
  showValuesWithCurrency
} from "../../../src/utils/helper"
import { TEAM_PAGE_CONSTANTS } from "src/utils/team"
import { useTranslation } from "react-i18next"
import { ROWCLICK_COLUMNSTOSUPRESS } from "src/utils/constants"
import { Translates } from "src/i18n/i18n"
import Loader from "src/components/Loader"
import useDollar from "src/hooks/useDollar"

const Team: React.FC<any> = () => {
  const { t, i18n } = useTranslation()
  const { qtyCurrencyLable } = useDollar()
  const { isDisable } = useInventoryStatus()
  const dispatch = useAppThunkDispatch()
  const inventory: any = useSelector((state: RootState) => state.inventory)
  const stdTimeZone: any = inventory?.inventoryData?.stdTimeZone
  const CountSuccess = useSelector(
    (state: RootState) => state.count.CountSuccess
  )
  const countSuccessStatus = useSelector(
    (state: RootState) => state.count.countSuccessStatus
  )
  const teamSummaryFromState = useSelector(
    (state: RootState) => state.team.teamSummary
  )
  const teamSummaryStatus = useSelector(
    (state: RootState) => state.team.teamSummaryStatus
  )
  const { locationLable } = useLocationLable()
  const [teamDetailsList, setTeamDetailsList] = useState<any>([])
  const history = useHistory()
  const [teamSummary, setTeamSummary] = useState<any>({})
  const [suggestions, setSuggestions] = useState([])
  const [currentFilter, setCurrentFilter] = useState<any>()
  const [filteredTeamDetailsList, setFilteredTeamDetailsList] = useState(
    [] as any
  )
  const teamDetailsFromStore = useSelector(
    (state: RootState) => state.team.teamDetails
  )
  const teamDetailsStatus = useSelector(
    (state: RootState) => state.team.teamDetailsStatus
  )
  const filterFromStore = useSelector(
    (state: RootState) => state.team.teamPageFilter.status
  )

  const [loadings, setLoadings] = useState({
    isGetTeamGridDataAPIDone: false,
    isGetCountCompleteStatusAPIDone: false,
    isGetTeamSummaryDetailsAPIDone: false
  })

  useEffect(() => {
    dispatch(getTeamSummary())
    getAllTeamDetails()
    dispatch(getCountSuccess())
    return () => {
      clearPoll([intervalVarNames.TEAMPAGE], "teamPage")
    }
  }, [dispatch])

  useEffect(() => {
    setTeamSummary(teamSummaryFromState)
  }, [currentFilter, teamSummaryFromState])
  useEffect(() => {
    if (teamDetailsFromStore?.length) {
      setTeamDetailsList(teamDetailsFromStore)
      handleFilter(filterFromStore, teamDetailsFromStore)
    }
  }, [teamDetailsFromStore])

  useEffect(() => {
    setLoadingValue({
      setLoadings,
      status: teamDetailsStatus,
      variableName: "isGetTeamGridDataAPIDone"
    })
  }, [teamDetailsStatus])

  useEffect(() => {
    setLoadingValue({
      setLoadings,
      status: countSuccessStatus,
      variableName: "isGetCountCompleteStatusAPIDone"
    })
  }, [countSuccessStatus])

  useEffect(() => {
    setLoadingValue({
      setLoadings,
      status: teamSummaryStatus,
      variableName: "isGetTeamSummaryDetailsAPIDone"
    })
  }, [teamSummaryStatus])

  const getAllTeamDetails = async () => {
    if (!["cancelled", "loading"].includes(teamDetailsStatus)) {
      dispatch(getTeamDetails())
    }

    TeamPagePoll(dispatch)
  }

  const handleFilter = (labelValue, data) => {
    let teamData = data?.length ? data : teamDetailsList
    if (labelValue == TEAM_PAGE_CONSTANTS.Auditor) {
      const results = teamData?.filter(element => {
        return (
          element?.userRole?.toLowerCase() ==
          TEAM_PAGE_CONSTANTS.Auditor.toLowerCase()
        )
      })
      setCurrentFilter(labelValue)
      setFilteredTeamDetailsList(results)
    } else if (labelValue == TEAM_PAGE_CONSTANTS.Counter) {
      const results = teamData.filter(element => {
        return (
          element?.userRole?.toLowerCase() ==
          TEAM_PAGE_CONSTANTS.Counter.toLowerCase()
        )
      })
      setCurrentFilter(labelValue)
      setFilteredTeamDetailsList(results)
    } else if (labelValue == TEAM_PAGE_CONSTANTS.Active) {
      const results = teamData.filter(element => {
        return element?.status == TEAM_PAGE_CONSTANTS.Active
      })
      setCurrentFilter(labelValue)
      setFilteredTeamDetailsList(results)
    } else if (labelValue == TEAM_PAGE_CONSTANTS.InActive) {
      const results = teamData.filter(element => {
        return element?.status == TEAM_PAGE_CONSTANTS.InActive
      })
      setFilteredTeamDetailsList(results)
    } else if (labelValue == TEAM_PAGE_CONSTANTS.LoggedOff) {
      const results = teamData.filter(element => {
        return element?.status == TEAM_PAGE_CONSTANTS.LoggedOff
      })
      setCurrentFilter(labelValue)
      setFilteredTeamDetailsList(results)
    } else {
      setFilteredTeamDetailsList(teamData)
      setCurrentFilter(TEAM_PAGE_CONSTANTS.All)
    }
  }

  const nameWithStatus = row => {
    if (row.original.status == TEAM_PAGE_CONSTANTS.InActive) {
      return (
        <Tooltip arrow title="Inactive">
          <span className="status-dot inActive"> </span>
        </Tooltip>
      )
    } else if (row.original.status == TEAM_PAGE_CONSTANTS.Active) {
      return (
        <Tooltip arrow title="Active">
          <span className="status-dot active"> </span>
        </Tooltip>
      )
    } else if (row.original.status == TEAM_PAGE_CONSTANTS.LoggedOff) {
      return (
        <Tooltip arrow title="Logged off">
          <span className="status-dot loggedOff"> </span>
        </Tooltip>
      )
    }
  }
  const nameWithRoleIcon = row => {
    if (row.original.userRole == TEAM_PAGE_CONSTANTS.Auditor) {
      return (
        <Tooltip arrow title="Auditor">
          <CheckCircleOutlineIcon />
        </Tooltip>
      )
    } else {
      return ""
    }
  }

  const OnCellClick = (rowObj, cellObj) => {
    const rowValues = rowObj.values
    const pathURL = `${window.location.pathname}/${encryptUrlParams(
      rowObj.original.name
    )}/${encryptUrlParams(parseInt(rowObj.original.userId))}`
    if (
      ROWCLICK_COLUMNSTOSUPRESS.toUpperCase().indexOf(
        cellObj.column.Header.toUpperCase()
      ) <= -1
    ) {
      history.push(pathURL, {
        rowValues,
        stdTimeZone: stdTimeZone
      }
      
      )
    }
  }

  const renderLocation = (value, row) => {
    if (row.original?.status === TEAM_PAGE_CONSTANTS.InActive) {
      return t(Translates.INACTIVE)
    } else if (row.original?.status === TEAM_PAGE_CONSTANTS.LoggedOff) {
      return t(Translates.LOGGED_OFF)
    } else if (
      row.original?.status === TEAM_PAGE_CONSTANTS.Active &&
      value?.includes(TEAM_PAGE_CONSTANTS.Out_of_Range)
    ) {
      return value?.replace(
        TEAM_PAGE_CONSTANTS.Out_of_Range,
        t(Translates.Out_of_Range)
      )
    } else {
      return t(value)
    }
  }

  const columnsData = React.useMemo(
    () => [
      {
        Header: t(Translates.NAME),
        accessor: "name",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 180,
        Cell: ({ row, value }) => {
          return (
            <span className="align-middle">
              <span className="team-name cursor-pointer">
                {nameWithStatus(row)} {value ? value : "-"}{" "}
              </span>
              <span className="team-name cursor-pointer align-right">
                {nameWithRoleIcon(row)}
              </span>
            </span>
          )
        }
      },
      {
        Header: `${t(locationLable)}`,
        accessor: "location",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 180,
        Cell: ({ value, row }) => {
          return renderLocation(value, row)
        }
      },

      {
        Header: `${t(locationLable)}s ${t(Translates.COUNTED)}`,
        accessor: "locationsCounted",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150
      },
      {
        Header: t(Translates.PIECES_COUNTED),
        accessor: "piecesCounted",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150,
        Cell: ({ value }) => {
          return showValuesWithCurrency(
            Math.round(Number(value)),
            0,
            qtyCurrencyLable
          )
        }
      },
      {
        Header: t(Translates.PIECES_PER_HOUR),
        accessor: "piecesPerHour",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150,
        Cell: ({ value }) => {
          return showValuesWithCurrency(
            Math.round(Number(value)),
            0,
            qtyCurrencyLable
          )
        }
      },

      {
        Header: t(Translates.DOWN_TIME),
        accessor: "downTime",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 180,
        Cell: ({ row, value }) => {
          return value != null
            ? renderDownTimeSmiley(
                value,
                row.original.dGoal,
                row.original.dSecondaryGoal
              )
            : ""
        }
      },
      {
        Header: t(Translates.ACCURACY),
        accessor: "accuracy",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 110,
        sortType: (a, b, columnId, desc) => {
          const aValue = a.original[columnId]
          const bValue = b.original[columnId]

          if (aValue === 0 && bValue !== 0) {
            return -1
          }
          if (aValue !== 0 && bValue === 0) {
            return 1
          }

          return aValue - bValue // Sort by numeric value otherwise
        },
        Cell: ({ row, value }) => {
          return renderAccuracySmiley(
            value,
            row.original.aGoal,
            row.original.aSecondaryGoal
          )
        }
      }
    ],
    [locationLable, i18n.language]
  )
  const handleSearchChange = (search: string) => {
    let filteredList: any = []
    if (search.length) {
      filteredList = teamDetailsList.filter(rec => {
        return (
          rec?.name && rec.name.toLowerCase().includes(search.toLowerCase())
        )
      })
    }
    setSuggestions(filteredList)
  }

  const displayGrid = () => {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          className="custom-table-responsive teams-table"
          data-testid="TeamGlobTable"
        >
          <GlobalTable
            columns={columnsData}
            data={filteredTeamDetailsList}
            OnCellClick={OnCellClick}
            noDataText={`${t(Translates.No_Persons_Found)}`}
          ></GlobalTable>
        </Grid>
      </Grid>
    )
  }
  const showTeamNoDataBanner = () => {
    return teamDetailsList.length > 0 ? false : true
  }

  const loading = Object.values(loadings).some(i => !i)

  return (
    <MainLayout>
      <TeamToolBarHeader
        showFilter={showTeamNoDataBanner()}
        handleFilter={handleFilter}
        handleSearchChange={(searchText: string) =>
          handleSearchChange(searchText)
        }
        suggestions={suggestions}
      />
      {isDisable && (
        <div className={`${styles.inventoryBanner}`}>
          <div className={`main-container inventory-banner`}>
            <InventoryStatusBanner />
          </div>
        </div>
      )}
      {loading ? (
        <Loader marginTopPx="32px" />
      ) : (
        <>
          {!showTeamNoDataBanner() && Object.keys(teamSummary).length > 0 && (
            <TeamSummaryBanner teamSummary={teamSummary} />
          )}
          <Box mb={2}>
            {CountSuccess.deviceLoginStatus === true &&
              !showTeamNoDataBanner() &&
              !isDisable && <TeamLoggedOffBanner />}
          </Box>
          {!showTeamNoDataBanner() ? displayGrid() : <TeamNoDataBanner />}
        </>
      )}
    </MainLayout>
  )
}
export default Team

import React, { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import OpenInFullTwoToneIcon from "@mui/icons-material/OpenInFullTwoTone"
import CloseFullscreenTwoToneIcon from "@mui/icons-material/CloseFullscreenTwoTone"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import CloseIcon from "@mui/icons-material/Close"
import FindIcon from "../../assets/images/Binoculars.svg"
import useInventoryStatus from "../../hooks/useInventoryStatus"
import { useDispatch, useSelector } from "react-redux"
import {
  updateShowDeleted,
  updateConsiceView
} from "../../redux/slices/countSlice"
import { RootState } from "../../redux/store"
import { getAllElementsByClassName, getQueryParam } from "../../utils/helper"
import { Tooltip, CircularProgress } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import FileClockIcon from "src/assets/images/FileClockIcon.svg"

const SearchToolBar = ({
  clearInputClick,
  arrowDownClick,
  arrowUpClick,
  onInputChange,
  onDeleteTagClick,
  showDeleteTag,
  isSearchBarDeleteLoading,
  toggleConciseView,
  onTagHistoryClick,
  showHistoryIcon
}) => {
  const { isDisable } = useInventoryStatus()
  const { t } = useTranslation()
  const consiceViewFromStore = useSelector(
    (state: RootState) => state.count.consiceView
  )
  const dispatch = useDispatch()

  const [value, setValue] = useState("")
  const [conciseView, setConciseView] = useState(false)
  const [matchedLength, setMatchedLength] = useState(0)
  const [numOfMatches, setNumOfMatches] = useState(0)
  const showDeletedFromStore = useSelector(
    (state: RootState) => state.count.showDeleted
  )
  const TIMEOUT: number = 100
  const updateMatchedRecData = () => {
    const allWithClass = getAllElementsByClassName("highlighted ")
    setNumOfMatches(allWithClass.length)
    setMatchedLength(allWithClass.length ? 1 : 0)
  }
  useEffect(() => {
    let navParams: string = getQueryParam("navParams", true) || ""
    let searchText = navParams.split("@")[1] || ""
    // istanbul ignore if
    if (searchText.length) {
      setTimeout(() => {
        onInputChange({ target: { value: searchText } })
        setTimeout(() => updateMatchedRecData(), TIMEOUT)
      }, TIMEOUT)

      setValue(searchText)
    }
  }, [])
  useEffect(() => {
    setConciseView(consiceViewFromStore)
  }, [consiceViewFromStore])
  const handleClearInputClick = e => {
    clearInputClick(e)
    setValue("")
    setNumOfMatches(0)
    setMatchedLength(0)
  }
  //istanbul ignore next
  const handleArrowDownClick = e => {
    arrowDownClick(e)
    if (matchedLength > 0 && matchedLength <= numOfMatches - 1) {
      setMatchedLength(matchedLength + 1)
    } else {
      setMatchedLength(1)
    }
  }
  //istanbul ignore next
  const handleArrowUpClick = e => {
    if (matchedLength > 1) setMatchedLength(matchedLength - 1)
    else setMatchedLength(numOfMatches)
    arrowUpClick(e)
  }
  const handleInputChange = e => {
    onInputChange(e)
    setValue(e.target.value)
    setTimeout(() => updateMatchedRecData(), TIMEOUT)
  }

  const handleKeydown = e => {
    if (e && e.keyCode && e.keyCode === 13) {
      handleArrowDownClick(e)
    }
  }
  const handleConciseView = () => {
    let toggledOption = !conciseView
    setConciseView(toggledOption)
    toggleConciseView(toggledOption)
    dispatch(updateConsiceView(toggledOption))
  }

  const handleShowDeleted = () => {
    let toggledOption = !showDeletedFromStore
    dispatch(updateShowDeleted(toggledOption))
  }
  const onDeleteClick = e => {
    onDeleteTagClick(e)
  }
  const handleRedirectHistoryClick = e => {
    onTagHistoryClick(e)
  }

  return (
    <div className="d-inline-flex">
      <div className="custom-search">
        <Tooltip arrow title={`${t(Translates.Find)}`}>
          <img
            src={FindIcon}
            alt="search icon"
            className="custom-search__search"
          />
        </Tooltip>
        <TextField
          data-testid="txtSearch"
          hiddenLabel
          id="filled-hidden-label-normal"
          placeholder={t(Translates.Find)}
          variant="filled"
          value={value}
          autoComplete={"off"}
          autoSave="false"
          InputProps={{ disableUnderline: true }}
          onChange={handleInputChange}
          onKeyDown={handleKeydown}
        />
        {numOfMatches ? (
          <span className="custom-search__count">
            {matchedLength}/{numOfMatches}
          </span>
        ) : null}
        {!numOfMatches && value ? (
          <span className="custom-search__count">
            {0}/{0}
          </span>
        ) : null}
        <span className="custom-search__line"></span>
        <Tooltip arrow title={`${t(Translates.Down_Arrow)}`}>
          <IconButton
            className="custom-search__icons"
            disabled={numOfMatches <= 1}
            onClick={handleArrowDownClick}
          >
            <KeyboardArrowDownIcon data-testid={"downarrow"} />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title={`${t(Translates.Up_Arrow)}`}>
          <IconButton
            className="custom-search__icons"
            disabled={numOfMatches <= 1}
            onClick={handleArrowUpClick}
            data-testid="uparrow-btn"
          >
            <KeyboardArrowUpIcon data-testid={"uparrow"} />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title={`${t(Translates.Clear)}`}>
          <IconButton className="custom-search__icons" disabled={!value}>
            <CloseIcon
              data-testid={"clearinput"}
              onClick={handleClearInputClick}
            />
          </IconButton>
        </Tooltip>
      </div>
      <Tooltip arrow title={`${t(Translates.Delete)}`}>
        {isSearchBarDeleteLoading ? (
          <CircularProgress size={24} className="icon-btn mt-1 mx-1" />
        ) : (
          <IconButton
            disabled={!showDeleteTag || isDisable}
            className="disabled"
            onClick={onDeleteClick}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Tooltip>
      <Tooltip arrow title={`${t(Translates.Concise_View)}`}>
        <IconButton
          className="icon-btn mx-2"
          data-testid={"conciseViewBtn"}
          onClick={handleConciseView}
        >
          {conciseView ? (
            <OpenInFullTwoToneIcon />
          ) : (
            <CloseFullscreenTwoToneIcon />
          )}
        </IconButton>
      </Tooltip>

      <IconButton
        data-testid={"showDeleteBtn"}
        className="icon-btn"
        onClick={handleShowDeleted}
      >
        {showDeletedFromStore ? (
          <Tooltip arrow title={`${t(Translates.Hide_Deleted_Items)}`}>
            <VisibilityOffIcon />
          </Tooltip>
        ) : (
          <Tooltip arrow title={`${t(Translates.Show_Deleted_Items)}`}>
            <VisibilityIcon />
          </Tooltip>
        )}
      </IconButton>
      <IconButton
        disabled={!showHistoryIcon}
        data-testid={"showHistoryBtn"}
        className="icon-btn ml-2"
        onClick={handleRedirectHistoryClick}
      >
        <Tooltip arrow title={`${t(Translates.View_Location_Edit_History)}`}>
          <img src={FileClockIcon} alt="history-icon" width="22" />
        </Tooltip>
      </IconButton>
    </div>
  )
}

export default SearchToolBar

import { Controller, useFormContext } from "react-hook-form"
import { Autocomplete, FormControl, TextField } from "@mui/material"
import {
  getDefaultValue,
  getFieldError,
  getOptionLabelForAutoComplete
} from "./Helper"
import { useState } from "react"
import { getTranslations } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
import { useTranslation } from "react-i18next"

type Color = "error" | "primary" | "info" | "secondary" | "success" | "warning"
interface Option {
  label: string
  value: any
  default?: boolean
  translateKey?: string
}

export interface Props {
  inputAttributes: {
    name: string
    type?: "text" | "email" | "tel"
    label: string
    placeholder?: string
    className?: string
    id?: string
    required?: boolean
    size?: "medium" | "small"
    color?: Color
    variant?: "standard" | "filled" | "outlined"
    fullWidth?: boolean
    InputProps?: any
    value?: string | number
    defaultValue?: string | number
    multiline?: boolean
    rows?: number
    maxRows?: number
    onChange?: Function
    disabled?: boolean
    options: Option[]
    freeSolo?: boolean
    isNotSort?: boolean
    isOptionTranslate?: boolean
  }
}

const MUIAutoCompleteText = ({ inputAttributes }: Props) => {
  const [inputValue, setInputValue] = useState<string>("")
  const {
    name,
    type,
    label,
    placeholder,
    className,
    id,
    size,
    color,
    variant,
    fullWidth,
    required,
    value,
    defaultValue,
    onChange,
    options,
    disabled,
    freeSolo,
    isNotSort,
    isOptionTranslate
  } = inputAttributes
  const {
    control,
    formState: { errors }
  } = useFormContext() // retrieve all hook methods
  const { t } = useTranslation<any>()
  const { isError, ErrorText } = getFieldError(name, errors)

  const getInputValue = targetValue => {
    const result = newOptions.find(item => item.value === targetValue)
    const output = result && targetValue ? result.label : targetValue
    return `${output}`
  }

  let newOptions = [...options]
  if (isOptionTranslate) {
    newOptions = newOptions.map(i => ({
      ...i,
      label: getTranslations(t, Translates, i.translateKey ?? i.label)
    }))
  }

  newOptions = !isNotSort
    ? [...newOptions].sort((a, b) => a.label.localeCompare(b.label))
    : newOptions

  const defVal: any = getDefaultValue(value, defaultValue, newOptions)

  return (
    <Controller
      name={`${name}`}
      control={control}
      defaultValue={defVal ?? ""}
      render={({ field: { ref, ...field } }) => (
        <FormControl className="custom-input" variant="filled">
          <Autocomplete
            ref={ref}
            data-testid="Test AutoComplete"
            className={`${className} custom-select`}
            id={id ?? name}
            freeSolo={freeSolo}
            options={newOptions}
            value={field.value}
            {...(freeSolo && {
              inputValue: inputValue || getInputValue(field.value)
            })}
            onChange={(_e, v, _w, _t) => {
              field.onChange(v ? v.value : "")
              if (freeSolo) {
                setInputValue(getInputValue(v ? v.value : ""))
              }
              if (onChange) {
                onChange(_e, v)
              }
            }}
            onInputChange={(_event, newInputValue, i) => {
              if (freeSolo && i === "input") {
                field.onChange(newInputValue)
                setInputValue(newInputValue)
              }
            }}
            disabled={disabled ?? false}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            getOptionLabel={option =>
              getOptionLabelForAutoComplete(option, newOptions)
            }
            renderInput={params => (
              <TextField
                {...params}
                label={label}
                fullWidth={fullWidth ?? true}
                required={required ?? false}
                size={size ?? "small"}
                placeholder={placeholder}
                disabled={disabled ?? false}
                type={type ?? "text"}
                variant={variant ?? "filled"}
                color={color ?? "primary"}
                error={isError}
                helperText={ErrorText}
                inputProps={{
                  ...params.inputProps
                }}
              />
            )}
          />
        </FormControl>
      )}
    />
  )
}

export default MUIAutoCompleteText

import React from "react"
import { Grid } from "@mui/material"
import TagIcon from "../../../src/assets/images/TagIcon.svg"
import ProgressBarStatus from "../Count/ProgressBarStatus"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import useLocationLable from "../../../src/hooks/useLocationLable"
import { useTranslation } from "react-i18next"
import { renderAccuracySmiley } from "../Team/teamHelper"
import { Translates } from "src/i18n/i18n"
import { formatWithCurrencyLang } from "src/utils/helper"
import { RootState } from "src/redux/store"
import { useSelector } from "react-redux"

const AuditSummaryBanner = () => {
  const auditSummaryFromState = useSelector(
    (state: RootState) => state.audit.auditSummary
  )
  const auditsRequiredPerCounter = () => {
    if (auditSummaryFromState.requiredAuditsPerCounter === 1) {
      return t(Translates.audit_per_counter)
    } else {
      return t(Translates.audits_per_counter)
    }
  }
  const { locationLable, checkPlural } = useLocationLable()
  const { t } = useTranslation()
  return (
    <Grid container className="tag-list blue-bg mb-2">
      <Grid item md={9} lg={10} className="tag-list__cont">
        <img src={TagIcon} alt="tag icon" className="tag-list__icon" />
        {auditSummaryFromState?.isAuditTypeRule && (
          <div className="tag-list__col">
            <span className="tag-list__col-title">
              {" "}
              {t(Translates.System_Audits)}:{""}
            </span>
            <strong className="tag-list__col-value">
              {`${formatWithCurrencyLang(
                `${auditSummaryFromState.xofSystemAudits}`
              )} ${t(Translates.OF)} ${formatWithCurrencyLang(
                `${auditSummaryFromState.yofSystemAudits}`
              )} ${t(Translates.COMPLETED)}`}
            </strong>
          </div>
        )}
        {auditSummaryFromState?.requiredAuditsPerCounter ? (
          <div className="tag-list__col">
            <span className="tag-list__col-title">
              {auditSummaryFromState.requiredAuditsPerCounter}{" "}
              {`${auditsRequiredPerCounter()}`}:{""}
            </span>
            <strong className="tag-list__col-value">
              {`${formatWithCurrencyLang(
                `${auditSummaryFromState.xofAuditsPerCounter}`
              )} ${t(Translates.OF)} ${formatWithCurrencyLang(
                `${auditSummaryFromState.yofNumberOfCounters}`
              )} ${t(Translates.COUNTERS)} ${t(Translates.COMPLETED)}`}
            </strong>
          </div>
        ) : null}
        {auditSummaryFromState?.requiredAuditPercentage ? (
          <div className="tag-list__col">
            <span className="tag-list__col-title">
              {t(Translates.Audit)}{" "}
              {auditSummaryFromState.requiredAuditPercentage}%{" "}
              {t(locationLable)}
              {"s"}:
            </span>
            <strong className="tag-list__col-value">
              {`${auditSummaryFromState.xofAuditedTags}% `}
              {auditSummaryFromState.xofAuditedTags >=
                auditSummaryFromState.requiredAuditPercentage && (
                <CheckCircleIcon
                  style={{ display: "inline-flex" }}
                  className="accuracy-status success"
                ></CheckCircleIcon>
              )}
            </strong>
          </div>
        ) : null}
        <div className="tag-list__col">
          <span className="tag-list__col-title">
            {t(Translates.accuracy)}:{""}
          </span>
          <strong className="tag-list__col-value">
            {renderAccuracySmiley(
              auditSummaryFromState?.overallAccuracy,
              auditSummaryFromState?.goal,
              auditSummaryFromState?.secondaryGoal
            )}
          </strong>
        </div>
      </Grid>
      <Grid item md={3} lg={2} className="tag-list__cont text-right">
        <div className="custom-progress ma-0">
          <ProgressBarStatus
            progress={auditSummaryFromState?.auditProgress || "0"}
            showWhiteBackground={true}
            isAudit={true}
            isTeam={false}
            isVariance={false}
            isVarianceSummary={false}
          ></ProgressBarStatus>
        </div>
        <span className="d-block text-uppercase">
          {`${formatWithCurrencyLang(auditSummaryFromState?.auditedTags)} ${t(
            Translates.of
          )} ${formatWithCurrencyLang(
            `${auditSummaryFromState?.yofOverAllAuditProgress}`
          )} ${checkPlural(auditSummaryFromState?.auditedTags)} ${t(
            Translates.Audited
          )}`}
        </span>
      </Grid>
    </Grid>
  )
}
export default AuditSummaryBanner

import { configureStore, ThunkDispatch, Action } from "@reduxjs/toolkit"
import inventoryReducer from "./slices/inventorySlice"
import authSlice from "./slices/authSlice"
import countReducer from "./slices/countSlice"
import auditReducer from "./slices/auditSlice"
import teamReducer from "./slices/teamSlice"
import configReducer from "./slices/configurationSlice"
import varianceReducer from "./slices/varianceSlice"
import { useDispatch } from "react-redux"
import { ThunkAction } from "redux-thunk"
import inputFilesSlice from "./slices/inputFilesSlice"
import apiDuplicateCancel from "./slices/apiDuplicateCancel"
import bulkSlice from "./slices/bulkSlice"

export const store = configureStore({
  reducer: {
    auth: authSlice,
    count: countReducer,
    inventory: inventoryReducer,
    audit: auditReducer,
    team: teamReducer,
    config: configReducer,
    variance: varianceReducer,
    files: inputFilesSlice,
    apiCancel: apiDuplicateCancel,
    bulk: bulkSlice
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch
export type AppDispatch = ThunkDispatch<RootState, void, Action>
export const useAppThunkDispatch = () => useDispatch<AppDispatch>()
export type AppThunk = ThunkAction<void, RootState, unknown, Action>

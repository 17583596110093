import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material"

import React, { useCallback, useEffect, useState } from "react"
import ForwardIcon from "@mui/icons-material/Forward"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { PATH } from "src/constants/paths"
import { useHistory } from "react-router-dom"
import { setTagMappingData, setTagReset } from "src/redux/slices/bulkSlice"
import shortid from "shortid"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import { validationConstants } from "src/utils/constants"
const TagsBulkDataMapping: React.FC<any> = ({ setPage }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useAppThunkDispatch()

  const csvTagColumList = useSelector(
    (state: RootState) => state.bulk.csvColumnList
  )
  const validTagColumnList = useSelector(
    (state: RootState) => state.bulk.validTagColumnList
  )

  const tagMappingData = useSelector(
    (state: RootState) => state.bulk.TagMappingData
  )

  const [mappingForm, setMappingForm] = useState({})
  useEffect(() => {
    if (Object.keys(tagMappingData)?.length) {
      setMappingForm(tagMappingData)
      dispatch(setTagMappingData(tagMappingData))
    } else {
      const _temp = validTagColumnList?.reduce((a, b) => {
        return { ...a, [b]: csvTagColumList?.find(f => f === b) }
      }, {})
      setMappingForm(_temp)
      dispatch(setTagMappingData(_temp))
    }
  }, [])

  const onSelectChange = useCallback(
    (event: any) => {
      if (event?.target?.name) {
        const _temp1 = {
          ...mappingForm,
          [event.target.name]: event.target.value
        }
        setMappingForm(_temp1)
        dispatch(setTagMappingData(_temp1))
      }
    },
    [mappingForm]
  )

  const onValidationClick = useCallback(() => {
    dispatch(setTagMappingData(mappingForm))
    setPage("page2")
  }, [mappingForm])
  const onCancelClick = useCallback(() => {
    dispatch(setTagReset())
    history.push(PATH.COUNT)
  }, [])

  const displayLabel = (m: any) => {
    if (m === validationConstants.LOCATION_END) {
      return m
    } else {
      return m + " *"
    }
  }
  const checkAllValidation = useCallback(() => {
    const { [validationConstants.LOCATION_END]: LocationEnd, ...rest } =
      mappingForm as any

    return Object.values(rest).some(
      s => s === "" || s === null || s === undefined
    )
      ? true
      : false
  }, [mappingForm])
  return (
    <Grid container>
      <MiniHeader headerTitle={t(Translates.UPLOAD_FILE)} />

      <Grid container className="inner-view">
        <Grid item xs={12} className="main-container">
          <Grid container>
            <Grid item xs={12}>
              <h4 className="inner-view__title">
                {t(Translates.Data_Mapping)}
              </h4>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <label className="mb-2 form-control__label ">
                  {t(Translates.Associating_the_attributes_to_the_CSV_Data)}
                </label>
                <p className="mb-3">
                  {t(
                    Translates.Map_the_database_attributes_to_the_CSV_data_object_attributes_in_Corporate_User
                  )}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <p className="font-14 text-uppercase f-700 mb-3">
                {t(Translates.Attributes)}
              </p>
            </Grid>
          </Grid>
          <Grid container className="mb-3 gray-bg pa-2">
            <Grid item sm={4}>
              <p className="text-uppercase font-14 f-700">
                {t(Translates.Attributes)}
              </p>
            </Grid>
            <Grid item sm={4}>
              <p className="text-uppercase font-14 f-700 ml-1">
                {t(Translates.CSV_Data)}
              </p>
            </Grid>
          </Grid>
          {validTagColumnList?.map(m => (
            <Grid key={shortid.generate()} container className="mb-3">
              <Grid item sm={3} className="d-flex align-items-center">
                <label>{displayLabel(m)}</label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>

              <Grid item sm={4}>
                <FormControl
                  variant="filled"
                  className="custom-input custom-select mb-0"
                >
                  <InputLabel id="groupSelect" data-testid="groupSelect">
                    {t(Translates.CSV_Column_Header)}
                  </InputLabel>

                  <Select
                    fullWidth
                    onChange={onSelectChange}
                    variant="filled"
                    data-testid={m.split(" ").join("-")}
                    key={m}
                    name={m}
                    value={mappingForm?.[m] || ""}
                  >
                    {csvTagColumList?.map(c => (
                      <MenuItem
                        data-testid={"menuItem"}
                        key={shortid.generate()}
                        className="dropdown-list"
                        value={c || ""}
                      >
                        <small>{c}</small>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <div className="inner-view__footer">
              <Grid container>
                <Grid item xs={12} className="text-right">
                  <Button
                    data-testid="cancel-btn"
                    variant="text"
                    className="primary-btn mr-5 cancel-btn"
                    onClick={onCancelClick}
                  >
                    {t(Translates.Cancel)}
                  </Button>
                  <Button
                    data-testid="next-btn"
                    variant="contained"
                    className="primary-btn"
                    onClick={onValidationClick}
                    disabled={checkAllValidation() === true}
                  >
                    {t(Translates.NextValidation)}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default React.memo(TagsBulkDataMapping)

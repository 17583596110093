import { useEffect } from "react"
import { useSelector } from "react-redux"
import { getEventDetail } from "../../src/redux/slices/inventorySlice"
import { RootState, useAppThunkDispatch } from "../../src/redux/store"
import { INVENTORY_STATUS } from "src/constants/common"

function useInventoryStatus() {
  const inventory: any = useSelector((state: RootState) => state?.inventory)
  const dispatch = useAppThunkDispatch()
  useEffect(() => {
    if (!inventory || !inventory?.inventoryData) {
      return
    }
    if (
      !Object?.keys(inventory?.inventoryData)?.length &&
      !["cancelled", "loading", "failed"].includes(inventory?.eventDetailStatus)
    ) {
      dispatch(getEventDetail())
    }
  }, [inventory])

  const _status = inventory?.inventoryData?.status?.toLowerCase()
  return {
    inventoryStatus: _status || "",
    isLocked: _status === INVENTORY_STATUS.LOCKED,
    isCompleted: _status === INVENTORY_STATUS.COMPLETED,
    isDisable:
      _status === INVENTORY_STATUS.LOCKED ||
      _status === INVENTORY_STATUS.COMPLETED
        ? true
        : false
  }
}

export default useInventoryStatus

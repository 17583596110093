import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { InputFilesService } from "../../service/inputFiles.service"

export interface InputFilesState {
  status: string
  inputFiles: any
  inputFilesStatus: string
  missingFiles: {
    status: string
    data: any
  }
}

const initialState: InputFilesState = {
  status: "",
  inputFiles: [],
  inputFilesStatus: "",
  missingFiles: {
    status: "",
    data: null
  }
}

const inputFilesService = new InputFilesService()
export const getInputFiles = createAsyncThunk(
  "files/getInputFiles",
  async () => {
    return inputFilesService.getInputFiles()
  }
)

export const getMissingFiles = createAsyncThunk(
  "files/getMissingFiles",
  async () => {
    return inputFilesService.getMissingFiles()
  }
)

export const inputFilesSlice = createSlice({
  name: "auditList",
  initialState,
  reducers: {
    clearInputFilesState() {
      return initialState
    }
  },
  extraReducers: builder => {
    builder.addCase(getInputFiles.pending, state => {
      state.inputFilesStatus = "loading"
    })
    builder.addCase(getInputFiles.fulfilled, (state, { payload }) => {
      state.inputFilesStatus = "success"

      state.inputFiles = payload?.data
    })
    builder.addCase(getInputFiles.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.inputFilesStatus = "cancelled"
      } else {
        state.inputFilesStatus = "failed"
      }
    })
    builder.addCase(getMissingFiles.pending, state => {
      state.missingFiles.status = "loading"
    })
    builder.addCase(getMissingFiles.fulfilled, (state, { payload }) => {
      state.missingFiles.status = "success"
      state.missingFiles.data = payload.data
    })
    builder.addCase(getMissingFiles.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.missingFiles.status = "cancelled"
      } else {
        state.missingFiles.status = "failed"
        state.missingFiles.data = null
      }
    })
  }
})
export const { clearInputFilesState } = inputFilesSlice.actions
export default inputFilesSlice.reducer

export const TEAM_PAGE_CONSTANTS = {
  Auditors: "Auditors",
  Counters: "Counters",
  Active: "Active",
  InActive: "InActive",
  LoggedOff: "LoggedOff",
  All: "All",
  Auditor: "Auditor",
  Counter: "Counter",
  Completed: "closed",
  INACTIVE: "INACTIVE",
  LOGGED_OFF: "LOGGED OFF",
  Out_of_Range: "Out of Range"
}

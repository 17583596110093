import { setKeys } from "src/redux/slices/authSlice"
import { store } from "src/redux/store"
import { UserService } from "./user.service"
import { decryptUrlParams } from "src/utils/helper"

const userService = new UserService()

export class InItService {
  async init() {
    //Keyvault secret
    if (!store.getState().auth.keys.length) {
      await userService
        .getKeyVaultSecrets()
        .then((res: any) => {
          if (res?.data) {
            const _temp = res.data.map(a => ({
              secretKey: a.secretKey,
              secretValue: decryptUrlParams(a.secretValue)
            }))
            store.dispatch(setKeys(_temp))
          }
          return res
        })
        .catch(err => err)
    }
  }
}

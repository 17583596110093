import React from "react"
import { Grid } from "@mui/material"
import auditBg from "../../../src/assets/images/AuditModeBg.svg"
import styles from "./AuditNoDataBanner.module.scss"
import { useSelector } from "react-redux"
import { RootState } from "../../../src/redux/store"
import useLocationLable from "../../../src/hooks/useLocationLable"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
const AuditNoDataBanner = () => {
  const { t } = useTranslation()
  const inventoryData = useSelector(
    (state: RootState) => state.inventory.inventoryData
  )
  const { locationLable } = useLocationLable()
  return (
    <>
      <Grid container className={`blue-bg ${styles.auditModeBanner}`}>
        <Grid item md={7} lg={7}>
          <h1 className="font-24 text-uppercase f-700 mb-0">
            {t(Translates.Audit_mode)}
          </h1>
          <p>
            {t(
              Translates.Choose_Audit_Mode_in_the_Count_App_to_start_completing
            )}{" "}
            {t(locationLable)} {t(Translates.Audits)}. {t(Translates.Use_this)}
            <span className="d-block">
              {t(Translates.Audit_PIN_to_access_Audit_Mode)}:{" "}
              {`${inventoryData.auditorPassword || "-"}`}
            </span>
          </p>
        </Grid>
        <Grid item md={5} lg={4} className="text-right">
          <img width={"248px"} height={"152px"} src={auditBg} alt="audit bg" />
        </Grid>
      </Grid>
    </>
  )
}
export default AuditNoDataBanner

import React, { useCallback } from "react"
import IconButton from "@mui/material/IconButton"
import { Tooltip } from "@mui/material"
import { Translates } from "src/i18n/i18n"
import { useTranslation } from "react-i18next"
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import PdfIcon from "../../assets/images/FilePdfIcon.svg"
import { KEY_CODES } from "src/utils/constants"
import { decrypt } from "src/utils/helper"

const AdjustmentReportToolBar: React.FC<any> = ({
  handleFilter,
  pdfUrl
}: {
  handleFilter: any
  pdfUrl: string
}) => {
  const { t } = useTranslation()

  const afForm = useSelector(
    (state: RootState) => state.count.adjustmentFilterForm
  )

  const filterButtonClass = () => {
    let classStr: string = `icon-btn`
    return classStr
  }
  const getFilterCount = () => {
    const temp: any = afForm
      ? Object.values(afForm).reduce(
          (a: any, b: any) => (b.length > 0 ? a + 1 : a),
          0
        )
      : 0
    return temp
  }

  const downloadFile = async (url: any) => {
    const actualUrl = await decrypt(url)
    window.open(actualUrl)
  }
  const handlePdfOpenClick = useCallback(() => {
    downloadFile(pdfUrl)
  }, [pdfUrl])

  const handlePdfOpenClickKeyUp = useCallback(
    e => {
      if (e.keyCode === KEY_CODES.ENTER) {
        downloadFile(pdfUrl)
      }
    },
    [pdfUrl]
  )
  return (
    <div>
      <Tooltip arrow title={t(Translates.Filter)}>
        <IconButton
          id="basic-button"
          data-testid={"handle-filter-btn"}
          className={filterButtonClass()}
          onClick={handleFilter}
        >
          <FilterListOutlinedIcon />

          {getFilterCount() > 0 ? (
            <span className="filter-icon__count">{getFilterCount()}</span>
          ) : null}
        </IconButton>
      </Tooltip>
      <Tooltip arrow title={`${t(Translates.DownloadPDF)}`}>
        <IconButton
          className="icon-btn mx-2"
          data-testid="download-btn"
          onClick={() => handlePdfOpenClick()}
          onKeyUp={e => handlePdfOpenClickKeyUp(e)}
        >
          <img src={PdfIcon} alt="pdf-icon" />
        </IconButton>
      </Tooltip>
    </div>
  )
}
export default AdjustmentReportToolBar

import React from "react"
import RouteList from "./RouteList"

export default function Routes() {
  return (
    <>
      <RouteList />
    </>
  )
}

import React from "react"
import LinearProgress from "@mui/material/LinearProgress"

const Progressbar = (Props: any) => {
  let number = Props.value
  const countCondition = () => {
    if (Props.value <= 0) {
      return "not-started"
    } else if (Props.value >= 1 && Props.value < 100) {
      return "in-progress"
    } else if (Props.value === 100) {
      return "completed"
    } else {
      return " "
    }
  }
  return (
    <>
      <div className={`${Props.type} custom-progressbar`}>
        <LinearProgress
          color="success"
          className={` ${countCondition()} custom-progressbar__bar `}
          variant="determinate"
          value={number}
          role="progress"
        />
        <span
          className="custom-progressbar__text"
          style={{ width: number + "%" }}
        >
          {number + "%"}
        </span>
      </div>
    </>
  )
}

export default Progressbar

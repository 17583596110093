export const breadcrumbNameMap: { [key: string]: string } = {
  "/count": "Count",
  "/inbox": "Inbox",
  "/inbox/important": "Important",
  "/trash": "Trash",
  "/spam": "Spam",
  "/drafts": "Drafts",
  "/count/outofrangetags": "Out_Of_Range_<__REPLACE_LOCATION>",
  "/closeInventory": "Close_Inventory",
  "/count/voidedandcounted": "Voided_and_Counted",
  "/count/duplicateTags": "Duplicate_<__REPLACE_LOCATION>"
}

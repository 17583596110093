import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface BulkState {
  validTagColumnList: string[]
  csvColumnList: string[]
  TagMappingData: any
  TagFileData: any
  TagFilePath: string
}

const initialState: BulkState = {
  TagMappingData: {},
  TagFileData: {},
  TagFilePath: "",
  csvColumnList: [],
  validTagColumnList: [
    "Category",
    "Range Name",
    "Location Start",
    "Location End"
  ]
}
export const bulkSlice = createSlice({
  name: "bulk",
  initialState,
  reducers: {
    setCsvColumnList: (state, _action: PayloadAction<any>) => {
      state.csvColumnList = [..._action.payload]
    },

    setTagMappingData: (state, _action: PayloadAction<any>) => {
      state.TagMappingData = { ..._action.payload }
    },
    setTagFileData: (state, _action: PayloadAction<any>) => {
      state.TagFileData = _action.payload
    },
    setTagFilePath: (state, _action: PayloadAction<any>) => {
      state.TagFilePath = _action.payload
    },
    setTagReset: () => initialState
  }
})

// Action creators are generated for each case reducer function
export const {
  setCsvColumnList,
  setTagMappingData,
  setTagFileData,
  setTagFilePath,
  setTagReset
} = bulkSlice.actions

export default bulkSlice.reducer

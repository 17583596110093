import React from "react"
import { Alert, AlertTitle, Button } from "@mui/material"
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { useHistory } from "react-router-dom"
import { PATH } from "src/constants/paths"

const PreCountDataBanner = ({
  isButtonShow = true
}: {
  isButtonShow?: boolean
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const handleReviewClick = () => {
    history.push(PATH.PRECOUNT_FILES)
  }

  return (
    <>
      <div>
        <Alert
          severity="warning"
          icon={<NotificationsActiveIcon />}
          className="custom-alert"
          action={
            isButtonShow ? (
              <Button
                data-testid="pc-review-btn"
                variant="contained"
                className="primary-btn mr-2"
                onClick={handleReviewClick}
              >
                {t(Translates.Review)}
              </Button>
            ) : null
          }
        >
          <AlertTitle className="custom-alert__title">
            {t(Translates.PRECOUNT_FILE_READY)}
          </AlertTitle>
          <p className="mr-10">{t(Translates.precount_banner_description)}.</p>
        </Alert>
      </div>
    </>
  )
}
export default PreCountDataBanner

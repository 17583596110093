import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { InputFilesService } from "src/service/inputFiles.service"
import { CountService } from "../../service/count.service"

export interface ConfigState {
  status: string
  configData: any
  inputFileData: any
}

const initialState: ConfigState = {
  status: "",
  configData: {},
  inputFileData: {
    status: "",
    data: null
  }
}

const inputFilesService = new InputFilesService()
export const getInputFiles = createAsyncThunk(
  "files/getInputFiles",
  async () => {
    return inputFilesService.getInputFiles()
  }
)

const countService = new CountService()

export const getConfig = createAsyncThunk("config/configDetails", async () => {
  return countService.getConfig()
})

//configData Slice
export const configurationSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    clearConfigurationState() {
      return initialState
    }
  },
  extraReducers: builder => {
    // configData
    builder.addCase(getConfig.pending, state => {
      state.status = "loading"
    })
    builder.addCase(getConfig.fulfilled, (state, { payload }) => {
      state.status = "success"
      state.configData = payload && payload.data
    })
    builder.addCase(getConfig.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.status = "cancelled"
      } else {
        state.status = "failed"
        state.configData = {}
      }
    })
    builder.addCase(getInputFiles.pending, state => {
      state.inputFileData.status = "loading"
      state.inputFileData.data = null
    })
    builder.addCase(getInputFiles.fulfilled, (state, { payload }) => {
      state.inputFileData.status = "success"
      state.inputFileData.data = payload && payload.data
    })
    builder.addCase(getInputFiles.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.inputFileData.status = "cancelled"
      } else {
        state.inputFileData.status = "failed"
        state.inputFileData.data = null
      }
    })
  }
})
export const { clearConfigurationState } = configurationSlice.actions
export default configurationSlice.reducer

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AuditService } from "../../service/audit.service"
import { FILTERS_LOCAL_STORE } from "src/utils/constants"
//clearing to avoid retaining old event cache
if (window.location.pathname === "/login") {
  localStorage.removeItem(FILTERS_LOCAL_STORE.AUDIT_FILTER)
  localStorage.removeItem(FILTERS_LOCAL_STORE.PERSONS_PAGE_FILTER)
}
interface FilterInterface {
  personViewStatus: string
  tagViewStatus: string
  view: string
}

interface PersonsFilterInterface {
  status: string
}
export interface AuditState {
  status: string
  auditSummary: any
  auditSummaryStatus: string
  auditTags: any
  auditTagsStatus: string
  auditPersons: any
  auditPersonsStatus: string
  persons: any
  personsStatus: string
  auditPageFilter: FilterInterface
  personsPageFilter: PersonsFilterInterface
}

const getFilterFromLocalStore = () => {
  let AUDIT_F: any = localStorage.getItem(FILTERS_LOCAL_STORE.AUDIT_FILTER)
  AUDIT_F = AUDIT_F
    ? JSON.parse(AUDIT_F)
    : { personViewStatus: "", tagViewStatus: "", view: "tagView" }

  let PERSONS_F: any = localStorage.getItem(
    FILTERS_LOCAL_STORE.PERSONS_PAGE_FILTER
  )
  PERSONS_F = PERSONS_F ? JSON.parse(PERSONS_F) : { status: "" }
  return { AUDIT_F, PERSONS_F }
}
const filterFromLocalStore = getFilterFromLocalStore()

const initialState: AuditState = {
  status: "",
  auditSummary: {},
  auditSummaryStatus: "",
  auditTags: [],
  auditTagsStatus: "",
  auditPersons: {},
  auditPersonsStatus: "",
  persons: {},
  personsStatus: "",
  auditPageFilter: filterFromLocalStore.AUDIT_F,
  personsPageFilter: filterFromLocalStore.PERSONS_F
}
const initialStateForLogout: AuditState = {
  status: "",
  auditSummary: {},
  auditSummaryStatus: "",
  auditTags: [],
  auditTagsStatus: "",
  auditPersons: {},
  auditPersonsStatus: "",
  persons: {},
  personsStatus: "",
  auditPageFilter: { personViewStatus: "", tagViewStatus: "", view: "tagView" },
  personsPageFilter: { status: "" }
}
const auditService = new AuditService()
export const getAuditSummary = createAsyncThunk(
  "auditList/getAuditSummary",
  async () => {
    return auditService.getAuditSummary()
  }
)

export const getAuditTags = createAsyncThunk(
  "auditList/getAuditTags",
  async () => {
    return auditService.getAuditTags()
  }
)
export const getAuditPersons = createAsyncThunk(
  "auditList/getAuditPersons",
  async () => {
    return auditService.getAuditPersons()
  }
)
export const getPersons = createAsyncThunk(
  "auditList/getPersons",
  async (params: any) => {
    let eventUserId = params.eventUserId
    return auditService.getPersons(eventUserId)
  }
)
//Audit Slice
export const auditSlice = createSlice({
  name: "auditList",
  initialState,
  reducers: {
    updateAuditPageFilter(state, action: PayloadAction<any>) {
      state.auditPageFilter = { ...state.auditPageFilter, ...action.payload }
      localStorage.setItem(
        FILTERS_LOCAL_STORE.AUDIT_FILTER,
        JSON.stringify(state.auditPageFilter)
      )
      return state
    },
    updatePersonsPageFilter(state, action: PayloadAction<any>) {
      state.personsPageFilter = {
        ...state.personsPageFilter,
        ...action.payload
      }
      localStorage.setItem(
        FILTERS_LOCAL_STORE.PERSONS_PAGE_FILTER,
        JSON.stringify(state.personsPageFilter)
      )
      return state
    },
    clearAuditState() {
      return initialStateForLogout
    }
  },
  extraReducers: builder => {
    builder.addCase(getAuditSummary.pending, state => {
      state.auditSummaryStatus = "loading"
    })
    builder.addCase(getAuditSummary.fulfilled, (state, { payload }) => {
      state.auditSummaryStatus = "success"
      state.auditSummary = payload.data
    })
    builder.addCase(getAuditSummary.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.auditSummaryStatus = "cancelled"
      } else {
        state.auditSummaryStatus = "failed"
        state.auditSummary = {}
      }
    })
    builder.addCase(getAuditTags.pending, state => {
      state.auditTagsStatus = "loading"
    })
    builder.addCase(getAuditTags.fulfilled, (state, { payload }) => {
      state.auditTagsStatus = "success"
      state.auditTags = payload.data
    })
    builder.addCase(getAuditTags.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.auditTagsStatus = "cancelled"
      } else {
        state.auditTagsStatus = "failed"
        state.auditTags = []
      }
    })
    builder.addCase(getAuditPersons.pending, state => {
      state.auditPersonsStatus = "loading"
    })
    builder.addCase(getAuditPersons.fulfilled, (state, { payload }) => {
      state.auditPersonsStatus = "success"
      state.auditPersons = payload.data
    })
    builder.addCase(getAuditPersons.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.auditPersonsStatus = "cancelled"
      } else {
        state.auditPersonsStatus = "failed"
        state.auditPersons = {}
      }
    })
    builder.addCase(getPersons.pending, state => {
      state.personsStatus = "loading"
    })
    builder.addCase(getPersons.fulfilled, (state, { payload }) => {
      state.personsStatus = "success"
      state.persons = payload.data
    })
    builder.addCase(getPersons.rejected, (state, { error }) => {
      if (error.message?.includes("duplicate request")) {
        state.personsStatus = "cancelled"
      } else {
        state.personsStatus = "failed"
        state.persons = []
      }
    })
  }
})
export const {
  updateAuditPageFilter,
  updatePersonsPageFilter,
  clearAuditState
} = auditSlice.actions
export default auditSlice.reducer

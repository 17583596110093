import React, { useEffect, useState } from "react"
import IconButton from "@mui/material/IconButton"
import OpenInFullTwoToneIcon from "@mui/icons-material/OpenInFullTwoTone"
import CloseFullscreenTwoToneIcon from "@mui/icons-material/CloseFullscreenTwoTone"
import { useDispatch, useSelector } from "react-redux"
import { updateDuplicateTagReviewConsiceView } from "../../redux/slices/countSlice"
import { RootState } from "../../redux/store"
import { Tooltip } from "@mui/material"
import { t } from "i18next"
import { Translates } from "src/i18n/i18n"

const DuplicateTagReviewToolBar = ({ toggleConciseView }) => {
  const consiceViewFromStore = useSelector(
    (state: RootState) => state.count.duplicateTagReviewconsiceView
  )
  const dispatch = useDispatch()
  const [conciseView, setConciseView] = useState(false)
  useEffect(() => {
    setConciseView(consiceViewFromStore)
  }, [consiceViewFromStore])

  const handleConciseView = () => {
    let toggledOption = !conciseView
    setConciseView(toggledOption)
    toggleConciseView(toggledOption)
    dispatch(updateDuplicateTagReviewConsiceView(toggledOption))
  }

  return (
    <>
      <div className="d-inline-flex">
        <Tooltip arrow title={`${t(Translates.Concise_View)}`}>
          <IconButton
            data-testid="conciseview"
            className="icon-btn mx-2"
            onClick={handleConciseView}
          >
            {conciseView ? (
              <OpenInFullTwoToneIcon />
            ) : (
              <CloseFullscreenTwoToneIcon />
            )}
          </IconButton>
        </Tooltip>
      </div>
    </>
  )
}

export default DuplicateTagReviewToolBar

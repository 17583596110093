import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import shortid from "shortid"
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup
} from "@mui/material"
import { getFieldError } from "./Helper"

export type formControlLabel = {
  value: string | boolean
  className?: string
  label: string
  radio?: {
    className?: string
    size?: "medium" | "small"
    color?: "error" | "primary" | "secondary" | "info" | "success" | "warning"
  }
  default?: boolean
  disabled?: boolean
}

export type Props = {
  className?: string
  name: string
  formLabel?: string
  formControl: {
    className?: string
    radioGroup: {
      row?: boolean
      formControlLabels: formControlLabel[]
      ariaLabelledby?: string
      name?: string
    }
    defaultValue?: any
    value?: any
  }
}

const MUIRadio = ({ formLabel, name, className, formControl }: Props) => {
  const {
    control,
    formState: { errors }
  } = useFormContext() // retrieve all hook methods
  let defVal: any = null
  if (formControl.value !== undefined) {
    defVal = formControl.value
  } else if (formControl.defaultValue !== undefined) {
    defVal = formControl.defaultValue
  } else {
    formControl.radioGroup.formControlLabels.forEach(item => {
      if (item.default) {
        defVal = item.value
      }
    })
  }
  const { isError, ErrorText } = getFieldError(name, errors)
  return (
    <Controller
      name={`${name}`}
      control={control}
      defaultValue={defVal || ""}
      render={({ field: { ref, ...field }, formState: { errors } }) => (
        <FormControl className={`${className}`}>
          <FormLabel id={`${name}-radio-buttons-group-label`}>
            {formLabel}
          </FormLabel>
          <RadioGroup
            {...field}
            row={formControl.radioGroup.row}
            aria-labelledby={
              formControl.radioGroup.ariaLabelledby ??
              `${name}-row-radio-buttons-group-label`
            }
            className={`${formControl.className}`}
          >
            {formControl.radioGroup.formControlLabels.map(item => (
              <FormControlLabel
                key={shortid.generate()}
                value={item?.value}
                className={`mr-7 ${item?.className}`}
                label={item?.label}
                disabled={item?.disabled}
                control={
                  <Radio
                    className={item?.radio?.className ?? ""}
                    size={item?.radio?.size ?? "small"}
                    inputRef={ref}
                  />
                }
              />
            ))}
          </RadioGroup>
          <FormHelperText className={isError ? "field-text-danger" : ""}>
            {ErrorText}
          </FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default MUIRadio

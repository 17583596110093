import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { TextField } from "@mui/material"

export interface Props {
  inputAttributes: {
    name: string
    type?: "text" | "email" | "tel" | "password"
    label?: string
    placeholder?: string
    className?: string
    id?: string
    required?: boolean
    size?: "medium" | "small"
    color?: "error" | "primary" | "secondary" | "info" | "success" | "warning"
    variant?: "standard" | "filled" | "outlined"
    fullWidth?: boolean
    InputProps?: any
    value?: string | number | null
    defaultValue?: string | number | null
    multiline?: boolean
    rows?: number
    maxRows?: number
    onChange?: Function
    onBlur?: Function
    disabled?: boolean
    secondaryValidationError?: string
    helperText?: string
    autoComplete?: string
  }
}

const MUIText = ({ inputAttributes }: Props) => {
  const {
    name,
    type,
    label,
    placeholder,
    className,
    id,
    size,
    color,
    variant,
    fullWidth,
    InputProps,
    required,
    value,
    defaultValue,
    multiline,
    rows,
    maxRows,
    onChange,
    onBlur,
    disabled,
    secondaryValidationError,
    helperText,
    autoComplete
  } = inputAttributes

  const { control } = useFormContext() // retrieve all hook methods
  let defVal: any = ""
  if (value !== undefined) {
    defVal = value
  } else if (defaultValue !== undefined) {
    defVal = defaultValue
  }

  return (
    <Controller
      name={`${name}`}
      control={control}
      defaultValue={defVal}
      render={({ field: { ref, ...field }, formState: { errors } }) => (
        <TextField
          {...field}
          {...(label && {
            label: `${label}`
          })}
          inputRef={ref}
          onChange={e => {
            field.onChange(e)
            if (onChange) {
              onChange(e)
            }
          }}
          onBlur={e => {
            if (onBlur) {
              onBlur(e)
            }
          }}
          className={`${multiline ? "" : "custom-input"} ${className}`}
          disabled={disabled ?? false}
          InputProps={InputProps}
          fullWidth={fullWidth ?? true}
          id={id ?? name}
          required={required ?? false}
          size={size ?? "small"}
          placeholder={placeholder}
          type={type ?? "text"}
          variant={variant ?? "filled"}
          color={color ?? "primary"}
          autoComplete={autoComplete || "off"}
          {...(rows && {
            rows: rows
          })}
          {...(maxRows && {
            maxRows: maxRows
          })}
          multiline={multiline ?? false}
          autoSave="false"
          error={!!errors[name] || !!secondaryValidationError}
          helperText={
            (errors[name] as any)?.message ||
            secondaryValidationError ||
            helperText
          }
        />
      )}
    />
  )
}

export default MUIText

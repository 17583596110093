import React from "react"
import Routes from "../../src/routes/routes"
import { withRouter } from "react-router-dom"
import { withAITracking } from "@microsoft/applicationinsights-react-js"
import { reactPlugin } from "../AppInsights/AppInsights"
import { updateMoment } from "src/i18n/i18n"

const App = () => {
  updateMoment()

  localStorage.setItem("isRefreshTokenInProgress", "false")
  return <Routes />
}

// export default withRouter(App)
export default withAITracking(reactPlugin, withRouter(App))

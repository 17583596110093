import React, { useEffect } from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Button
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useTranslation } from "react-i18next"
import { LoaderHelper } from "../../utils/LoaderHelper"
import { Translates } from "src/i18n/i18n"
import { KEY_CODES } from "src/utils/constants"
interface InputConfirm {
  open: boolean
  callBack: any
  content: any
}

const PrintPreviewDialog: React.FC<InputConfirm> = ({
  open,
  callBack,
  content
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    const handleKeyUp = e => {
      if (e.keyCode === KEY_CODES.ENTER) {
        modalCallbackTrueKeyUp(e)
      }
    }

    // Add the event listener when the component mounts
    document.addEventListener("keyup", handleKeyUp)

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("keyup", handleKeyUp)
    }
  }, [])

  const modalCallbackTrueKeyUp = _e => {
    callBack()
  }

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="lg"
        className="custom-dialog"
      >
        <div>
          <Grid container className="custom-dialog__header">
            <Grid className="custom-dialog__header-close">
              <Button
                data-testid="closeBtn"
                variant="text"
                id="secondary-btn"
                className="secondary-btn"
                disableRipple={true}
                startIcon={<CloseIcon />}
                onClick={callBack}
              >
                {t(Translates.Close)}
              </Button>
            </Grid>
          </Grid>
        </div>

        <DialogContent className="custom-dialog__cont">
          {content ? content : LoaderHelper()}
        </DialogContent>
        <DialogActions className="custom-dialog__actions"></DialogActions>
      </Dialog>
    </div>
  )
}

export default PrintPreviewDialog

import React, { useEffect, useState, useCallback } from "react"
import { Grid, Button, TextField, FormControl } from "@mui/material"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { RootState, useAppThunkDispatch } from "../../../redux/store"
import { setAdjustmentFilterForm } from "src/redux/slices/countSlice"
import MultiSelectAutocomplete from "src/components/MUIFormFields/MUIAutoCompleteMultiSelect"
import { AdjfilterNames } from "../AdjustmentSummaryReport/AdjustmentConstants"
import shortid from "shortid"
interface IFilterForm {
  tag: string[]
  barcode: string[]
  sku: string[]
  description: string
  adjustmentSourceName: string[]
  adjustmentSource: string[]
}

const DEFAULT_FITERS = {
  tag: [],
  barcode: [],
  sku: [],
  description: "",
  adjustmentSourceName: [],
  adjustmentSource: []
}

const AdjustmentSummaryFilter = ({ hideFilter }) => {
  const { t } = useTranslation()
  const [filterForm, setFilterForm] = useState(DEFAULT_FITERS as IFilterForm)
  const [filterSet, setFilterSet] = useState({} as any)
  const filterData = useSelector(
    (state: RootState) => state.count.adjustmentFilters
  )
  const adjustmentFilterForm = useSelector(
    (state: RootState) => state.count.adjustmentFilterForm
  )
  useEffect(() => {
    if (adjustmentFilterForm && Object.values(adjustmentFilterForm).length) {
      setFilterForm(adjustmentFilterForm)
    }
  }, [adjustmentFilterForm])

  useEffect(() => {
    if (filterData.length) {
      setFilterSet(getFiltersValues())
    }
  }, [filterData])

  const getValues = useCallback((column: any) => {
    return [
      ...Array.from(
        new Set(
          filterData
            .map(m => m.details)
            .flat()
            .map(m => m.changeAdjustmentSummary[column])
            .filter(Boolean)
        )
      )
    ]
  }, [])
  const getFiltersValues = () => {
    return Object.keys(DEFAULT_FITERS).reduce((a, b) => {
      if (b !== "description") {
        return { ...a, [b]: getValues(b) }
      } else {
        return { ...a }
      }
    }, {})
  }

  const dispatch = useAppThunkDispatch()
  // Handle change fun for all autocomplete controls except descp
  // istanbul ignore next
  const handleChangeAutoComplete = (e, newValue, name) => {
    let _checked: any = null
    if (e.target.checked === undefined) {
      _checked = e.currentTarget.children[0].children[0].checked
    } else {
      _checked = !e.target.checked
    }
    if (
      e.currentTarget?.children[1]?.children[0]?.innerHTML === "All" &&
      !_checked
    ) {
      setFilterForm({
        ...filterForm,
        [name]: ["All", ...filterSet[name]]
      })
    } else if (
      e.currentTarget?.children[1]?.children[0]?.innerHTML === "All" &&
      _checked
    ) {
      setFilterForm({
        ...filterForm,
        [name]: []
      })
    } else {
      if (name) {
        setFilterForm({ ...filterForm, [name]: [...newValue] })
      }
    }
  }
  // Handle Change Func for descp autocomplete control
  const handleChangeDesp = e => {
    if (e.target.name) {
      setFilterForm({ ...filterForm, [e.target.name]: e.target.value })
    }
  }
  const applyFilterForm = () => {
    dispatch(setAdjustmentFilterForm({ ...filterForm }))
    hideFilter()
  }
  const onCancelClick = () => {
    hideFilter()
  }
  const handleResetAllClick = () => {
    let filters: any = DEFAULT_FITERS
    setFilterForm(filters as IFilterForm)
  }

  const _controls = [
    {
      header: `${t(Translates.tag)}`,
      name: AdjfilterNames.TAG,
      field: filterForm.tag,
      label: `${t(Translates.Select_Tag_No)}`
    },
    {
      header: `${t(Translates.Barcode)}`,
      name: AdjfilterNames.BARCODE,
      field: filterForm.barcode,
      label: `${t(Translates.Select_Barcode)}`
    },
    {
      header: `${t(Translates.SKU)}`,
      name: AdjfilterNames.SKU,
      field: filterForm.sku,
      label: `${t(Translates.Select_SKU)}`
    },
    {
      header: AdjfilterNames.DESCRIPTION,
      field: [],
      name: "",
      label: ""
    },
    {
      header: `${t(Translates.Adjusted_By)}`,
      name: AdjfilterNames.ADJ_SOURCE_NAME,
      field: filterForm.adjustmentSourceName,
      label: `${t(Translates.Select_Adjusted_By)}`
    },
    {
      header: `${t(Translates.Adjusted_Source)}`,
      name: AdjfilterNames.ADJ_SOURCE,
      field: filterForm.adjustmentSource,
      label: `${t(Translates.Select_Adjusted_Source)}`
    }
  ]

  return (
    <div className="filter-panel">
      <Grid container className="main-container">
        <Grid className="gray-bg pa-4" container spacing={2}>
          <Grid item xs={12}>
            <h4>{t(Translates.Filters)}</h4>
          </Grid>
          {_controls.map(item =>
            item.header !== "description" ? (
              <Grid item xs={12} sm={4} md={3} key={item.header}>
                <label
                  className="f-600 text-uppercase mb-2"
                  key={`${shortid.generate()}`}
                >
                  {item.header}
                </label>
                <div className="text-box">
                  <MultiSelectAutocomplete
                    key={item.header}
                    multiple={true}
                    data={["All", ...(filterSet[item.name] || [])]}
                    onChange={(event, newValue) => {
                      handleChangeAutoComplete(event, newValue, item.name)
                    }}
                    extraparams={{}}
                    label={item.label}
                    className={"custom-input light"}
                    disablePortal={true}
                    value={item.field}
                  />
                </div>
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={3} key={item.header}>
                <label
                  className="f-600 text-uppercase mb-2"
                  key={`${shortid.generate()}`}
                >
                  {t(Translates.Description)}
                </label>
                <div className="text-box">
                  <FormControl className="custom-input text-area light">
                    <TextField
                      id="filled-multiline-flexible"
                      label={t(Translates.Description)}
                      name="description"
                      className="custom-form-control"
                      fullWidth
                      variant="filled"
                      onChange={e => handleChangeDesp(e)}
                      value={filterForm.description}
                      autoComplete={"off"}
                    />
                  </FormControl>
                </div>
              </Grid>
            )
          )}

          <Grid item xs={12}>
            <Grid container className="inner-view__footer mb-0">
              <Grid item sm={5} md={4} className="text-left">
                <Button
                  onClick={handleResetAllClick}
                  variant="text"
                  className="primary-btn mr-5 cancel-btn"
                >
                  {t(Translates.Reset_All)}
                </Button>
              </Grid>
              <Grid item sm={7} md={8} className="text-right">
                <Button
                  variant="text"
                  data-testid="ASFCancelBtn"
                  className="primary-btn mr-5 cancel-btn"
                  onClick={onCancelClick}
                >
                  {t(Translates.Cancel)}
                </Button>
                <Button
                  variant="contained"
                  className="primary-btn"
                  onClick={applyFilterForm}
                  data-testid="applyFilter"
                >
                  {t(Translates.Apply_Filter)}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default AdjustmentSummaryFilter

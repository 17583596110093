import { config } from "src/config/config"
import ApiService from "../utils/useAxios"

const api = new ApiService(config.baseURLFiles)

export class PreCountFilesService {
  GetPrecountFileData() {
    const endPoint = `/api/PrecountFile/GetPrecountFileData`
    return api.findAll(endPoint)
  }

  LoadPrecountFileData(payload) {
    const endPoint = `/api/PrecountFile/LoadPrecountFileData`
    return api.post(endPoint, payload)
  }
  DeletePrecountFileData(payload) {
    const endPoint = `/api/PrecountFile/DeletePrecountFileData`
    return api.post(endPoint, payload)
  }

  IsFileAvailableToProcess() {
    const endPoint = `/api/PrecountFile/IsFileAvailableToProcess`
    return api.findAll(endPoint)
  }
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface AuthState {
  isAutheticated: boolean
  closeSideNav: boolean
  keys: Array<any>
}

const initialState: AuthState = {
  isAutheticated: false,
  closeSideNav: false,
  keys: []
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      localStorage.removeItem("token")
      state.isAutheticated = false
    },
    login: state => {
      state.isAutheticated = true
    },
    close_side_nav: (state, _action: PayloadAction<number>) => {
      state.closeSideNav = !state.closeSideNav
    },
    clearAuthState() {
      return initialState
    },
    setKeys: (state, _action: PayloadAction<any>) => {
      state.keys = [..._action.payload]
    }
  }
})

// Action creators are generated for each case reducer function
export const { logout, login, close_side_nav, clearAuthState, setKeys } =
  authSlice.actions

export default authSlice.reducer

import React from "react"
import useInventoryStatus from "../../hooks/useInventoryStatus"
import InventoryStatusBanner from "../../pages/CloseInventory/InventoryStatusBanner"
import BreadCrumb from "../BreadCrumb/BreadCrumb"
import SearchToolBar from "../SearchToolBar/SearchToolBar"
import DuplicateTagReviewToolBar from "../DuplicateTagReviewToolBar/DuplicateTagReviewToolBar"
import ToolBar from "../ToolBar/ToolBar"
import styles from "./ToolBarHeader.module.scss"
import AdjustmentReportToolBar from "../AdjustmentReportToolBar/AdjustmentReportToolBar"
import AdjustmentSummaryFilter from "src/pages/Report/filters/AdjustmentSummaryFilter"
import ReportToolbar from "../ReportToolbar/ReportToolbar"

const InventoryBanner = () => {
  const { isDisable } = useInventoryStatus()
  if (isDisable) {
    return (
      <div className={`${styles.inventoryBanner}`}>
        <div className={`main-container inventory-banner`}>
          <InventoryStatusBanner></InventoryStatusBanner>
        </div>
      </div>
    )
  }
  return null
}

const BreadcrumbContainer = props => (
  <div className="custom-breadcrumb">
    <BreadCrumb
      customPath={props.customPath || ""}
      breadCrumbInfo={props.breadCrumbInfo}
      isEditTagDisabled={props.isEditTagDisabled || false}
      renderedPage={props.renderedPage}
      curTagNumber={props.curTagNumber}
      curRangeId={props.curRangeId}
    />
  </div>
)

const ToolBarContainer = props => (
  <div className={`${styles.ToolBarContainer}`}>{props.children}</div>
)

const ToolBarHeader = (props: any) => {
  return (
    <>
      <InventoryBanner />
      <div
        id={`toolbar-header-container`}
        data-testid={`toolbar-header-container`}
        className={`${styles.Container}`}
      >
        <div className={`main-container ${styles.ContainerInner}`}>
          <BreadcrumbContainer {...props} />
          {!props.showSearchToolBar ? (
            <ToolBarContainer>
              {props.showToolBar ? (
                <ToolBar data-testid="toolbar" {...props} />
              ) : null}
            </ToolBarContainer>
          ) : (
            <ToolBarContainer>
              <SearchToolBar {...props} />
            </ToolBarContainer>
          )}
          {props.showReportIcons ? (
            <ToolBarContainer>
              <ReportToolbar {...props} />
            </ToolBarContainer>
          ) : null}
          {props.showDuplicateTagReviewTools ? (
            <ToolBarContainer>
              <DuplicateTagReviewToolBar {...props} />
            </ToolBarContainer>
          ) : null}
          {props.showAdjustmentReportIcons ? (
            <ToolBarContainer>
              <AdjustmentReportToolBar
                handleFilter={props.handleFilter}
                pdfUrl={props.pdfUrl}
              />
            </ToolBarContainer>
          ) : null}
        </div>
        {props.showFilter ? (
          <AdjustmentSummaryFilter hideFilter={props.hideFilter} />
        ) : null}
      </div>
    </>
  )
}

export default ToolBarHeader

import ApiService from "../utils/useAxios"

const api = new ApiService()

export class UserService {
  login(payload) {
    const endPoint: string = `/api/Login/LoginAuthorization`
    return api.post(endPoint, payload)
  }
  logout() {
    const endPoint: string = `api/Logout`
    return api.post(endPoint, {})
  }
  getKeyVaultSecrets() {
    const endPoint: string = `/api/Login/GetKeyVaultSecrets`
    return api.findAll(endPoint)
  }
  RedirectLogin(payload) {
    const endPoint: string = `/api/Login/RedirectLoginAuthorization`
    return api.post(endPoint, payload)
  }
  getLoggedinUsersByEventId() {
    const endPoint: string = `/api/EventUser/GetLoggedinUsersByEventId`
    return api.findAll(endPoint)
  }
}

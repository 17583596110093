import * as Yup from "yup"
import {
  fieldNames,
  reasonOptions,
  reasonOptionsValues,
  validationErrors,
  yesornoValues
} from "./Constant"

export const validationSchema = () => {
  return Yup.object().shape({
    [fieldNames.reasonForDelete.name]: Yup.string()
      .required(validationErrors.required)
      .oneOf(reasonOptionsValues, validationErrors.selectOption)
      .trim(),

    [fieldNames.reasonOther.name]: Yup.string().when(
      fieldNames.reasonForDelete.name,
      value => {
        const locYup = Yup.string()
          .max(250, validationErrors.max.replace("{number}", "250"))
          .trim()
        return String(value) === reasonOptions[reasonOptions.length - 1].value
          ? locYup.required(validationErrors.required)
          : locYup
      }
    ),
    [fieldNames.keepLocationRange.name]: Yup.string()
      .required(validationErrors.required)
      .oneOf(yesornoValues, validationErrors.selectOption)
      .trim(),
    [fieldNames.clearDeviceCountData.name]: Yup.string()
      .required(validationErrors.required)
      .oneOf(yesornoValues, validationErrors.selectOption)
      .trim(),
    [fieldNames.forceValidationFileReDownload.name]: Yup.string()
      .required(validationErrors.required)
      .oneOf(yesornoValues, validationErrors.selectOption)
      .trim(),
    [fieldNames.eventPassword.name]: Yup.string()
      .required(validationErrors.required)
      .trim()
  })
}
